import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import {nanoid} from "nanoid";
import CreatableSelect from "react-select/creatable";
import {IVolSplitPerLocation} from "../../common/api/api";

function VolSplitPerLocationAddForm({uniqueLocations, addFormHandler, cancelHandler}) {
    const [location, setLocation] = useState("");
    const [value, setValue] = useState("");

    //Error checking
    const [errorMessage, setErrorMessage] = useState("");
    //Convert locations to hold value and label
    uniqueLocations = uniqueLocations.map((str) => ({
        value: str,
        label: str,
    }));

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Black" : "Navy",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };

    //Submit Handler
    function submitHandler(event) {
        event.preventDefault();
        if (!location || !value) {
            setErrorMessage("Fill all the fields before submitting!");
        } else {
            const addData: IVolSplitPerLocation = {
                id: nanoid(),
                location: location['label'],
                value: parseFloat(value),
            };
            addFormHandler(addData);
        }
    }

    return (
        <>
            <Column>
                <form onSubmit={submitHandler}>
                    <Column>
                        <Heading level={4}>Add New Vol Split Per Location</Heading>
                        <Row height={50}>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setLocation}
                                    options={uniqueLocations}
                                    value={location}
                                    styles={customStyles}
                                    placeholder="Location"
                                />
                            </div>
                            <Input
                                name="value"
                                value={value}
                                placeholder={"Value"}
                                type="number"
                                onChange={setValue}
                            />
                        </Row>
                        <Box>
                            <Row>
                                <Button submit={true}>Add</Button>
                                <Button onClick={cancelHandler}>Cancel</Button>
                                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                            </Row>
                        </Box>
                        <Row></Row>
                    </Column>
                </form>
            </Column>
        </>
    );
}

export default VolSplitPerLocationAddForm;
