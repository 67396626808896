import { useCallback, useState } from "react";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_PKG_SPLITS_CONTAINER_TYPES,
  ITEMS_PER_PAGE,
  PLEASE_WAIT_STATUS,
  VALUE_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
  DEFAULT_SORT_DIRECTION,
  PKG_SPLITS_CONTAINER_TYPES_HEADERS,
  CONTAINER_TYPE_LABEL,
  SIZE_LABEL,
} from "../../constants";
import Button from "@amzn/meridian/button";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import PkgSplitsContainerTypesAddForm from "./PkgSplitsContainerTypesAddForm";
import ReadOnlyPkgSplitsContainerTypesRow from "./ReadOnlyPkgSplitsContainerTypesRow";
import EditablePkgSplitsContainerTypesRow from "./EditablePkgSplitsContainerTypesRow";
import { IId, IPkgSplitsContainerType } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Input from "@amzn/meridian/input";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function PkgSplitsContainerTypesTable({
  selectedNode,
  PkgSplitsContainerTypesList,
}) {
  //useState for the list
  const [pkgSplitsContainerTypesList, setPkgSplitsContainerTypesList] = useState(PkgSplitsContainerTypesList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(pkgSplitsContainerTypesList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(PKG_SPLITS_CONTAINER_TYPES_HEADERS.CONTAINER_TYPE)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IPkgSplitsContainerType = {
    id: "",
    container_type: "",
    size: "",
    value: 0.0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new Pkg Splits Container type record
   * @param addData The new Pkg Splits Container type to be added.
   */
  function addFormHandler(addData) {
    let result = pkgSplitsContainerTypesList.filter(function (pkgSplitsContainerTypesItem) {
      return (
          pkgSplitsContainerTypesItem.container_type == addData.container_type &&
          pkgSplitsContainerTypesItem.size == addData.size &&
          pkgSplitsContainerTypesItem.value == addData.value
      );
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_PKG_SPLITS_CONTAINER_TYPES);
      onOpenToast();
    } else {
      const addList: IPkgSplitsContainerType[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updatePkgSplitsContainerType(
            selectedNode,
            finalList
          );
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setPkgSplitsContainerTypesList([addData, ...pkgSplitsContainerTypesList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editPkgSplitsContainerTypesId, setEditPkgSplitsContainerTypesId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * This function Updates the Pkg Splits Container Type
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    let result = pkgSplitsContainerTypesList.filter(function (pkgSplitsContainerTypesItem) {
      return (
          pkgSplitsContainerTypesItem.container_type == newFormData.container_type &&
          pkgSplitsContainerTypesItem.size == newFormData.size &&
          pkgSplitsContainerTypesItem.value == newFormData.value
      );
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_PKG_SPLITS_CONTAINER_TYPES);
      onOpenToast();
    } else {
      const updateList: IPkgSplitsContainerType[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updatePkgSplitsContainerType(
            selectedNode,
            finalList
          );
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          const newEditPkgSplitsContainerTypesList = [...pkgSplitsContainerTypesList];
          setEditFormData(newFormData);
          const index = newEditPkgSplitsContainerTypesList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          newEditPkgSplitsContainerTypesList[index].id = newFormData.id;
          newEditPkgSplitsContainerTypesList[index].container_type = newFormData.container_type;
          newEditPkgSplitsContainerTypesList[index].size = newFormData.size;
          newEditPkgSplitsContainerTypesList[index].value = newFormData.value;
          setPkgSplitsContainerTypesList([...pkgSplitsContainerTypesList]);
          setEditPkgSplitsContainerTypesId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (pkgSplitsContainerTypes) => {
    setEditPkgSplitsContainerTypesId(pkgSplitsContainerTypes.id);
    const formValues: IPkgSplitsContainerType = {
      id: pkgSplitsContainerTypes.id,
      container_type: pkgSplitsContainerTypes.container_type,
      size: pkgSplitsContainerTypes.size,
      value: pkgSplitsContainerTypes.value,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditPkgSplitsContainerTypesId(null);
  };
  /**
   * To handle the delete button
   * @param pkgSplitsContainerTypes The Pkg Splits Container Type object to be deleted
   */
  const handleDeleteClick = (pkgSplitsContainerTypes) => {
    const idsList: IId[] = [];
    const finalIds: IId[] = [pkgSplitsContainerTypes, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deletePkgSplitsContainerType(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const newPkgSplitsContainerTypesList = [...pkgSplitsContainerTypesList];
        const index = newPkgSplitsContainerTypesList.findIndex((obj) => {
          return obj.id === pkgSplitsContainerTypes.id;
        });
        newPkgSplitsContainerTypesList.splice(index, 1);
        setPkgSplitsContainerTypesList(newPkgSplitsContainerTypesList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${pkgSplitsContainerTypes}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  //Get list of unique containerTypes & Sizes
  let containerTypesOnly = PkgSplitsContainerTypesList.map((a) => a.container_type);
  let uniqueContainerTypes = [...new Set(containerTypesOnly)];
  let sizes = PkgSplitsContainerTypesList.map((a) => a.size);
  let uniqueSizes = [...new Set(sizes)];

  PkgSplitsContainerTypesList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <PkgSplitsContainerTypesAddForm
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              uniqueContainerTypes={uniqueContainerTypes}
              uniqueSizes={uniqueSizes}
              selectedNode={selectedNode}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Search by Container Type"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={PKG_SPLITS_CONTAINER_TYPES_HEADERS.CONTAINER_TYPE} width={200}>{CONTAINER_TYPE_LABEL}</TableCell>
            <TableCell sortColumn={PKG_SPLITS_CONTAINER_TYPES_HEADERS.SIZE} width={150}>{SIZE_LABEL}</TableCell>
            <TableCell sortColumn={PKG_SPLITS_CONTAINER_TYPES_HEADERS.VALUE} width={150}>{VALUE_LABEL}</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </Table>
        {pkgSplitsContainerTypesList
          .filter((pkgSplitsContainerTypes) => {
            if (searchTerm == "") {
              return pkgSplitsContainerTypes;
            } else if (
                pkgSplitsContainerTypes.container_type.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return pkgSplitsContainerTypes;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((pkgSplitsContainerTypes) => (
            <div key={pkgSplitsContainerTypes.id}>
              {editPkgSplitsContainerTypesId === pkgSplitsContainerTypes.id ? (
                <EditablePkgSplitsContainerTypesRow
                    pkgSplitsContainerTypes={pkgSplitsContainerTypes}
                    selectedNode={selectedNode}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                    uniqueContainerTypes={uniqueContainerTypes}
                    uniqueSizes={uniqueSizes}
                />
              ) : (
                <ReadOnlyPkgSplitsContainerTypesRow
                    pkgSplitsContainerTypes={pkgSplitsContainerTypes}
                    selectedNode={selectedNode}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default PkgSplitsContainerTypesTable;
