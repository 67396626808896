import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IProcessPathRelationship} from "../../../common/api/api";

/**
 * This component creates the editable row in the process path relationship table
 * @param ppr
 * @param selectedNode
 * @param handleEditFormChange
 * @param handleCancelClick
 * @param uniqueProcessPaths
 * @constructor
 */
function EditablePPRelationshipRow({
                                       ppr,
                                       selectedNode,
                                       handleEditFormChange,
                                       handleCancelClick,
                                       uniqueProcessPaths,
                                   }) {
    const [to, setTo] = useState(ppr.to);
    const [from, setFrom] = useState(ppr.from);

    /**
     * To generate a unique id for the list
     * @param id
     * @param text1
     * @param text2
     */
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    /**
     * Function to handle the submit button
     */
    const handleSaveHandler = () => {
        const editData: IProcessPathRelationship = {
            id: ppr.id,
            from: from.toString(),
            to: to.toString(),
        };
        handleEditFormChange(editData);
    };
    return (
        <Table spacing="small">
            <TableRow key={ppr.id}>
                <TableCell key={getUniqueKey(ppr.id, selectedNode, "node")} width={100}>
                    <Input id="selectedNode" value={selectedNode}/>
                </TableCell>
                <TableCell width={200}>
                    <Select value={from} onChange={setFrom}>
                        {uniqueProcessPaths.map((from) => (
                            <SelectOption
                                key={getUniqueKey(ppr.id, from, "from")}
                                value={from}
                                label={from}
                            />
                        ))}
                    </Select>
                </TableCell>

                <TableCell width={200}>
                    <Select value={to} onChange={setTo}>
                        {uniqueProcessPaths.map((to) => (
                            <SelectOption
                                key={getUniqueKey(ppr.id, to, "to")}
                                value={to}
                                label={to}
                            />
                        ))}
                    </Select>
                </TableCell>

                <TableCell>
                    <Row>
                        <Button minWidth={70} onClick={handleSaveHandler}>
                            Save
                        </Button>
                        <Button minWidth={70} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Row>
                </TableCell>
            </TableRow>
        </Table>
    );
}

export default EditablePPRelationshipRow;
