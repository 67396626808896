import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";

function ReadOnlyRow({
  mhe,
  selectedNode,
  handleEditClick,
  handleDeleteClick,
}) {
  return (
    <>
      <Table>
        <TableRow>
          <TableCell width={100}>
            <Input value={selectedNode} />
          </TableCell>
          <TableCell width={300}>
            <Input value={mhe.processPath} />
          </TableCell>
          <TableCell width={100}>
            <Input value={mhe.quantity} />
          </TableCell>
          <TableCell width={100}>
            <Input value={mhe.isInbound} />
          </TableCell>
          <TableCell>
            <Row>
              <Button minWidth={70} onClick={(event) => handleDeleteClick(mhe)}>
                Delete
              </Button>
              <Button minWidth={70} onClick={(event) => handleEditClick(mhe)}>
                Edit
              </Button>
            </Row>
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
}

export default ReadOnlyRow;
