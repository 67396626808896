import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import React, {useState} from "react";
import {ITEMS_PER_PAGE} from "../../constants";
import Pagination from "@amzn/meridian/pagination";

export default function RunSummary({summaryCsvData}) {
    //For pagination
    const itemsPerPage = ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(summaryCsvData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    //Pagination
    return (<>
        <Table
            showStripes={true}
            headerRows={1}
            showDividers={true}
            spacing={"small"}
        >
            <TableRow>
                <TableCell width={70}>Sort Center</TableCell>
                <TableCell width={100}>Shift</TableCell>
                <TableCell width={100}>Inducted Volume</TableCell>
                <TableCell width={100}>WIP Volume</TableCell>
                <TableCell width={100}>Total Volume</TableCell>
                <TableCell width={100}>Total HC</TableCell>
                <TableCell width={100}>Shift Length</TableCell>
                <TableCell width={100}>Shift Length Available</TableCell>
                <TableCell width={100}>Labor Hours</TableCell>
                <TableCell width={200}>TPH</TableCell>
            </TableRow>
            {summaryCsvData &&
                summaryCsvData.slice(firstVisibleIndex, lastVisibleIndex).map((parsedData, index) => {
                    return <TableRow key={index} >
                        <TableCell>{parsedData['Sort_center']}</TableCell>
                        <TableCell>{parsedData['Shift']}</TableCell>
                        <TableCell>{parsedData['Inducted_volume']}</TableCell>
                        <TableCell>{parsedData['WIP_volume']}</TableCell>
                        <TableCell>{parsedData['Total_volume']}</TableCell>
                        <TableCell>{parsedData['Total_HC']}</TableCell>
                        <TableCell>{parsedData['Shift_length']}</TableCell>
                        <TableCell>{parseFloat(parsedData['Shift_length_available']).toFixed(2)}</TableCell>
                        <TableCell>{parsedData['Labor_hours']}</TableCell>
                        <TableCell>{parseFloat(parsedData['TPH']).toFixed(2)}</TableCell>
                    </TableRow>
                })}
        </Table>
        <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
        />
    </>);
};
