import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import useSelectedNode from "../../components/hooks/nodeContext";
import PPCapacityTable from "./PPCapacityTable";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AmpApi from "../../common/api/AmpApi";
import {IProcessPathCapacity} from "../../common/api/api";
import Loader from "@amzn/meridian/loader";
import Box from "@amzn/meridian/box";
import { Redirect } from "react-router";

function PPCapacity() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [ppCapacityList, setPPCapacityList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }

    //Load Process Path Capacity entries when the page first loads
    useEffect(() => {
        setIsLoading(true);
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getResponse = await AmpApi.getProcessPathCapacity(nodeKey);
                if (getResponse) {
                    const pathCapacities: IProcessPathCapacity[] = getResponse.data
                        .ppCapacities as IProcessPathCapacity[];
                    const finalList = [...pathCapacities];
                    setPPCapacityList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching ${nodeKey}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);
    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <div className="ppCapacityDiv">
                <Card width="fill" spacingInset="none">
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <>
                            <Column spacingInset="400 500" alignmentHorizontal={"center"}>
                                <Box type="outline" spacingInset="400">
                                    <PPCapacityTable selectedNode={nodeKey} ppCapacityList={ppCapacityList}/>
                                </Box>
                            </Column>
                        </>
                    )}
                </Card>
            </div>
        )
    );
}

export default PPCapacity;
