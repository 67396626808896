import {Route, Switch} from "react-router-dom";
import Home from "../../pages/home/Home";
import ConfigNewSite from "../../pages/configNewSite/ConfigNewSite";
import Download from "../../pages/download/Download";
import InboundContainerEligibility from "../../pages/inboundContainerEligibility/InboundContainerEligibility";
import MHEConfig from "../../pages/mheConfig/MHEConfig";
import ProcessPathCapacity from "../../pages/ppCapacity/PPCapacity";
import ProcessPathHeadcount from "../../pages/ppHeadcount/PPHeadcount";
import ProcessPathRates from "../../pages/ppRates/PPRates";
import ProcessPathRelationshipFlow from "../../pages/ppRelationship/flowChart/Flow";
import ProcessPathRelationship from "../../pages/ppRelationship/PPRelationship";
import RunAMP from "../../pages/runAMP/RunAMP";
import DownloadAMPOutput from "../../pages/downloadAMPOutput/DownloadAMPOutput";
import ShiftsConfig from "../../pages/shiftsConfig/ShiftsConfig";
import PkgSplitsContainerTypes from "../../pages/pkgSplitsContainerTypes/PkgSplitsContainerTypes";
import LocationSizeAdmissibility from "../../pages/locationSizeAdmissibility/LocationSizeAdmissibility";
import VolSplitPerLocation from "../../pages/volSplitPerLocation/VolSplitPerLocation";

/**
 * Component to get all the routes.
 * @constructor
 */
function Routes() {
    return (
        <>
            <Switch>
                <Route exact path="/newConfig">
                    <ConfigNewSite/>
                </Route>
                <Route exact path={["/", "/:nodeId"]}>
                    <Home/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/mheConfig">
                    <MHEConfig/>
                </Route>
                <Route
                    exact
                    path="/:nodeId/processPathRelationship/processPathRelationshipFlow"
                >
                    <ProcessPathRelationshipFlow/>
                </Route>
                <Route
                    exact
                    path="/:nodeId/processPathRelationship/processPathRelationshipTable"
                >
                    <ProcessPathRelationship/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/processPathRates">
                    <ProcessPathRates/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/processPathCapacity">
                    <ProcessPathCapacity/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/shiftsConfig">
                    <ShiftsConfig/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/processPathHeadcount">
                    <ProcessPathHeadcount/>
                </Route>
                <Route exact path="/:nodeId/download">
                    <Download/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/inboundContainerEligibility">
                    <InboundContainerEligibility/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/pkgSplitsContainerTypes">
                    <PkgSplitsContainerTypes/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/locationSizeAdmissibility">
                    <LocationSizeAdmissibility/>
                </Route>
                <Route exact path="/:nodeId/manageInputs/volSplitPerLocation">
                    <VolSplitPerLocation/>
                </Route>
                <Route exact path="/:nodeId/runAMP">
                    <RunAMP/>
                </Route>
                <Route exact path="/:nodeId/downloadAMPOutput">
                    <DownloadAMPOutput/>
                </Route>
                <Route exact path="/:nodeId/configNewSite">
                    <ConfigNewSite/>
                </Route>
            </Switch>
        </>
    );
}

export default Routes;
