import React, {useState} from "react"
import Column from "@amzn/meridian/column"
import Tab, {TabGroup} from "@amzn/meridian/tab"
import RunSummary from "./RunSummary";
import {
    AVG_HC_ALLOCATION,
    RUN_SUMMARY,
    VOLUME_BETWEEN_PROCESS_PATHS,
    VOLUME_MIX,
    WIP
} from "../../constants";
import Row from "@amzn/meridian/row";
import VolumeMix from "./VolumeMix";
import AvgHCAllocation from "./AvgHCAllocation";
import VolumeBetweenProcessPaths from "./VolumeBetweenProcessPaths";
import Wip from "./Wip";

const TabDisplay = ({summaryCsvData, hcCsvData, volumeCsvData, volumeProcessPathsCsvData, wipCsvData}) => {
    const [currentTab, setCurrentTab] = useState("RunSummary")

    return (
        <Column>
            <TabGroup value={currentTab} onChange={setCurrentTab}
                      type="classic"
            >
                <Tab value="RunSummary">{RUN_SUMMARY}</Tab>
                <Tab value="AvgHCAllocation">{AVG_HC_ALLOCATION}</Tab>
                <Tab value="VolumeMix">{VOLUME_MIX}</Tab>
                <Tab value="VolumeBetweenProcessPaths">{VOLUME_BETWEEN_PROCESS_PATHS}</Tab>
                <Tab value="Wip">{WIP}</Tab>
            </TabGroup>
            <Row></Row>
            {currentTab == "RunSummary" && <RunSummary summaryCsvData={summaryCsvData}/>}
            {currentTab == "AvgHCAllocation" && <AvgHCAllocation hcCsvData={hcCsvData}/>}
            {currentTab == "VolumeMix" && <VolumeMix volumeCsvData={volumeCsvData}/>}
            {currentTab == "VolumeBetweenProcessPaths" &&
                <VolumeBetweenProcessPaths volumeProcessPathsCsvData={volumeProcessPathsCsvData}/>}
            {currentTab == "Wip" && <Wip wipCsvData={wipCsvData}/>}
        </Column>
    )
}
export default TabDisplay;
