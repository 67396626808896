import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IInboundContainerEligibility} from "../../common/api/api";

function EditableIbceRow({
                             ibce,
                             selectedNode,
                             handleEditFormChange,
                             handleCancelClick,
                             uniqueSizes,
                             uniqueContainers,
                             uniqueInboundProcesses,
                         }) {
    const [container, setContainer] = useState(ibce.container);
    const [size, setSize] = useState(ibce.size);
    const [value, setValue] = useState(ibce.value);
    const [inbound_process, setInbound_process] = useState(ibce.inbound_process);

    /**
     * To generate unique id for the list
     * @param id
     * @param text1
     * @param text2
     */
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    //Error checking
    const [valueValid, setValueValid] = useState(true);
    const [valueErrorMessage, setValueErrorMessage] = useState("");
    //Function to handle submit
    const saveHandler = () => {
        if(value==""){
            setValueValid(false);
            setValueErrorMessage("Empty!");
        }
        if (parseInt(value)<=-1) {
            setValueValid(false);
            setValueErrorMessage("Invalid!");
        } else {
            const editData: IInboundContainerEligibility = {
                id: ibce.id,
                container: container,
                size: size,
                inbound_process: inbound_process,
                value: parseInt(value),
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <>
            <Table spacing="small">
                <TableRow key={ibce.id}>
                    <TableCell
                        key={getUniqueKey(ibce.id, selectedNode, "selectedNode")}
                        width={100}
                    >
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={container} onChange={setContainer}>
                            {uniqueContainers.map((container) => (
                                <SelectOption
                                    key={getUniqueKey(ibce.id, container, "container")}
                                    value={container}
                                    label={container}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={size} onChange={setSize}>
                            {uniqueSizes.map((size) => (
                                <SelectOption
                                    key={getUniqueKey(ibce.id, size, "size")}
                                    value={size}
                                    label={size}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={inbound_process} onChange={setInbound_process}>
                            {uniqueInboundProcesses.map((inbound_process) => (
                                <SelectOption
                                    key={getUniqueKey(ibce.id, inbound_process, "inbound_process")}
                                    value={inbound_process}
                                    label={inbound_process}
                                />
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell width={90}>
                        <Input id="value" value={value} type="number" onChange={setValue} placeholder="Value"
                               error={!valueValid}
                               errorMessage={valueErrorMessage}/>
                    </TableCell>
                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={saveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                        <Row>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
}

export default EditableIbceRow;
