import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IShiftsConfig} from "../../common/api/api";

function EditableShiftsConfigRow({
                                     sc,
                                     selectedNode,
                                     handleEditFormChange,
                                     handleCancelClick,
                                 }) {
    const [shift, setShift] = useState(sc.shift);
    const [min_hc, setMinHc] = useState(sc.min_hc);
    const [maxHc, setMaxHc] = useState(sc.max_hc);
    const [min_length, setMinLength] = useState(sc.min_length);
    const [max_length, setMaxLength] = useState(sc.max_length);
    //For input validation
    const [minHcValid, setMinHcValid] = useState(true);
    const [maxHcValid, setMaxHcValid] = useState(true);
    const [min_lengthValid, setMin_lengthHcValid] = useState(true);
    const [max_lengthValid, setMax_lengthValid] = useState(true);
    const [minHcErrorMessage, setMinHcErrorMessage] = useState("");
    const [maxHcErrorMessage, setMaxHcErrorMessage] = useState("");
    const [minLengthErrorMessage, setMinLengthErrorMessage] = useState("");
    const [maxLengthErrorMessage, setMaxLengthErrorMessage] = useState("");
    /**
     * To generate a unique key
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}-${text}`;
    };

    /**
     * Function to handle submit button
     */
    const saveHandler = () => {
        if (!min_hc) {
            setMinHcValid(false);
            setMinHcErrorMessage("Empty!");
        }
        if (!maxHc) {
            setMaxHcValid(false);
            setMaxHcErrorMessage("Empty!");
        }
        if (!min_length) {
            setMin_lengthHcValid(false);
            setMinLengthErrorMessage("Empty!");
        }
        if (!max_length) {
            setMax_lengthValid(false);
            setMaxLengthErrorMessage("Empty!");
        } else {
            const editData: IShiftsConfig = {
                id: sc.id,
                shift: shift,
                min_hc: parseInt(min_hc),
                max_hc: parseInt(maxHc),
                min_length: parseInt(min_length),
                max_length: parseInt(max_length),
            };
            handleEditFormChange(editData);
        }
    };
    return (
        <>
            <Table spacing="small">
                <TableRow key={sc.id}>
                    <TableCell
                        key={getUniqueKey(sc.id, "selectedNode")}
                        width={100}
                    >
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>

                    <TableCell width={100}>
                        <Select value={shift} onChange={setShift}>
                            <SelectOption
                                key={getUniqueKey(sc.id, "1-SUN")}
                                value="1-SUN"
                                label="1-SUN"
                            />
                            <SelectOption
                                key={getUniqueKey(sc.id, "2-MOR")}
                                value="2-MOR"
                                label="2-MOR"
                            />
                            <SelectOption
                                key={getUniqueKey(sc.id, "3-DAY")}
                                value="3-DAY"
                                label="3-DAY"
                            />
                            <SelectOption
                                key={getUniqueKey(sc.id, "4-TWI")}
                                value="4-TWI"
                                label="4-TWI"
                            />
                            <SelectOption
                                key={getUniqueKey(sc.id, "5-NIT")}
                                value="5-NIT"
                                label="5-NIT"
                            />
                        </Select>
                    </TableCell>

                    <TableCell key={getUniqueKey(sc.id, "min_hc")} width={100}>
                        <Input
                            id="min_hc"
                            type="number"
                            value={min_hc}
                            onChange={setMinHc}
                            placeholder="min_hc"
                            error={!minHcValid}
                            errorMessage={minHcErrorMessage}
                        ></Input>
                    </TableCell>

                    <TableCell key={getUniqueKey(sc.id, "max_hc")} width={100}>
                        <Input
                            id="maxHc"
                            value={maxHc}
                            type="number"
                            onChange={setMaxHc}
                            placeholder="max_hc"
                            error={!maxHcValid}
                            errorMessage={maxHcErrorMessage}
                        ></Input>
                    </TableCell>

                    <TableCell
                        key={getUniqueKey(sc.id, "min_length")}
                        width={70}
                    >
                        <Input
                            id="min_length"
                            type="number"
                            value={min_length}
                            onChange={setMinLength}
                            error={!min_lengthValid}
                            errorMessage={minLengthErrorMessage}
                        ></Input>
                    </TableCell>

                    <TableCell
                        key={getUniqueKey(sc.id, "max_length")}
                        width={70}
                    >
                        <Input
                            id="max_length"
                            type="number"
                            value={max_length}
                            onChange={setMaxLength}
                            error={!max_lengthValid}
                            errorMessage={maxLengthErrorMessage}
                        ></Input>
                    </TableCell>

                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={saveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
}

export default EditableShiftsConfigRow;
