import Masthead, {MastheadMenuButton, MastheadTitle,} from "@amzn/meridian/masthead";
import {colorGray100, colorGrayMuted200,} from "@amzn/meridian-tokens/base/color";
import Text from "@amzn/meridian/text";
import {SORT_CENTER_MESSAGE, USERNAME_KEY, WELCOME_MESSAGE} from "../../constants";
import useSidebar from "../hooks/SidebarContext";
import useSelectedNode from "../hooks/nodeContext";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import Row from "@amzn/meridian/row";
import Marquee from "react-fast-marquee";

export default function AmpMasthead() {
    const {sidebar, setSidebar} = useSidebar();
    const userAlias = localStorage.getItem(USERNAME_KEY);
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const {nodeId} = useParams<{ nodeId: string }>();

    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
    }, [nodeId]);

    return (
        <>
            <Masthead backgroundColor={colorGray100}>
                <MastheadMenuButton onClick={setSidebar} open={true}/>
                <MastheadTitle href="/">
                    <Text>Asset Maximization Planner</Text>
                </MastheadTitle>
                <Text>Hello, {JSON.parse(userAlias)}</Text>
            </Masthead>
            <Marquee pauseOnClick={true} pauseOnHover={true} gradient={false} speed={35}>{selectedNode==null ? <Text>{WELCOME_MESSAGE}</Text>:
                <Text className="nodeIdColor">{SORT_CENTER_MESSAGE}{selectedNode}</Text>}</Marquee>
        </>
    );
}
