import AppLayout from "@amzn/meridian/app-layout";
import AmpMasthead from "../ampMasthead/AmpMasthead";
import AmpSideMenu from "../ampSideMenu/AmpSideMenu";
import Footer from "../footer/Footer";

/**
 * The basic layout with Masthead, SideMenu, Footer (Using Meridian Components)
 * @param children
 * @constructor
 */
export default function AmpLayout({children}) {
    return (
        <>
            <AppLayout
                headerComponent={AmpMasthead}
                sidebarComponent={AmpSideMenu}
                footerComponent={Footer}
                backgroundColor="alternateSecondary"
            >
                <AmpMasthead/>
                <AmpSideMenu/>
                {children}
                <Footer/>
            </AppLayout>
        </>
    );
}
