import React, {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IPkgSplitsContainerType} from "../../common/api/api";

function EditablePkgSplitsContainerTypesRow({
                                   pkgSplitsContainerTypes,
                                   selectedNode,
                                   handleEditFormChange,
                                   handleCancelClick,
                                   uniqueSizes,
                                   uniqueContainerTypes,
                               }) {
    const [containerType, setContainerType] = useState(pkgSplitsContainerTypes.container_type);
    const [size, setSize] = useState(pkgSplitsContainerTypes.size);
    const [value, setValue] = useState(pkgSplitsContainerTypes.value);

    /**
     * To generate a unique id for the list
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}-${text}`;
    };
//For input validation
    const [valueValid, setValueValid] = useState(true);
    const [valueErrorMessage, setvalueErrorMessage] = useState("");

    /**
     * Function to handle submit button
     */
    const saveHandler = () => {
        if (parseFloat(value) < 0) {
            setValueValid(false);
            setvalueErrorMessage("Empty!");
        } else {
            const editData: IPkgSplitsContainerType = {
                id: pkgSplitsContainerTypes.id,
                container_type: containerType,
                size: size,
                value: parseFloat(value),
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <Table spacing="small">
            <TableRow key={containerType.id}>
                <TableCell
                    key={getUniqueKey(containerType.id, selectedNode)}
                    width={100}
                >
                    <Input id="selectedNode" value={selectedNode}/>
                </TableCell>
                <TableCell width={200}>
                    <Select value={containerType} onChange={setContainerType}>
                        {uniqueContainerTypes.map((containerType) => (
                            <SelectOption
                                key={getUniqueKey(containerType.id, containerType)}
                                value={containerType}
                                label={containerType}
                            />
                        ))}
                    </Select>
                </TableCell>
                <TableCell width={150}>
                    <Select value={size} onChange={setSize}>
                        {uniqueSizes.map((size) => (
                            <SelectOption
                                key={getUniqueKey(containerType.id, size)}
                                value={size}
                                label={size}
                            />
                        ))}
                    </Select>
                </TableCell>
                <TableCell key={getUniqueKey(containerType.id, "value")} width={150}>
                    <Input
                        id="value"
                        value={value}
                        type="number"
                        onChange={setValue}
                        error={!valueValid}
                        errorMessage={valueErrorMessage}
                    ></Input>
                </TableCell>
                <TableCell>
                    <Row>
                        <Button minWidth={70} onClick={saveHandler}>
                            Save
                        </Button>
                        <Button minWidth={70} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Row>
                </TableCell>
            </TableRow>
        </Table>
    );
}

export default EditablePkgSplitsContainerTypesRow;
