import React, {useCallback, useState} from "react"
import Table, {TableActionBar, TableCell, TableRow} from "@amzn/meridian/table"
import {
    AMP_OUTPUT_ERROR_FILE_NAME,
    AMP_OUTPUT_ZIP_FILE_NAME,
    CREATION_DATE_TIME_LABEL,
    DEFAULT_SORT_COLUMN,
    DEFAULT_SORT_DIRECTION,
    DOWNLOAD_OUTPUT_OR_ERROR_FILE_LABEL,
    ITEMS_PER_PAGE,
    RUN_ID_LABEL,
    STATUS,
    STATUS_LABEL,
    CREATED_BY_LABEL,
    RUN_ARGUMENTS_LABEL
} from "../../constants";
import AmpApi from "../../common/api/AmpApi";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Pagination from "@amzn/meridian/pagination";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import TabDisplay from "./TabDisplay";
import {TableSortDirection} from "@amzn/meridian/table/table";
import Input from "@amzn/meridian/input";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import * as XLSX from "xlsx";
import Papa from "papaparse";

const DownloadAMPOutputTable = ({ampModelRunList, selectedNode}) => {
    //For pagination
    const itemsPerPage = ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(ampModelRunList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    //Pagination
    //For the view modal
    const [summaryCsvData, setSummaryCsvData] = useState([]);
    const [hcCsvData, setHCCsvData] = useState([]);
    const [volumeCsvData, setVolumeCsvData] = useState([]);
    const [volumeProcessPathsCsvData, setVolumeProcessPathsCsvData] = useState([]);
    const [wipCsvData, setWipCsvData] = useState([]);
    const [open, setOpen] = useState(false)
    const onClickFooterButton = useCallback(() => setOpen(false), [])
    const onClose = useCallback(() => setOpen(false), [])
    //View Modal ends
    //useState for filtering table
    const [searchTerm, setSearchTerm] = useState("");
//For sorting table columns
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN)
    const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
    const onSort = useCallback(({sortColumn, sortDirection}) => {
        setSortDirection(sortDirection)
        setSortColumn(sortColumn)
    }, [])
    const handleDownloadClick = async (ampModelRun) => {
        // setIsLoading(true);
        try {
            let fileName = AMP_OUTPUT_ZIP_FILE_NAME;
            if (ampModelRun.status === STATUS.FAILED) {
                fileName = AMP_OUTPUT_ERROR_FILE_NAME;
            }
            const downloadAmpModelOutputResponse =
                await AmpApi.downloadAmpModelOutput(ampModelRun.runId, fileName);
            if (downloadAmpModelOutputResponse) {
                window.open(downloadAmpModelOutputResponse.data.downloadUrl, "_blank")
            }
        } catch (ex: any) {
            console.error(`Error Downloading Output for Node ${selectedNode}`, ex);
        }
    };

    const handleViewClick = async (ampModelRun) => {
        try {
            // TODO: This is only for POC project. We need to fetch file name based on RUN_TYPE.
            const fileName = "Pre_shift_planning_summary.xlsx"

            const downloadAmpModelOutputResponse =
                await AmpApi.downloadAmpModelOutput(ampModelRun.runId, fileName);
            if (downloadAmpModelOutputResponse) {
                setOpen(true);
                await fetch(downloadAmpModelOutputResponse.data.downloadUrl)
                    .then(async (response) => {
                        // Convert response to binary string
                        const arrayBuffer = await response.arrayBuffer();
                        const data = new Uint8Array(arrayBuffer);
                        const arr = [];
                        for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
                        const binaryString = arr.join('');

                        const workbook = XLSX.read(binaryString, {type: "binary"});

                        // Summary Sheet
                        const summarySheet = workbook.SheetNames[0];
                        const summaryCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[summarySheet]);
                        const summaryResults = Papa.parse(summaryCsv, {header: true}).data;
                        setSummaryCsvData(summaryResults);

                        // Head Count Sheet
                        const hcSheet = workbook.SheetNames[1];
                        const hcCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[hcSheet]);
                        const hcResults = Papa.parse(hcCsv, {header: true}).data;
                        setHCCsvData(hcResults);

                        // Volume Per Process Path Sheet
                        const volumeProcessPathSheet = workbook.SheetNames[2];
                        const volumeProcessPathCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[volumeProcessPathSheet]);
                        const volumeProcessPathResults = Papa.parse(volumeProcessPathCsv, {header: true}).data;
                        setVolumeProcessPathsCsvData(volumeProcessPathResults);

                        // Volume Mix Sheet
                        const volumeSheet = workbook.SheetNames[3];
                        const volumeCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[volumeSheet]);
                        const volumeResults = Papa.parse(volumeCsv, {header: true}).data;
                        setVolumeCsvData(volumeResults);

                        // Volume Process Path Sheet
                        const wipSheet = workbook.SheetNames[4];
                        const wipCsv = XLSX.utils.sheet_to_csv(workbook.Sheets[wipSheet]);
                        const wipResults = Papa.parse(wipCsv, {header: true}).data;
                        setWipCsvData(wipResults);
                    })
            } else {
                setSummaryCsvData([]);
                setHCCsvData([]);
                setVolumeCsvData([]);
                setVolumeProcessPathsCsvData([]);
                setWipCsvData([]);
            }
        } catch (ex: any) {
            console.error(`Error Viewing Output for Node ${selectedNode}`, ex);
        }
    }

    ampModelRunList.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) {
            return sortDirection === "ascending" ? -1 : 1
        }
        if (a[sortColumn] > b[sortColumn]) {
            return sortDirection === "ascending" ? 1 : -1
        }
        return 0
    })

    return (
        <>
            <Modal
                title="View AMP Output"
                open={open}
                onClose={onClose}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description"
            >
                <TabDisplay
                    summaryCsvData={summaryCsvData}
                    volumeCsvData={volumeCsvData}
                    hcCsvData={hcCsvData}
                    volumeProcessPathsCsvData={volumeProcessPathsCsvData}
                    wipCsvData={wipCsvData}
                />
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="primary" size="small" onClick={onClickFooterButton}>
                            Close
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <Table
                headerRows={1}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                onSort={onSort}
                showDividers={true}
                spacing={"small"}
            >
                <TableActionBar>
                    <Input
                        key="searchInput"
                        type="text"
                        value={searchTerm}
                        placeholder="Search by RunId"
                        prefixIconTokens={filterTokenIcon}
                        onChange={setSearchTerm}
                    />
                </TableActionBar>
                <TableRow>
                    <TableCell sortColumn={"runId"}>{RUN_ID_LABEL}</TableCell>
                    <TableCell sortColumn={"creationTimeEpoch"}>{CREATION_DATE_TIME_LABEL}</TableCell>
                    <TableCell sortColumn={"createdBy"}>{CREATED_BY_LABEL}</TableCell>
                    <TableCell sortColumn={"status"}>{STATUS_LABEL}</TableCell>
                    <TableCell>{RUN_ARGUMENTS_LABEL}</TableCell>
                    <TableCell
                        sortColumn={DOWNLOAD_OUTPUT_OR_ERROR_FILE_LABEL}>{DOWNLOAD_OUTPUT_OR_ERROR_FILE_LABEL}</TableCell>
                </TableRow>
                {ampModelRunList.filter((ampModelRun) => {
                    if (searchTerm == "") {
                        return ampModelRun;
                    } else if (
                        ampModelRun.runId.toLowerCase().includes(searchTerm.toLowerCase())
                    ) {
                        return ampModelRun;
                    }
                })
                    .slice(firstVisibleIndex, lastVisibleIndex).map(ampModelRun => {
                        const timestamp = new Date(ampModelRun.creationTimeEpoch).toUTCString();
                        return <TableRow key={ampModelRun.runId}>
                            <TableCell width={100}>{ampModelRun.runId}</TableCell>
                            <TableCell width={100}>{timestamp}</TableCell>
                            <TableCell width={80}>{ampModelRun.createdBy}</TableCell>
                            { (ampModelRun.status === 'COMPLETED' && (ampModelRun.totalVolume !== ampModelRun.volumeProcessed)) ? (
                                <TableCell width={200}>
                                    <Text className="partialRun">{ampModelRun.status}
                                        <br/>Volume Provided by User: {ampModelRun.totalVolume}
                                        <br/>Max Achievable Volume: {ampModelRun.volumeProcessed}
                                    </Text>
                                </TableCell>
                                ) : ampModelRun.status === 'COMPLETED' ? (
                                <TableCell width={200}><Text className="completeRun">{ampModelRun.status}</Text></TableCell>
                                ) : ampModelRun.status === 'FAILED' ? (
                                <TableCell width={200}><Text className="failedRun">{ampModelRun.status}</Text></TableCell>
                                ) : (
                                <TableCell width={200}>{ampModelRun.status}</TableCell>
                                )
                            }
                            { ampModelRun.runArguments ? (
                                <TableCell width={60}>
                                    <pre>
                                        {JSON.stringify(ampModelRun.runArguments, null, 1)
                                            .replace(/[{"}]/g, '')}
                                    </pre>
                                </TableCell>
                                ) : (
                                    <TableCell width={60}>Not Available</TableCell>
                                )
                            }
                            <TableCell width={80}>
                                <Row>
                                    <Button
                                        disabled={ampModelRun.status !== STATUS.COMPLETED}
                                        minWidth={80}
                                        onClick={(event) => handleViewClick(ampModelRun)}
                                    >
                                        View
                                    </Button>
                                    <Button
                                        disabled={ampModelRun.status === STATUS.IN_PROGRESS ||
                                            ampModelRun.status === STATUS.TIMED_OUT}
                                        onClick={(event) => handleDownloadClick(ampModelRun)}
                                    >
                                        Download
                                    </Button>
                                </Row></TableCell>
                        </TableRow>
                    })}
            </Table>
            <Pagination
                showSkipArrows={true}
                numberOfPages={numberOfPages}
                onChange={setCurrentPage}
                currentPage={currentPage}
            />
        </>
    )
}
export default DownloadAMPOutputTable;
