import { useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import CreatableSelect from "react-select/creatable";
import { nanoid } from "nanoid";

function PPRelationshipAddForm({
  ppRelList,
  selectedNode,
  addFormHandler,
  cancelHandler,
  uniqueProcessPaths,
}) {
  uniqueProcessPaths = uniqueProcessPaths.map((str) => ({
    value: str,
    label: str,
  }));
  /**
   * To generate a unique key.
   * @param id
   * @param text1
   * @param text2
   */
  const getUniqueKey = (id, text1, text2) => {
    return `${id}$${text1}$${text2}`;
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted green",
      color: state.isFocused ? "Forest" : "Forest",
      padding: 10,
      "&:hover": { borderColor: "green" }, // border style on hover
      boxShadow: "none", // no box-shadow
    }),
  };
  //To create new input
  const [to, setTo] = useState("");
  const [from, setFrom] = useState("");

  //For input validation
  const [errorMessage, setErrorMessage] = useState("");

  function submitHandler(event) {
    event.preventDefault();
    if (!to || !from) {
      setErrorMessage("All fields must be filled!");
    } else {
      const addData = {
        id: nanoid(),
        to: to["label"],
        from: from["label"],
      };
      addFormHandler(addData);
    }
  }

  return (
    <>
      <Column>
        <form onSubmit={submitHandler}>
          <Column>
            <Heading level={4}>Add New MHE_Config</Heading>
            <Row>
              <label>From</label>
              <div style={{ width: "300px" }}>
                <CreatableSelect
                  isClearable
                  onChange={setFrom}
                  options={uniqueProcessPaths}
                  value={from}
                  styles={customStyles}
                  placeholder="From"
                />
              </div>
              <label>To</label>
              <div style={{ width: "300px" }}>
                <CreatableSelect
                  isClearable
                  onChange={setTo}
                  options={uniqueProcessPaths}
                  value={to}
                  styles={customStyles}
                  placeholder="To"
                />
              </div>
            </Row>
            <Box>
              <Row>
                <Button submit={true}>Add</Button>
                <Button onClick={cancelHandler}>Cancel</Button>
                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
              </Row>
            </Box>
            <Row></Row>
          </Column>
        </form>
      </Column>
    </>
  );
}

export default PPRelationshipAddForm;
