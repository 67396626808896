import { useCallback, useState } from "react";
import Column from "@amzn/meridian/column";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import Pagination from "@amzn/meridian/pagination";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DEFAULT_SORT_DIRECTION,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_SHIFTS_CONFIG,
  ITEMS_PER_PAGE,
  MAX_HC_LABEL,
  MAX_LENGTH_LABEL,
  MIN_HC_LABEL,
  MIN_LENGTH_LABEL,
  PLEASE_WAIT_STATUS,
  SHIFT_LABEL,
  SHIFTSINFO_HEADERS,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
} from "../../constants";
import Button from "@amzn/meridian/button";
import ShiftsConfigAddForm from "./ShiftsConfigAddForm";
import EditableShiftsConfigRow from "./EditableShiftsConfigRow";
import ReadOnlyScRow from "./ReadOnlyScRow";
import { IId, IShiftsConfig } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Input from "@amzn/meridian/input";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function ShiftsConfigTable({ selectedNode, shiftsConfigList }) {
  //useState for the shifts_config list
  const [scList, setScList] = useState(shiftsConfigList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(scList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(SHIFTSINFO_HEADERS.SHIFT)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IShiftsConfig = {
    id: "",
    shift: "",
    min_hc: 0,
    max_hc: 0,
    min_length: 0,
    max_length: 0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new shifts_config record
   * @param addData The new shifts_config to be added.
   */
  function addFormHandler(addData) {
    //check if the unique values already exist in the list
    let result = scList.filter(function (v, i) {
      return v.shift == addData.shift;
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_SHIFTS_CONFIG);
      onOpenToast();
    } else {
      const addList: IShiftsConfig[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updateShiftsConfig(selectedNode, finalList);
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setScList([addData, ...scList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editScId, setEditScId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * Function to update id for the record
   */
  function generateId(text1, text2) {
    return `${text1}$${text2}`;
  }

  /**
   * This function Updates the Shifts_Config info
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    //check if the unique values already exist in the list
    let result = scList.filter(function (v, i) {
      return v.shift == newFormData.shift;
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_SHIFTS_CONFIG);
      onOpenToast();
    } else {
      const updateList: IShiftsConfig[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updateShiftsConfig(selectedNode, finalList);
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          const newEditScList = [...scList];
          setEditFormData(newFormData);
          const index = newEditScList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          newEditScList[index] = newFormData;
          setScList(newEditScList);
          setEditScId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (sc) => {
    setEditScId(sc.id);
    const formValues: IShiftsConfig = {
      id: sc.id,
      shift: sc.processPath,
      min_hc: sc.min_hc,
      max_hc: sc.max_hc,
      min_length: sc.min_length,
      max_length: sc.max_length,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditScId(null);
  };

  /**
   * To handle the delete button
   * @param sc The Process Path Rates object to be deleted
   */
  const handleDeleteClick = (sc) => {
    const idsList: IId[] = [];
    const finalIds: IId[] = [sc, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deleteShiftsConfig(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const newScList = [...scList];
        const index = newScList.findIndex((obj) => {
          return obj.id === sc.id;
        });
        newScList.splice(index, 1);
        setScList(newScList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${sc}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  scList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <ShiftsConfigAddForm
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              selectedNode={selectedNode}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Search by Shift"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={SHIFTSINFO_HEADERS.SHIFT} width={100}>{SHIFT_LABEL}</TableCell>
            <TableCell sortColumn={SHIFTSINFO_HEADERS.MIN_HC} width={100}>{MIN_HC_LABEL}</TableCell>
            <TableCell sortColumn={SHIFTSINFO_HEADERS.MAX_HC} width={100}>{MAX_HC_LABEL}</TableCell>
            <TableCell sortColumn={SHIFTSINFO_HEADERS.MIN_LENGTH} width={70}>{MIN_LENGTH_LABEL}</TableCell>
            <TableCell sortColumn={SHIFTSINFO_HEADERS.MAX_LENGTH} width={70}>{MAX_LENGTH_LABEL}</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </Table>

        {scList
          .filter((sc) => {
            if (searchTerm == "") {
              return sc;
            } else if (
              sc.shift.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return sc;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((sc) => (
            <div key={sc.id}>
              {editScId === sc.id ? (
                <EditableShiftsConfigRow
                  sc={sc}
                  selectedNode={selectedNode}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                />
              ) : (
                <ReadOnlyScRow
                  sc={sc}
                  selectedNode={selectedNode}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}

        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default ShiftsConfigTable;
