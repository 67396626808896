import {Switch, useParams} from "react-router-dom";
import AmpLayout from "./layout/AmpLayout";
import Routes from "./routes/Routes";
import useSelectedNode, {NodeProvider} from "./hooks/nodeContext";
import "./app.scss";
import {SidebarProvider} from "./hooks/SidebarContext";
import useNodeList, {NodeListProvider} from "./hooks/NodeListContext";
import {useEffect} from "react";
import AmpApi from "../common/api/AmpApi";
import {IId} from "../common/api/api";
import {NODE_LIST_KEY} from "../constants";

export default function App() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const {setNodeList} = useNodeList();
    const {nodeId} = useParams<{ nodeId: string }>();
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getAllNodeIdsResponse = await AmpApi.getAllNodeIds();
                if (getAllNodeIdsResponse) {
                    const ids: IId[] = getAllNodeIdsResponse.data.ids;
                    const finalList: IId[] = [...ids];
                    //Add nodeList to the localStorage
                    localStorage.setItem(NODE_LIST_KEY, JSON.stringify(finalList));
                    setNodeList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching node list`, ex);
            }
        })();
    }, []);

    return (
        <NodeListProvider>
            <SidebarProvider>
                <NodeProvider>
                    <Switch>
                        <AmpLayout>
                            <Routes/>
                        </AmpLayout>
                    </Switch>
                </NodeProvider>
            </SidebarProvider>
        </NodeListProvider>
    );
}
