import { useCallback, useState } from "react";
import Column from "@amzn/meridian/column";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import Pagination from "@amzn/meridian/pagination";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DEFAULT_SORT_DIRECTION,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_PP_RATES,
  ITEMS_PER_PAGE,
  PLEASE_WAIT_STATUS,
  PPRATES_HEADERS,
  PROCESS_PATH_LABEL,
  RATE_LABEL,
  ROLE_LABEL,
  SIZE_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
} from "../../constants";
import Button from "@amzn/meridian/button";
import PPRatesAddForm from "./PPRatesAddForm";
import EditablePPRatesRow from "./EditablePPRatesRow";
import ReadOnlyPPRatesRow from "./ReadOnlyPPRatesRow";
import { IId, IProcessPathRates } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Input from "@amzn/meridian/input";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function PPRatesTable({ selectedNode, ppRatesList }) {
  //useState for the mhe list
  const [pprList, setPPRList] = useState(ppRatesList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(pprList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(PPRATES_HEADERS.PROCESS_PATH)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IProcessPathRates = {
    id: "",
    process_path: "",
    role: "",
    size: "",
    rate: 0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new Process Path Rate record
   * @param addData The new Process Path Rate to be added.
   */
  function addFormHandler(addData) {
    let result = pprList.filter(function (v, i) {
      return (
        v.process_path == addData.process_path &&
        v.size == addData.size &&
        v.role == addData.role
      );
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_PP_RATES);
      onOpenToast();
    } else {
      const addList: IProcessPathRates[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updatePPRates(selectedNode, finalList);
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setPPRList([addData, ...pprList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editPPRId, setEditPPRId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * This function Updates the process path rates info
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    let result = pprList.filter(function (v, i) {
      return (
        v.process_path == newFormData.process_path &&
        v.size == newFormData.size &&
        v.role == newFormData.role
      );
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_PP_RATES);
      onOpenToast();
    } else {
      const newEditPPRList = [...pprList];
      const updateList: IProcessPathRates[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updatePPRates(selectedNode, finalList);
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          setEditFormData(newFormData);
          const index = newEditPPRList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          newEditPPRList[index] = newFormData;
          setPPRList(newEditPPRList);
          setEditPPRId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (ppr) => {
    setEditPPRId(ppr.id);
    const formValues: IProcessPathRates = {
      id: ppr.id,
      process_path: ppr.process_path,
      role: ppr.role,
      size: ppr.size,
      rate: ppr.rate,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditPPRId(null);
  };

  /**
   * To handle the delete button
   * @param ppr The Process Path Rates object to be deleted
   */
  const handleDeleteClick = (ppr) => {
    const newPPRList = [...pprList];
    const idsList: IId[] = [];
    const finalIds: IId[] = [ppr, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deletePPRates(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const index = newPPRList.findIndex((obj) => {
          return obj.id === ppr.id;
        });
        newPPRList.splice(index, 1);
        setPPRList(newPPRList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${ppr}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };
  //To get a list of unique Process Path, Size, Role
  let processPathsOnly = ppRatesList.map((a) => a.process_path);
  let uniqueProcessPaths = [...new Set(processPathsOnly)];
  let sizes = ppRatesList.map((a) => a.size);
  let uniqueSizes = [...new Set(sizes)];
  let roles = ppRatesList.map((a) => a.role);
  let uniqueRoles = [...new Set(roles)];

  ppRatesList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <PPRatesAddForm
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              uniqueSizes={uniqueSizes}
              uniqueRoles={uniqueRoles}
              uniqueProcessPaths={uniqueProcessPaths}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Search by Process Path Name"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={PPRATES_HEADERS.PROCESS_PATH} width={200}>{PROCESS_PATH_LABEL}</TableCell>
            <TableCell sortColumn={PPRATES_HEADERS.SIZE} width={150}>{SIZE_LABEL}</TableCell>
            <TableCell sortColumn={PPRATES_HEADERS.ROLE} width={150}>{ROLE_LABEL}</TableCell>
            <TableCell sortColumn={PPRATES_HEADERS.RATE} width={100}>{RATE_LABEL}</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </Table>
        {pprList
          .filter((ppr) => {
            if (searchTerm == "") {
              return ppr;
            } else if (
              ppr.process_path.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return ppr;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((ppr) => (
            <div key={ppr.id}>
              {editPPRId === ppr.id ? (
                <EditablePPRatesRow
                  ppr={ppr}
                  selectedNode={selectedNode}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                  uniqueSizes={uniqueSizes}
                  uniqueRoles={uniqueRoles}
                  uniqueProcessPaths={uniqueProcessPaths}
                />
              ) : (
                <ReadOnlyPPRatesRow
                  ppr={ppr}
                  selectedNode={selectedNode}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default PPRatesTable;
