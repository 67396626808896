import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IMheConfig} from "../../common/api/api";
import CreatableSelect from "react-select/creatable";
import {nanoid} from "nanoid";

export default function MHEConfigAddForm({
                                             addFormHandler,
                                             cancelHandler,
                                             uniqueProcessPaths,
                                             selectedNode
                                         }) {
    const [quantity, setQuantity] = useState("");
    const [isInbound, setIsInbound] = useState("");
    const [processPath, setProcessPath] = useState("");

    //Convert processPath to hold value and label
    uniqueProcessPaths = uniqueProcessPaths.map((str) => ({
        value: str,
        label: str,
    }));

    /**
     * To generate a unique key for each list item
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}$${text}`;
    };

    //Error checking
    const [errorMessage, setErrorMessage] = useState("");

    function submitHandler(event) {
        event.preventDefault();
        if (!processPath || !quantity || !isInbound) {
            setErrorMessage("Fill all the fields before submitting!");
        } else {
            const addData: IMheConfig = {
                id: nanoid(),
                processPath: processPath['label'],
                quantity: parseInt(quantity),
                isInbound: parseInt(isInbound),
            };
            addFormHandler(addData);
        }
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Forest" : "Forest",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };

    return (
        <>
            <Column>
                <form onSubmit={submitHandler}>
                    <Column>
                        <Heading level={4}>Add New MHE_Config</Heading>
                        <Row minHeight={50} maxHeight={50}>
                            <div style={{width: "300px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setProcessPath}
                                    options={uniqueProcessPaths}
                                    value={processPath}
                                    styles={customStyles}
                                    placeholder="Process Path"
                                />
                            </div>
                            <Input
                                className="inputMHE"
                                name="quantity"
                                value={quantity}
                                onChange={setQuantity}
                                type="number"
                                width={100}
                                placeholder="Quantity"
                                size="medium"
                            />
                            <Select
                                name="isInbound"
                                value={isInbound}
                                onChange={setIsInbound}
                                placeholder="Is Inbound?"
                                width={150}
                                size="medium"
                            >
                                <SelectOption
                                    value="0"
                                    label="0"
                                />
                                <SelectOption
                                    value="1"
                                    label="1"
                                />
                            </Select>
                        </Row>
                        <Column>
                            <Row>
                                <Button submit={true}>Add</Button>
                                <Button onClick={cancelHandler}>Cancel</Button>
                                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                            </Row>
                        </Column>
                        <Row></Row>
                    </Column>
                </form>
            </Column>
        </>
    );
}
