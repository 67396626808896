import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IVolSplitPerLocation} from "../../common/api/api";

function EditableVolSplitPerLocationRow({
                             volSplitPerLocation,
                             selectedNode,
                             handleEditFormChange,
                             handleCancelClick,
                             uniqueLocations,
                         }) {
    const [location, setLocation] = useState(volSplitPerLocation.location);
    const [value, setValue] = useState(volSplitPerLocation.value);

    /**
     * To generate unique id for the list
     * @param id
     * @param text1
     * @param text2
     */
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    //Error checking
    const [valueValid, setValueValid] = useState(true);
    const [valueErrorMessage, setValueErrorMessage] = useState("");
    //Function to handle submit
    const saveHandler = () => {
        if(value==""){
            setValueValid(false);
            setValueErrorMessage("Empty!");
        }
        else if (parseFloat(value)<=0) {
            setValueValid(false);
            setValueErrorMessage("Invalid Value!");
        } else {
            const editData: IVolSplitPerLocation = {
                id: volSplitPerLocation.id,
                location: location,
                value: parseFloat(value),
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <>
            <Table spacing="small">
                <TableRow key={volSplitPerLocation.id}>
                    <TableCell
                        key={getUniqueKey(volSplitPerLocation.id, selectedNode, "selectedNode")}
                        width={100}
                    >
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={location} onChange={setLocation}>
                            {uniqueLocations.map((location) => (
                                <SelectOption
                                    key={getUniqueKey(volSplitPerLocation.id, location, "location")}
                                    value={location}
                                    label={location}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={90}>
                        <Input id="value" value={value} type="number" onChange={setValue} placeholder="Value"
                               error={!valueValid}
                               errorMessage={valueErrorMessage}/>
                    </TableCell>

                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={saveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                        <Row>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
}

export default EditableVolSplitPerLocationRow;
