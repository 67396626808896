import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {ILocationSizeAdmissibility} from "../../common/api/api";

function EditableLocSizeAdmissibilityRow({
                             locSizeAdmissibility,
                             selectedNode,
                             handleEditFormChange,
                             handleCancelClick,
                             uniqueLocations,
                             uniqueSizes,
                         }) {
    const [location, setLocation] = useState(locSizeAdmissibility.location);
    const [size, setSize] = useState(locSizeAdmissibility.size);
    const [is_admissible, setIs_admissible] = useState(locSizeAdmissibility.is_admissible);

    /**
     * To generate unique id for the list
     * @param id
     * @param text1
     * @param text2
     */
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    //Error checking
    const [isAdmissibleValid, setIsAdmissibleValid] = useState(true);
    const [isAdmissibleErrorMessage, setIsAdmissibleErrorMessage] = useState("");
    //Function to handle submit
    const saveHandler = () => {
        if (is_admissible=="") {
            setIsAdmissibleValid(false);
            setIsAdmissibleErrorMessage("Empty!");
        }
        else if (parseInt(is_admissible) < 0 || parseInt(is_admissible) > 1) {
            setIsAdmissibleValid(false);
            setIsAdmissibleErrorMessage("Is admissible value can only be 0 or 1!");
        } else {
            const editData: ILocationSizeAdmissibility = {
                id: locSizeAdmissibility.id,
                location: location,
                size: size,
                is_admissible: parseInt(is_admissible),
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <>
            <Table spacing="small">
                <TableRow key={locSizeAdmissibility.id}>
                    <TableCell
                        key={getUniqueKey(locSizeAdmissibility.id, selectedNode, "selectedNode")}
                        width={100}
                    >
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={location} onChange={setLocation}>
                            {uniqueLocations.map((location) => (
                                <SelectOption
                                    key={getUniqueKey(locSizeAdmissibility.id, location, "location")}
                                    value={location}
                                    label={location}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={150}>
                        <Select value={size} onChange={setSize}>
                            {uniqueSizes.map((size) => (
                                <SelectOption
                                    key={getUniqueKey(locSizeAdmissibility.id, size, "size")}
                                    value={size}
                                    label={size}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={90}>
                        <Input id="is_admissible" value={is_admissible} type="number" onChange={setIs_admissible} placeholder="is_admissible"
                               error={!isAdmissibleValid}
                               errorMessage={isAdmissibleErrorMessage}/>
                    </TableCell>

                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={saveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                        <Row>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
}

export default EditableLocSizeAdmissibilityRow;
