import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Row from "@amzn/meridian/row";

function ReadOnlyPPHeadcountRow({
  pphc,
  selectedNode,
  handleEditClick,
  handleDeleteClick,
}) {
  return (
    <Table spacing="small">
      <TableRow>
        <TableCell width={100}>
          <Input value={selectedNode} />
        </TableCell>
        <TableCell width={200}>
          <Input value={pphc.process_path} />
        </TableCell>
        <TableCell width={120}>
          <Input value={pphc.role} />
        </TableCell>
        <TableCell width={120}>
          <Input value={pphc.role_type} />
        </TableCell>
        <TableCell width={100}>
          <Input value={pphc.shift} />
        </TableCell>
        <TableCell width={70}>
          <Input value={pphc.min_hc} />
        </TableCell>
        <TableCell width={70}>
          <Input value={pphc.max_hc} />
        </TableCell>
        <TableCell>
          <Row>
            <Button minWidth={70} onClick={(event) => handleDeleteClick(pphc)}>
              Delete
            </Button>
            <Button minWidth={70} onClick={(event) => handleEditClick(pphc)}>
              Edit
            </Button>
          </Row>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default ReadOnlyPPHeadcountRow;
