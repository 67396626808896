import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import React, {useState} from "react";
import {ITEMS_PER_PAGE} from "../../constants";
import Pagination from "@amzn/meridian/pagination";

export default function VolumeMix({volumeCsvData}) {
    //For pagination
    const itemsPerPage = ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(volumeCsvData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    //Pagination
    return (<>
        <Table
            showStripes={true}
            headerRows={1}
            showDividers={true}
            spacing={"small"}
        >
            <TableRow>
                <TableCell width={70}>Sort Center</TableCell>
                <TableCell width={70}>Size</TableCell>
                <TableCell width={70}>Total Volume</TableCell>
                <TableCell width={70}>Proportion</TableCell>
                <TableCell width={70}>Avg Cube</TableCell>
            </TableRow>
            {volumeCsvData &&
                volumeCsvData.slice(firstVisibleIndex, lastVisibleIndex).map((parsedData, index) => {
                    return <TableRow key={index}>
                        <TableCell>{parsedData['Sort_center']}</TableCell>
                        <TableCell>{parsedData['Size']}</TableCell>
                        <TableCell>{parsedData['Total_vol']}</TableCell>
                        <TableCell>{parseFloat(parsedData['Proportion']).toFixed(2)}</TableCell>
                        <TableCell>{parseFloat(parsedData['Avg_cube']).toFixed(2)}</TableCell>
                    </TableRow>
                })}
        </Table>
        <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
        />
    </>);
};
