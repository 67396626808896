import React, { useState } from "react";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import AmpApi from "../../common/api/AmpApi";
import Loader from "@amzn/meridian/loader";
import Input from "@amzn/meridian/input";
import Card from "@amzn/meridian/card";
import Link from "@amzn/meridian/link";
import {IId} from "../../common/api/api";
import useNodeList from "../../components/hooks/NodeListContext";

export default function Upload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [nodeId, setNodeId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState(false);
  const {nodeList,setNodeList} = useNodeList();
  function submitHandler(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = AmpApi.configNewSite(nodeId, selectedFile);

      if (response) {
        console.log("Response", response);
        let newId = JSON.parse(JSON.stringify({
          id: nodeId
        }));
        let finalList:IId[] = [newId,...nodeList];
        setNodeList(finalList);
        setIsLoading(false);
        setResult(true);
      }
    } catch (exception: any) {
      console.error(`Error uploading file`, exception);
    }
  }
  const sampleFileDownloadHandler = () => {
    (async () => {
      try {
        let folder = "sample_input";
        let fileName = "Sample_input.xlsx";
        const downloadSampleFileResponse =
            await AmpApi.downloadAmpModelOutput(folder, fileName);
        console.log(downloadSampleFileResponse);
        if (downloadSampleFileResponse) {
          window.open(downloadSampleFileResponse.data.downloadUrl, "_self")
        }
      } catch (ex: any) {
        console.error(`Error Downloading Sample File`, ex);
      }
    })();
  };
  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <Card>
          <form onSubmit={submitHandler}>
            <Column>
              <label className="nodeIdColor">Only North American Sort Centers are allowed to be entered by design. This tool is not approved to support other regions such as EU due to data privacy.</label>
              <Row></Row>
              <Link onClick={sampleFileDownloadHandler}>Click here to download sample excel sheet</Link>
              <Row></Row>
              <Input
                name="nodeId"
                value={nodeId}
                label="Enter Site Id"
                type="text"
                onChange={setNodeId}
                width={150}
                size="small"
              />
              <Row></Row>
              <FileInput
                type={"single"}
                onFileAttached={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                    console.log("File", file);
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
                    fileReader.onload = function () {
                      const arrayBuffer: any = fileReader.result;
                      setSelectedFile(arrayBuffer);
                      setReady(true);
                    };
                    file.percentage = 100;
                  });
                  setFiles([...files, ...acceptedFiles]);
                }}
                uploadButtonSize="small"
                uploadButtonLabel={"Browse"}
                showDropZone={true}
                dropZoneLabel="Drag and drop, or click to browse"
                dropZoneDirection="column"
                uploadButtonType={"link"}
              >
                {files.map((file) => {
                  return (
                    <FileDetails
                      error={file.error}
                      errorMessage={file.errorMessage}
                      file={file}
                      connectionSpeed="5MB/s"
                      uploadPercentage={file.percentage}
                      key={file.name}
                      cancelUploadButtonLabel={`Cancel, ${file.name}`}
                      onClickCancel={() => {
                        file.cancel();
                        const updatedFileArr = files.filter(
                          (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                      }}
                      removeFileButtonLabel={`Delete, ${file.name}`}
                      onClickRemoveFile={() => {
                        const updatedFileArr = files.filter(
                          (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                      }}
                      uploadComplete={file.percentage === 100}
                    />
                  );
                })}
              </FileInput>

              <Column>
                <Row></Row>
                <Row width={70}>
                  <Button disabled={!files[0]} submit={ready}>
                    Upload File
                  </Button>
                </Row>
                <Row></Row>
                <Row>
                  {result && (
                    <div className="nodeIdColor">
                      Document Uploaded. You may close the window.
                    </div>
                  )}
                </Row>
              </Column>
            </Column>
          </form>
        </Card>
      )}
    </React.Fragment>
  );
}
