import React, {useCallback, useEffect, useState} from "react";
import Card from "@amzn/meridian/card";
import useSelectedNode from "../../components/hooks/nodeContext";
import "./runAMP.scss";
import Submitmodal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import {useParams} from "react-router-dom";
import AmpApi from "../../common/api/AmpApi";
import {RUN_AMP_MODEL_SUCCESS_STATUS, RUN_AMP_MODEL_FAIL_STATUS} from "../../constants";
import RunAMPForm from "./RunAMPForm";
import Heading from "@amzn/meridian/heading";
import Column from "@amzn/meridian/column";
import { Redirect } from "react-router";

function RunAMP() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    //For the modal to display message
    const [openModal, setOpenModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState<any>();
    const onConfirmClose = useCallback(() => setOpenModal(false), []);
    //For the loading sign for api calls
    const [isLoading, setIsLoading] = useState(false);
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
        changeSelectedNode(nodeKey);
    } else {
        nodeKey = {selectedNode};
    }
    //useEffect to update the nodeId
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
    }, []);

    function addFormHandler(argumentsMap) {
        setIsLoading(true);
        //Making api call for add
        (async () => {
            try {
                const addMheConfigResponse = await AmpApi.runAmpModel(
                    selectedNode,
                    argumentsMap
                );
                console.log(addMheConfigResponse);
                setIsLoading(false);
                setOpenModal(true);
                setResponseMessage(RUN_AMP_MODEL_SUCCESS_STATUS);
            } catch (ex: any) {
                console.error(`Error updating ${selectedNode}`, ex);
                setIsLoading(false);
                setResponseMessage(RUN_AMP_MODEL_FAIL_STATUS);
            }
        })();
        //End of the api call for add
    }

    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <>
                <Submitmodal
                    open={openModal}
                    responseMessage={responseMessage}
                    onClose={onConfirmClose}
                />
                {isLoading ? (
                    <Loader/>
                ) : (
                    <Card>
                        <Column>
                                <RunAMPForm
                            addFormHandler={addFormHandler}
                        />
                        </Column>
                    </Card>
                )}
            </>
        )
    );
}

export default RunAMP;
