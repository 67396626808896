import React, {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IProcessPathCapacity} from "../../common/api/api";

function EditablePPCapacityRow({
                                   ppc,
                                   selectedNode,
                                   handleEditFormChange,
                                   handleCancelClick,
                                   uniqueSizes,
                                   uniqueProcessPaths,
                               }) {
    const [process_path, setProcess_path] = useState(ppc.process_path);
    const [size, setSize] = useState(ppc.size);
    const [capacity, setCapacity] = useState(ppc.capacity);

    /**
     * To generate a unique id for the list
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}-${text}`;
    };
//For input validation
    const [capacityValid, setCapacityValid] = useState(true);
    const [capacityErrorMessage, setCapacityErrorMessage] = useState("");

    /**
     * Function to handle submit button
     */
    const saveHandler = () => {
        if (parseInt(capacity) < 0) {
            setCapacityValid(false);
            setCapacityErrorMessage("Empty!");
        } else {
            const editData: IProcessPathCapacity = {
                id: ppc.id,
                process_path: process_path,
                size: size,
                capacity: parseInt(capacity),
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <Table spacing="small">
            <TableRow key={ppc.id}>
                <TableCell
                    key={getUniqueKey(ppc.id, selectedNode)}
                    width={100}
                >
                    <Input id="selectedNode" value={selectedNode}/>
                </TableCell>
                <TableCell width={200}>
                    <Select value={process_path} onChange={setProcess_path}>
                        {uniqueProcessPaths.map((process_path) => (
                            <SelectOption
                                key={getUniqueKey(ppc.id, process_path)}
                                value={process_path}
                                label={process_path}
                            />
                        ))}
                    </Select>
                </TableCell>
                <TableCell width={150}>
                    <Select value={size} onChange={setSize}>
                        {uniqueSizes.map((size) => (
                            <SelectOption
                                key={getUniqueKey(ppc.id, size)}
                                value={size}
                                label={size}
                            />
                        ))}
                    </Select>
                </TableCell>
                <TableCell key={getUniqueKey(ppc.id, "capacity")} width={120}>
                    <Input
                        id="capacity"
                        value={capacity}
                        type="number"
                        onChange={setCapacity}
                        error={!capacityValid}
                        errorMessage={capacityErrorMessage}
                    ></Input>
                </TableCell>
                <TableCell>
                    <Row>
                        <Button minWidth={70} onClick={saveHandler}>
                            Save
                        </Button>
                        <Button minWidth={70} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Row>
                </TableCell>
            </TableRow>
        </Table>
    );
}

export default EditablePPCapacityRow;
