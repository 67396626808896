import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import React, {useState} from "react";
import {ITEMS_PER_PAGE} from "../../constants";
import Pagination from "@amzn/meridian/pagination";

export default function VolumeBetweenProcessPaths({volumeProcessPathsCsvData}) {
    //For pagination
    const itemsPerPage = ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(volumeProcessPathsCsvData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    //Pagination
    return (<>
        <Table
            showStripes={true}
            headerRows={1}
            showDividers={true}
            spacing={"small"}
        >
            <TableRow>
                <TableCell width={100}>Sort Center</TableCell>
                <TableCell width={200}>Process Path</TableCell>
                <TableCell width={70}>ExtraSmall</TableCell>
                <TableCell width={70}>Small</TableCell>
                <TableCell width={70}>Medium</TableCell>
                <TableCell width={70}>Large</TableCell>
                <TableCell width={70}>ExtraLarge</TableCell>
                <TableCell width={70}>NC/NC+</TableCell>
                <TableCell width={70}>HB/HB+</TableCell>
                <TableCell width={70}>Total</TableCell>
            </TableRow>
            {volumeProcessPathsCsvData &&
                volumeProcessPathsCsvData.slice(firstVisibleIndex, lastVisibleIndex).map((parsedData, index) => {
                    return <TableRow key={index}>
                        <TableCell>{parsedData['Sort_center']}</TableCell>
                        <TableCell>{parsedData['Process_path']}</TableCell>
                        <TableCell>{parsedData['ExtraSmall']}</TableCell>
                        <TableCell>{parsedData['Small']}</TableCell>
                        <TableCell>{parsedData['Medium']}</TableCell>
                        <TableCell>{parsedData['Large']}</TableCell>
                        <TableCell>{parsedData['ExtraLarge']}</TableCell>
                        <TableCell>{parsedData['NC/NC+']}</TableCell>
                        <TableCell>{parsedData['HB/HB+']}</TableCell>
                        <TableCell>{parsedData['total']}</TableCell>
                    </TableRow>
                })}
        </Table>
        <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
        />
    </>);
};
