import React, { useState } from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Input from "@amzn/meridian/input";
import Card from "@amzn/meridian/card";
import Select, { SelectOption } from "@amzn/meridian/select";
import { IAmpModelArgumentsMap } from "../../common/api/api";
import Heading from "@amzn/meridian/heading";

function RunAmpForm({ addFormHandler }) {
  // Setting default Values
  const [runType, setRunType] = useState("Pre_Shift_Planning");
  const [sortType, setSortType] = useState("1-SUN");
  const [totalVolume, setTotalVolume] = useState("10000");
  const [XS, setXS] = useState("1.00");
  const [S, setS] = useState("0.00");
  const [M, setM] = useState("0.00");
  const [L, setL] = useState("0.00");
  const [XL, setXL] = useState("0.00");
  const [NC, setNC] = useState("0.00");
  const [HB, setHB] = useState("0.00");
  const [shiftLength, setShiftLength] = useState("4");
  const [min_HC, setMin_HC] = useState("0");
  const [max_HC, setMax_HC] = useState("1000");
  const [breakMinutes, setBreakMinutes] = useState("20");
  const [currentWip, setCurrentWip] = useState("0");
  const [targetWip, setTargetWip] = useState("0");
  const [errorMessage, setErrorMessage] = useState("");
  const [totalSizeErrorMessage, setTotalSizeErrorMessage] = useState("");

  function submitHandler(event) {
    event.preventDefault();
    const totalSize = (
      parseFloat(XS) +
      parseFloat(S) +
      parseFloat(M) +
      parseFloat(L) +
      parseFloat(XL) +
      parseFloat(NC) +
      parseFloat(HB)).toFixed(2);
    if (
      !runType ||
      !sortType ||
      !XS ||
      !S ||
      !M ||
      !L ||
      !XL ||
      !NC ||
      !HB ||
      !shiftLength ||
      !min_HC ||
      !max_HC ||
      !breakMinutes ||
      !currentWip ||
      !targetWip
    ) {
        setErrorMessage("Fill all the fields before submitting!");
    } else if (parseFloat(totalSize) !== 1.00) {
        setTotalSizeErrorMessage("Total of all sizes(XS, S, L, M, XL, NC, HB) must be Equal to One .");
    } else if(parseInt(breakMinutes) < 0 ) {
      setErrorMessage("Break minutes must be Greater than or Equal to Zero!");
    } else {
      let argumentsMap: IAmpModelArgumentsMap = {};
      argumentsMap["run_type"] = runType;
      argumentsMap["shift_to_run"] = sortType;
      argumentsMap["total_volume"] = totalVolume;
      argumentsMap["extra_small"] = XS;
      argumentsMap["small"] = S;
      argumentsMap["medium"] = M;
      argumentsMap["large"] = L;
      argumentsMap["extra_large"] = XL;
      argumentsMap["non_con"] = NC;
      argumentsMap["heavy_bulky"] = HB;
      argumentsMap["shift_length"] = shiftLength;
      // Setting this to default value as it will not change.
      argumentsMap["break_minutes"] = breakMinutes;
      argumentsMap["min_hc"] = min_HC;
      argumentsMap["max_hc"] = max_HC;
      argumentsMap["current_wip"] = currentWip;
      argumentsMap["target_wip"] = targetWip;

      addFormHandler(argumentsMap);
    }
  }

  function resetHandler() {
    setErrorMessage("");
    setTotalSizeErrorMessage("");
    setRunType("Pre_Shift_Planning");
    setSortType("1-SUN");
    setTotalVolume("10000");
    setXS("1.00");
    setS("0.00");
    setM("0.00");
    setL("0.00");
    setXL("0.00");
    setNC("0.00");
    setHB("0.00");
    setShiftLength("4");
    setMin_HC("0");
    setMax_HC("1000");
    setBreakMinutes("20");
    setCurrentWip("0");
    setTargetWip("0");
  }

  return (
    <div>
        <Card>
          <Column alignmentHorizontal={"center"}>
            <Column></Column>
            <Column alignmentHorizontal={"center"}>
              <Heading  level={5} type="h300">Please enter data below to run the AMP Model</Heading>
              <label id="disclaimerRunAMP" className="nodeIdColor">* The total sum of all sizes ( XS + S + L + M + XL + NC + HB ) must be equal to 1</label>
            </Column>
            <Row></Row>
            <Row></Row>
          <form onSubmit={submitHandler}>
              <Box type="outline" spacingInset="500">
                <Column>
                <Row>
                  <label className="label-width">Select Run Type</label>
                  <Select
                    value={runType}
                    onChange={setRunType}
                    placeholder="Run Type"
                    width={300}
                    size="large"
                  >
                    <SelectOption
                      value="Pre_Shift_Planning"
                      label="Pre Shift Planning"
                    />
                  </Select>
                </Row>
                <Row></Row>
                <Row>
                  <label className="label-width">Select Sort</label>
                  <Select
                    value={sortType}
                    onChange={setSortType}
                    placeholder="Sort Type"
                    width={300}
                    size="large"
                  >
                    <SelectOption value="1-SUN" label="1-SUN" />
                    <SelectOption value="2-MOR" label="2-MOR" />
                    <SelectOption value="3-DAY" label="3-DAY" />
                    <SelectOption value="4-TWI" label="4-TWI" />
                    <SelectOption value="5-NIT" label="5-NIT" />
                  </Select>
                </Row>
                <Row></Row>
                <Row>
                  <Input
                    name="total_volume"
                    value={totalVolume}
                    label="Total Volume"
                    type="number"
                    onChange={setTotalVolume}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="XS"
                    value={XS}
                    label="XS %"
                    type="number"
                    onChange={setXS}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="S"
                    value={S}
                    label="S %"
                    type="number"
                    onChange={setS}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="M"
                    value={M}
                    label="M %"
                    type="number"
                    onChange={setM}
                    width={130}
                    size="small"
                  />
                </Row>
                <Row></Row>
                <Row>
                  <Input
                    name="L"
                    value={L}
                    label="L %"
                    type="number"
                    onChange={setL}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="XL"
                    value={XL}
                    label="XL %"
                    type="number"
                    onChange={setXL}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="NC"
                    value={NC}
                    label="NC %"
                    type="number"
                    onChange={setNC}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="HB"
                    value={HB}
                    label="HB %"
                    type="number"
                    onChange={setHB}
                    width={130}
                    size="small"
                  />
                </Row>
                <Row></Row>
                <Row>
                  <Input
                    name="shift_length"
                    value={shiftLength}
                    label="Shift Length"
                    type="number"
                    onChange={setShiftLength}
                    width={130}
                    size="small"
                  />

                  <Input
                      name="break_minutes"
                      value={breakMinutes}
                      label="Break Minutes"
                      type="number"
                      onChange={setBreakMinutes}
                      width={130}
                      size="small"
                  />

                  <Input
                    name="min_HC"
                    value={min_HC}
                    label="min HC"
                    type="number"
                    onChange={setMin_HC}
                    width={130}
                    size="small"
                  />

                  <Input
                    name="max_HC"
                    value={max_HC}
                    label="max HC"
                    type="number"
                    onChange={setMax_HC}
                    width={130}
                    size="small"
                  />
                </Row>
                <Row></Row>
                <Row>
                  <Input
                      name="current_wip"
                      value={currentWip}
                      label="Current WIP"
                      type="number"
                      onChange={setCurrentWip}
                      width={130}
                      size="small"
                  />
                  <Input
                      name="target_wip"
                      value={targetWip}
                      label="Target WIP"
                      type="number"
                      onChange={setTargetWip}
                      width={130}
                      size="small"
                  />
                </Row>
                </Column>
              </Box>
              {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
              {totalSizeErrorMessage && (
                <p className="errorMsg"> {totalSizeErrorMessage} </p>
              )}
              <Box spacingInset="500">
                <Row alignmentHorizontal={"center"}>
                  <Button submit={true}>Run AMP Model</Button>
                  <Button onClick={resetHandler}>Reset Form</Button>
                </Row>
              </Box>
          </form>
          </Column>
        </Card>
    </div>
  );
}

export default RunAmpForm;
