import {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IMheConfig} from "../../common/api/api";

function EditableRow({
                         mhe,
                         selectedNode,
                         handleEditFormChange,
                         handleCancelClick,
                         uniqueProcessPaths,
                     }) {
    const [quantity, setQuantity] = useState(mhe.quantity);
    const [isInbound, setIsInbound] = useState(mhe.isInbound);
    const [processPath, setProcessPath] = useState(mhe.processPath);

    /**
     * To generate a unique id for the list
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}-${text}`;
    };
//For input validation
    const [quantityValid, setQuantityValid] = useState(true);
    const [quantityErrorMessage, setQuantityErrorMessage] = useState("");
    
    /**
     * Function to handle submit button
     */
    const saveHandler = () => {
        if (!quantity) {
            setQuantityValid(false);
            setQuantityErrorMessage("Empty!");
        } else {
            const editData: IMheConfig = {
                id: mhe.id,
                isInbound: parseInt(isInbound),
                quantity: parseInt(quantity),
                processPath: processPath,
            };
            handleEditFormChange(editData);
        }
    };

    return (
        <>
            <Table>
                <TableRow key={mhe.id}>
                    <TableCell key={getUniqueKey(mhe.id, selectedNode)} width={100}>
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>
                    <TableCell width={300}>
                        <Select value={processPath} onChange={setProcessPath}>
                            {uniqueProcessPaths.map((processPath) => (
                                <SelectOption
                                    key={getUniqueKey(mhe.id, processPath)}
                                    value={processPath}
                                    label={processPath}
                                />
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell key={getUniqueKey(mhe.id, "quantity")} width={100}>
                        <Input
                            id="quantity"
                            value={quantity}
                            type="number"
                            onChange={setQuantity}
                            error={!quantityValid}
                            errorMessage={quantityErrorMessage}
                        />
                    </TableCell>
                    <TableCell key={getUniqueKey(mhe.id, "isInbound")} width={100}>
                        <Select value={isInbound} onChange={setIsInbound}>
                            <SelectOption
                                key={getUniqueKey(mhe.id, "0")}
                                value={0}
                                label="0"
                            />
                            <SelectOption
                                key={getUniqueKey(mhe.id, "1")}
                                value={1}
                                label="1"
                            />
                        </Select>
                    </TableCell>
                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={saveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </>
    );
}

export default EditableRow;
