/**
 * Named Error to indicate when the client type requested is not recognized during the 'PulseLink.createClient' process.
 * @extends Error
 */
class InvalidClientTypeException extends Error {
  constructor(message: string) {
    super(message);

    Object.setPrototypeOf(this, InvalidClientTypeException.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, InvalidClientTypeException);
    }

    this.name = "InvalidClientTypeException";
  }
}

export default InvalidClientTypeException;
