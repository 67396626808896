export interface AmpExceptionStruct {
  httpStatusCode: number;
  data: string;
  date: Date;
  fault?: string;
  hasError: boolean;
  message: string;
  metadata: object;
  stack: string;
  statusInfo: string;
  sourceErrorName: string;
  name: string;
}

/**
 * Base Amp Exception that takes a formatted exception response and parses it to be used in surfacing
 * distinct Client and Server exceptions on the frontend with as much information as possible.
 * @extends Error
 */
class AmpException extends Error {
  httpStatusCode: AmpExceptionStruct["httpStatusCode"];

  data: AmpExceptionStruct["data"];

  date: AmpExceptionStruct["date"];

  fault?: AmpExceptionStruct["fault"];

  hasError: AmpExceptionStruct["hasError"];

  message: AmpExceptionStruct["message"];

  metadata: AmpExceptionStruct["metadata"];

  stack: AmpExceptionStruct["stack"];

  statusInfo: AmpExceptionStruct["statusInfo"];

  sourceErrorName: AmpExceptionStruct["sourceErrorName"];

  name: AmpExceptionStruct["name"];

  constructor(formattedError: AmpExceptionStruct) {
    super(formattedError.message);

    Object.setPrototypeOf(this, AmpException.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AmpException);
    }

    this.httpStatusCode = formattedError.httpStatusCode;
    this.data = formattedError.data;
    this.date = formattedError.date;
    this.fault = formattedError.fault;
    this.hasError = formattedError.hasError;
    this.message = formattedError.message;
    this.metadata = formattedError.metadata;
    this.stack = formattedError.data;
    this.statusInfo = formattedError.statusInfo;
    this.sourceErrorName = formattedError.sourceErrorName;
    this.name = "AmpException";
  }
}

export default AmpException;
