import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Card from "@amzn/meridian/card";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IProcessPathHeadcount} from "../../common/api/api";
import {nanoid} from "nanoid";
import {BLANK_FIELDS_ERROR} from "../../constants";
import CreatableSelect from "react-select/creatable";

function PPHeadCountAddForm({
                                addFormHandler,
                                uniqueRoles,
                                cancelHandler,
                                uniqueRoleTypes,
                                uniqueProcessPaths
                            }) {
    const [process_path, setProcess_path] = useState("");
    const [role, setRole] = useState("");
    const [role_type, setRole_type] = useState("");
    const [shift, setShift] = useState("");
    const [min_hc, setMin_hc] = useState("");
    const [max_hc, setMax_hc] = useState("");
    //Error checking
    const [errorMessage, setErrorMessage] = useState("");

    //Convert processPath to hold value and label
    uniqueProcessPaths = uniqueProcessPaths.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert role to hold value and label
    uniqueRoles = uniqueRoles.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert role_type to hold value and label
    uniqueRoleTypes = uniqueRoleTypes.map((str) => ({
        value: str,
        label: str,
    }));

    //Submit handler
    function submitHandler(event) {
        event.preventDefault();
        if (!process_path || !role || !role_type || !shift || !min_hc || !max_hc) {
            setErrorMessage(BLANK_FIELDS_ERROR);
        } else {
            const addData: IProcessPathHeadcount = {
                id: nanoid(),
                process_path: process_path['label'],
                role: role['label'],
                role_type: role_type['label'],
                shift: shift,
                min_hc: parseInt(min_hc),
                max_hc: parseInt(max_hc),
            };
            addFormHandler(addData);
        }
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Forest" : "Forest",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };
    return (
        <div>
            <Column>
                <Card>
                    <form onSubmit={submitHandler}>
                        <Column>
                            <Heading level={5}>Add New Process Path HeadCount</Heading>
                            <Column>
                                <Row>
                                    <div style={{width: "300px", height: "40px"}}>
                                        <CreatableSelect
                                            isClearable
                                            onChange={setProcess_path}
                                            options={uniqueProcessPaths}
                                            value={process_path}
                                            styles={customStyles}
                                            placeholder="Process Path"
                                        />
                                    </div>

                                    <div style={{width: "300px", height: "40px"}}>
                                        <CreatableSelect
                                            isClearable
                                            onChange={setRole}
                                            options={uniqueRoles}
                                            value={role}
                                            styles={customStyles}
                                            placeholder="Role"
                                        />
                                    </div>
                                </Row>

                                <Row>
                                    <div style={{width: "300px", height: "40px"}}>
                                        <CreatableSelect
                                            isClearable
                                            onChange={setRole_type}
                                            options={uniqueRoleTypes}
                                            value={role_type}
                                            styles={customStyles}
                                            placeholder="Role Type"
                                        />
                                    </div>

                                    <Select value={shift} onChange={setShift} width={200} placeholder="Shift">
                                        <SelectOption value="1-SUN" label="1-SUN"/>
                                        <SelectOption value="2-MOR" label="2-MOR"/>
                                        <SelectOption value="3-DAY" label="3-DAY"/>
                                        <SelectOption value="4-TWI" label="4-TWI"/>
                                        <SelectOption value="5-NIT" label="5-NIT"/>
                                    </Select>
                                </Row>

                                <Row>
                                    <Column>
                                        <Input
                                            name="min_hc"
                                            value={min_hc}
                                            label="min_hc"
                                            type="number"
                                            onChange={setMin_hc}
                                            width={200}
                                            size="small"
                                        />
                                        <Input
                                            name="max_hc"
                                            value={max_hc}
                                            label="max_hc"
                                            type="number"
                                            onChange={setMax_hc}
                                            width={200}
                                            size="small"
                                        />
                                    </Column>
                                </Row>
                            </Column>
                            <Box>
                                <Row>
                                    <Button submit={true}>Add</Button>
                                    <Button onClick={cancelHandler}>Cancel</Button>
                                    {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                                </Row>
                            </Box>
                        </Column>
                    </form>
                </Card>
            </Column>
        </div>
    );
}

export default PPHeadCountAddForm;
