import React, {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IProcessPathHeadcount} from "../../common/api/api";
import Row from "@amzn/meridian/row";

function EditablePPHeadcountRow({
                                    pphc,
                                    selectedNode,
                                    handleEditFormChange,
                                    handleCancelClick,
                                    uniqueRoles,
                                    uniqueRoleTypes,
                                    uniqueProcessPaths
                                }) {
    const [process_path, setProcess_path] = useState(pphc.process_path);
    const [role, setRole] = useState(pphc.role);
    const [role_type, setRole_type] = useState(pphc.role_type);
    const [shift, setShift] = useState(pphc.shift);
    const [min_hc, setMin_hc] = useState(pphc.min_hc);
    const [max_hc, setMax_hc] = useState(pphc.max_hc);

    //To generate id
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    //For input validation
    const [minHcValid, setMinHcValid] = useState(true);
    const [maxHcValid, setMaxHcValid] = useState(true);
    const [minHcErrorMessage, setMinHcErrorMessage] = useState("");
    const [maxHcErrorMessage, setMaxHcErrorMessage] = useState("");

    /**
     * Submit Handler
     */
    const handleSaveHandler = () => {
        if(min_hc==""){
            setMinHcValid(false);
            setMinHcErrorMessage("Empty!");
        }
        if(max_hc==""){
            setMaxHcValid(false);
            setMaxHcErrorMessage("Empty!");
        }
        if (parseInt(min_hc)<0) {
            setMinHcValid(false);
            setMinHcErrorMessage("Invalid!");
        }
        if (parseInt(max_hc)<0) {
            setMaxHcValid(false);
            setMaxHcErrorMessage("Invalid!");
        } else {
            const editData: IProcessPathHeadcount = {
                id: pphc.id,
                process_path: process_path,
                role: role,
                role_type: role_type,
                shift: shift,
                min_hc: parseInt(min_hc),
                max_hc: parseInt(max_hc),
            };
            handleEditFormChange(editData);
        }
    };
    return (
        <div>
            <Table spacing="small">
                <TableRow key={pphc.id}>
                    <TableCell
                        key={getUniqueKey(pphc.id, selectedNode, "selectedNode")}
                        width={100}
                    >
                        <Input id="selectedNode" value={selectedNode}/>
                    </TableCell>

                    <TableCell width={200}>
                        <Select value={process_path} onChange={setProcess_path}>
                            {uniqueProcessPaths.map((process_path) => (
                                <SelectOption
                                    key={getUniqueKey(pphc.id, process_path, "process_path")}
                                    value={process_path}
                                    label={process_path}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={120}>
                        <Select value={role} onChange={setRole}>
                            {uniqueRoles.map((role) => (
                                <SelectOption
                                    key={getUniqueKey(pphc.id, role, "role")}
                                    value={role}
                                    label={role}
                                />
                            ))}
                        </Select>
                    </TableCell>
                    <TableCell width={120}>
                        <Select value={role_type} onChange={setRole_type}>
                            {uniqueRoleTypes.map((role_type) => (
                                <SelectOption
                                    key={getUniqueKey(pphc.id, role_type, "role_type")}
                                    value={role_type}
                                    label={role_type}
                                />
                            ))}
                        </Select>
                    </TableCell>

                    <TableCell width={100}>
                        <Select value={shift} onChange={setShift}>
                            <SelectOption value="1-SUN" label="1-SUN"/>
                            <SelectOption value="2-MOR" label="2-MOR"/>
                            <SelectOption value="3-DAY" label="3-DAY"/>
                            <SelectOption value="4-TWI" label="4-TWI"/>
                            <SelectOption value="5-NIT" label="5-NIT"/>
                        </Select>
                    </TableCell>
                    <TableCell width={70}>
                        <Input
                            id="min_hc"
                            type="number"
                            value={min_hc}
                            onChange={setMin_hc}
                            error={!minHcValid}
                            errorMessage={minHcErrorMessage}
                        />
                    </TableCell>
                    <TableCell width={70}>
                        <Input
                            id="max_hc"
                            type="number"
                            value={max_hc}
                            onChange={setMax_hc}
                            error={!maxHcValid}
                            errorMessage={maxHcErrorMessage}
                        />
                    </TableCell>

                    <TableCell>
                        <Row>
                            <Button minWidth={70} onClick={handleSaveHandler}>
                                Save
                            </Button>
                            <Button minWidth={70} onClick={handleCancelClick}>
                                Cancel
                            </Button>
                        </Row>
                    </TableCell>
                </TableRow>
            </Table>
        </div>
    );
}

export default EditablePPHeadcountRow;
