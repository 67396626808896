import {useState} from "react";
import SideMenu, {SideMenuLink} from "@amzn/meridian/side-menu";
import NodeIdSelect from "../nodeIdSelect/NodeIdSelect";
import {
    CONFIG_LINK_LABEL,
    DOWNLOAD_LINK_LABEL,
    FLOW_CHART_LABEL,
    HOME_LINK_LABEL,
    ICE_LINK_LABEL,
    MHE_CONFIG_LINK_LABEL,
    PKG_SPLITS_CONTAINER_TYPES_LINK_LABEL,
    PP_CAPACITY_LINK_LABEL,
    PP_HEADCOUNT_LINK_LABEL,
    PP_RATES_LINK_LABEL,
    PP_REL_LINK_LABEL,
    PP_REL_TABLE_LABEL,
    LOCATION_SIZE_ADMISSIBILITY_LINK_LABEL,
    VOL_SPLIT_PER_LOCATION_LINK_LABEL,
    RUN_AMP_LINK_LABEL,
    DOWNLOAD_AMP_OUTPUT_LINK_LABEL,
    SHIFTS_CONFIG_LINK_LABEL,
    SIDE_MENU_HASH_KEY,
    SIDE_MENU_OPEN_HASH_KEY, MANAGE_INPUTS_LABEL,
} from "../../constants";
import {useHistory, useLocation} from "react-router-dom";
import useSelectedNode from "./../hooks/nodeContext";
import useSidebar from "../hooks/SidebarContext";

//To get the hash for routing with the HashRouter
const NAVLINK_HASH = {
    home: "#home",
    mheConfig: "#mheConfig",
    processPathRelationship: "#processPathRelationship",
    processPathRelationshipFlow: "#processPathRelationshipFlow",
    processPathRelationshipTable: "#processPathRelationshipTable",
    processPathRates: "#processPathRates",
    processPathCapacity: "#processPathCapacity",
    shiftsConfig: "#shiftsConfig",
    processPathHeadcount: "#processPathHeadcount",
    inboundContainerEligibility: "#inboundContainerEligibility",
    pkgSplitsContainerTypes: "#pkgSplitsContainerTypes",
    locationSizeAdmissibility: "#locationSizeAdmissibility",
    volSplitPerLocation: "#volSplitPerLocation",
    download: "#download",
    runAMP: "#runAMP",
    downloadAMPOutput: "#downloadAMPOutput",
    configNewSite: "#configNewSite",
    manageInputs: "#manageInputs",
};

const NAVLINK_HASH_OBJECTS = [
    {
        hashName: NAVLINK_HASH.home,
        label: HOME_LINK_LABEL,
        hrefLink: "/",
        isNested: false,
    },
    {
        hashName: NAVLINK_HASH.configNewSite,
        label: CONFIG_LINK_LABEL,
        hrefLink: "/configNewSite",
        isNested: false,
    },
    {
        hashName: NAVLINK_HASH.manageInputs,
        label: MANAGE_INPUTS_LABEL,
        hrefLink: "/manageInputs",
        isNested: true,
        nestedLinks: [
            {
                hashName: NAVLINK_HASH.mheConfig,
                label: MHE_CONFIG_LINK_LABEL,
                hrefLink: "/mheConfig",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.processPathRelationship,
                label: PP_REL_LINK_LABEL,
                hrefLink: "/processPathRelationship",
                isNested: true,
                nestedLinks: [
                    {
                        hashName: NAVLINK_HASH.processPathRelationshipFlow,
                        label: FLOW_CHART_LABEL,
                        hrefLink: "/processPathRelationshipFlow",
                    },
                    {
                        hashName: NAVLINK_HASH.processPathRelationshipTable,
                        label: PP_REL_TABLE_LABEL,
                        hrefLink: "/processPathRelationshipTable",
                    },
                ],
            },
            {
                hashName: NAVLINK_HASH.processPathRates,
                label: PP_RATES_LINK_LABEL,
                hrefLink: "/processPathRates",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.processPathCapacity,
                label: PP_CAPACITY_LINK_LABEL,
                hrefLink: "/processPathCapacity",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.shiftsConfig,
                label: SHIFTS_CONFIG_LINK_LABEL,
                hrefLink: "/shiftsConfig",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.processPathHeadcount,
                label: PP_HEADCOUNT_LINK_LABEL,
                hrefLink: "/processPathHeadcount",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.inboundContainerEligibility,
                label: ICE_LINK_LABEL,
                hrefLink: "/inboundContainerEligibility",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.pkgSplitsContainerTypes,
                label: PKG_SPLITS_CONTAINER_TYPES_LINK_LABEL,
                hrefLink: "/pkgSplitsContainerTypes",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.locationSizeAdmissibility,
                label: LOCATION_SIZE_ADMISSIBILITY_LINK_LABEL,
                hrefLink: "/locationSizeAdmissibility",
                isNested: false,
            },
            {
                hashName: NAVLINK_HASH.volSplitPerLocation,
                label: VOL_SPLIT_PER_LOCATION_LINK_LABEL,
                hrefLink: "/volSplitPerLocation",
                isNested: false,
            },
        ],
    },
    {
        hashName: NAVLINK_HASH.download,
        label: DOWNLOAD_LINK_LABEL,
        hrefLink: "/download",
        isNested: false,
    },
    {
        hashName: NAVLINK_HASH.runAMP,
        label: RUN_AMP_LINK_LABEL,
        hrefLink: "/runAMP",
        isNested: false,
    },
    {
        hashName: NAVLINK_HASH.downloadAMPOutput,
        label: DOWNLOAD_AMP_OUTPUT_LINK_LABEL,
        hrefLink: "/downloadAMPOutput",
        isNested: false,
    },

];
const PATH_NAMES_HASH_ARRAY = [
    {
        hrefLink: "/home",
        hashName: NAVLINK_HASH.home,
    },
    {
        hrefLink: "/mheConfig",
        hashName: NAVLINK_HASH.mheConfig,
    },
    {
        hrefLink: "/processPathRelationship",
        hashName: NAVLINK_HASH.processPathRelationship,
    },
    {
        hrefLink: "/processPathRelationshipFlow",
        hashName: NAVLINK_HASH.processPathRelationshipFlow,
    },
    {
        hrefLink: "/processPathRelationshipTable",
        hashName: NAVLINK_HASH.processPathRelationshipTable,
    },
    {
        hrefLink: "/processPathRates",
        hashName: NAVLINK_HASH.processPathRates,
    },
    {
        hrefLink: "/processPathCapacity",
        hashName: NAVLINK_HASH.processPathCapacity,
    },
    {
        hrefLink: "/shiftsConfig",
        hashName: NAVLINK_HASH.shiftsConfig,
    },
    {
        hrefLink: "/processPathHeadcount",
        hashName: NAVLINK_HASH.processPathHeadcount,
    },
    {
        hrefLink: "/inboundContainerEligibility",
        hashName: NAVLINK_HASH.inboundContainerEligibility,
    },
    {
        hrefLink: "/locationSizeAdmissibility",
        hashName: NAVLINK_HASH.locationSizeAdmissibility,
    },
    {
        hrefLink: "/volSplitPerLocation",
        hashName: NAVLINK_HASH.volSplitPerLocation,
    },
    {
        hrefLink: "/download",
        hashName: NAVLINK_HASH.download,
    },
    {
        hrefLink: "/runAMP",
        hashName: NAVLINK_HASH.runAMP,
    },
    {
        hrefLink: "/downloadAMPOutput",
        hashName: NAVLINK_HASH.downloadAMPOutput,
    },
    {
        hrefLink: "/configNewSite",
        hashName: NAVLINK_HASH.configNewSite,
    },
];

export default function AmpSideMenu() {
    const {selectedNode} = useSelectedNode();
    const {sidebar} = useSidebar();
    const location = useLocation();
    const history = useHistory();
    const [hash, setHash] = useState(getDefaultHash(location));
    const [openLinkHash, setOpenLinkHash] = useState(
        localStorage.getItem(SIDE_MENU_OPEN_HASH_KEY) || null
    );

    function getDefaultHash(location) {
        let localStoredHash = localStorage.getItem(SIDE_MENU_HASH_KEY);
        let locationHash = getLocationHashFromPath(location.pathname);
        if (localStoredHash === locationHash) {
            return localStoredHash;
        } else {
            localStorage.setItem(SIDE_MENU_HASH_KEY, locationHash);
            return locationHash;
        }
    }

    function getLocationHashFromPath(pathname) {
        for (let linkItem of PATH_NAMES_HASH_ARRAY) {
            if (pathname.includes(linkItem.hrefLink)) {
                return linkItem.hashName;
            }
        }
        return NAVLINK_HASH.home;
    }
    function generateKey (text1, text2){
        return(`${text1}-${text2}`);
    }

    return (
        <>
            <SideMenu open={sidebar}>
                <SideMenuLink>
                    <NodeIdSelect width={200}/>
                </SideMenuLink>

                {NAVLINK_HASH_OBJECTS.map(
                    ({isNested, hashName, label, hrefLink, nestedLinks = []},index) => {
                        if (isNested) {
                            return (
                                <SideMenuLink
                                    key={generateKey(hashName, index)}
                                    href={hashName}
                                    selected={hash === hashName}
                                    open={openLinkHash === hashName}
                                    onClick={(hashValue) => {
                                        setHash(hashValue);
                                        localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                        let nextOpenLinkHash =
                                            openLinkHash === hashName ? null : hashValue;
                                        setOpenLinkHash(nextOpenLinkHash);
                                        localStorage.setItem(
                                            SIDE_MENU_OPEN_HASH_KEY,
                                            nextOpenLinkHash
                                        );
                                    }}
                                >
                                    {label}
                                    {nestedLinks.map((nestedLink) => (
                                        (!nestedLink.isNested) ?( <SideMenuLink
                                            key={nestedLink.hashName}
                                            href={nestedLink.hashName}
                                            selected={hash === nestedLink.hashName}
                                            disabled={!selectedNode}
                                            onClick={(hashValue) => {
                                                setHash(hashValue);
                                                localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                                history.push(
                                                    `/${selectedNode}${hrefLink}${nestedLink.hrefLink}`
                                                );
                                            }}
                                        >
                                            {nestedLink.label}
                                        </SideMenuLink>):(<SideMenuLink
                                            key={generateKey(nestedLink.hashName, "third")}
                                            href={nestedLink.hashName}
                                            selected={hash === nestedLink.hashName}
                                            open={openLinkHash === nestedLink.hashName}
                                            onClick={(hashValue) => {
                                                setHash(hashValue);
                                                localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                                let nextOpenLinkHash =
                                                    openLinkHash === nestedLink.hashName ? null : hashValue;
                                                setOpenLinkHash(nextOpenLinkHash);
                                                localStorage.setItem(
                                                    SIDE_MENU_OPEN_HASH_KEY,
                                                    nextOpenLinkHash
                                                );
                                            }}
                                        >
                                            {nestedLink.label}
                                            <SideMenuLink
                                                href={"#processPathRelationshipFlow"}
                                                selected={hash === "#processPathRelationshipFlow"}
                                                disabled={!selectedNode}
                                                onClick={(hashValue) => {
                                                    setHash(hashValue);
                                                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                                    history.push(
                                                        `/${selectedNode}${nestedLink.hrefLink}/processPathRelationshipFlow`
                                                    );
                                                }}
                                            >
                                                Flow Chart
                                            </SideMenuLink>
                                            <SideMenuLink
                                                href={"#processPathRelationshipTable"}
                                                selected={hash === "#processPathRelationshipTable"}
                                                disabled={!selectedNode}
                                                onClick={(hashValue) => {
                                                    setHash(hashValue);
                                                    localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                                    history.push(
                                                        `/${selectedNode}${nestedLink.hrefLink}/processPathRelationshipTable`
                                                    );
                                                }}
                                            >
                                                Table
                                            </SideMenuLink>
                                        </SideMenuLink>)
                                    ))}
                                </SideMenuLink>
                            );
                        } else {
                            if(hashName=="#configNewSite"){
                                return (
                                    <SideMenuLink
                                        key={hashName}
                                        href={hashName}
                                        selected={hash === hashName}
                                        onClick={(hashValue) => {
                                            setHash(hashValue);
                                            localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                            history.push(
                                                selectedNode ? `/${selectedNode}${hrefLink}` : "/newConfig"
                                            );
                                        }}
                                    >
                                        {label}
                                    </SideMenuLink>
                                );
                            }
                            else {
                                return (
                                    <SideMenuLink
                                        key={hashName}
                                        href={hashName}
                                        selected={hash === hashName}
                                        onClick={(hashValue) => {
                                            setHash(hashValue);
                                            localStorage.setItem(SIDE_MENU_HASH_KEY, hashValue);
                                            history.push(
                                                selectedNode ? `/${selectedNode}${hrefLink}` : "/"
                                            );
                                        }}
                                    >
                                        {label}
                                    </SideMenuLink>
                                );
                            }
                        }
                    }
                )}
            </SideMenu>
        </>
    );
}
