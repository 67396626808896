import {createContext, useContext, useState} from "react";

/**
 * useContext hook to hide/display side bar
 */
const initialState = {
    sidebar: true,
    setSidebar: () => {
    }
};

const SidebarContext = createContext(initialState);

export const SidebarProvider = ({children}) => {
    const [userSidebar, setUserSidebar] = useState(initialState.sidebar);

    function setSidebar() {
        setUserSidebar((prevIsBarOpen) => !prevIsBarOpen);
    }

    return (
        <SidebarContext.Provider value={{sidebar: userSidebar, setSidebar}}>
            {children}
        </SidebarContext.Provider>
    );
};

const useSidebar = () => {
    const context = useContext(SidebarContext);

    if (context === undefined) {
        throw new Error("useSidebar must be used within a SidebarProvider");
    }
    return context;
};

export default useSidebar;
