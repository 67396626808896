import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import React, {useState} from "react";
import {ITEMS_PER_PAGE} from "../../constants";
import Pagination from "@amzn/meridian/pagination";

export default function Wip({wipCsvData}) {
    //For pagination
    const itemsPerPage = ITEMS_PER_PAGE;
    const numberOfPages = Math.ceil(wipCsvData.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
    const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
    //Pagination
    return (<>
        <Table
            showStripes={true}
            headerRows={1}
            showDividers={true}
            spacing={"small"}
        >
            <TableRow>
                <TableCell width={70}>Sort Center</TableCell>
                <TableCell width={150}>Process Path</TableCell>
                <TableCell width={100}>Size</TableCell>
                <TableCell width={150}>Initial WIP</TableCell>
                <TableCell width={100}>final WIP</TableCell>
            </TableRow>
            {wipCsvData &&
                wipCsvData.slice(firstVisibleIndex, lastVisibleIndex).map((parsedData, index) => {
                    return <TableRow key={index} >
                        <TableCell>{parsedData['sort_center']}</TableCell>
                        <TableCell>{parsedData['process_path']}</TableCell>
                        <TableCell>{parsedData['size']}</TableCell>
                        <TableCell>{parsedData['initial_wip']}</TableCell>
                        <TableCell>{parsedData['final_wip']}</TableCell>
                    </TableRow>
                })}
        </Table>
        <Pagination
            showSkipArrows={true}
            numberOfPages={numberOfPages}
            onChange={setCurrentPage}
            currentPage={currentPage}
        />
    </>);
};
