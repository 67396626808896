import AmpException, { AmpExceptionStruct } from "./AmpException";

/**
 * Named AmpException type Error to indicate a $FAULT of 'server' in a smithy Error response
 * from an Api Gateway request.
 * See: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/
 *      client-api-gateway/classes/apigatewayserviceexception.html
 * @extends AmpException
 */
class AmpServerException extends AmpException {
  constructor(formattedError: AmpExceptionStruct) {
    super(formattedError);

    Object.setPrototypeOf(this, AmpServerException.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AmpServerException);
    }

    this.fault = formattedError.fault || "server";
    this.name = "AmpServerException";
  }
}

export default AmpServerException;
