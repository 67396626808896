import {AssetMaximizationPlannerClient} from "@amzn/asset-maximization-planner-service-client";
import {get, includes, toLower, trim} from "lodash";
import InvalidClientTypeException from "./errors/InvalidClientTypeException";
import AmpException from "./errors/AmpException";
import AmpClientException from "./errors/AmpClientException";
import AmpServerException from "./errors/AmpServerException";
import ApiConfigurator from "../config/apiClientConfigurator";
import {USERNAME_KEY} from "../constants";


/**
 * Max attempts for both creation of client, and for retries on retrying failed SEND commands
 */
const MAX_ATTEMPTS = 3;
let userAlias="";
/**
 * Base client management class for components. Handles creation, token expiry, and the execution of 'send' commands.
 */
class AmpLink {
    /**
     * Class constructor for an instance of AmpLink
     * @param {string} clientType - 'amp'.
     */
    constructor(clientType) {
        this.auth = ApiConfigurator.getAuth();
        this.clientType = trim(toLower(clientType));
        this.clientApiConfig = ApiConfigurator.getApiClientConfig(this.clientType);
        this.client = this.createClient();
    }

    /**
     * Checks for an expired STS token message when an error is received from a SEND client command request.
     * @param {Error} error - The error object received during a client SEND request failure.
     * @returns {boolean} - Indicator of whether the token is expired (true) or not (false).
     */
    static checkTokenExpired = (error) => {
        // The error message received on token expiration.
        if (
            error.message === "The security token included in the request is expired"
        ) {
            console.error(
                "403 token expired, creating new client and generating new token"
            );
            return true;
        }
        console.error(error);
        return false;
    };

    /**
     * Utility function that parses the '$metadata' response object for its http status code.
     * @param {object} apiResponse - The response received from our Api send() command.
     * @returns {number} httpStatusCode - The numerical status code returned as a part of the '$metadata' sub-object, or
     *  at the top level 'httpStatusCode' property.
     */
    static parseResponseMetaStatusCode = (apiResponse) => {
        const metadata = get(apiResponse, "$metadata");
        return metadata.httpStatusCode || get(apiResponse, "httpStatusCode");
    };

    /**
     * Generates a string response, that includes the response status code and optional message, which will be included
     *  in the response object and can later be used as needed to surface additional response details to the User.
     * @param {number} httpStatusCode - The numerical status code returned as a part of the '$metadata' sub-object.
     * @param {string} details - Optional added details regarding the status code and response received.
     * @returns {string} - A generated info string to be included with the response object, and that can later be used
     *  as needed (e.g. UI toasts).
     */
    static surfaceStatusInfo = (httpStatusCode, details = "N/A") => {
        return `Received unanticipated response status: ${httpStatusCode}, with additional details: ${details}.`;
    };

    /**
     * Custom Credentials Provider for AmpClient instances. Does 2 core things:
     *  1) Ensures a call to refresh token authentication is done (e.g. invoking a refresh of the Midway token)
     *  2) Handles role assumption based on the current client type ('amp') and its
     *     associated Api configuration
     * @returns {Promise<Credentials>} - The credentials object to be applied in the client configuration.
     */
    credentialsProvider = async () => {
        const credentials = await this.auth
            .refresh()
            .then(() => {
                userAlias = this.auth.getUsername();
                return this.auth.assumeRole(
                    this.clientApiConfig.GATEWAY_EXEC_ROLE,
                    this.clientType
                );
            })
            .catch((error) => {
                // Surface any errors while providing credentials.
                console.log("Error getting the credentials");
                throw error;
            });
        localStorage.setItem(USERNAME_KEY, JSON.stringify(userAlias));
        return credentials;
    };

    /**
     * Creates the appropriate client for use in Api calling based on passed in clientType.
     * @returns {AssetMaximizationPlannerClient}
     */
    createClient = () => {
        const clientConfig = {
            region: this.clientApiConfig.REGION,
            endpoint: this.clientApiConfig.ENDPOINT,
            maxAttempts: MAX_ATTEMPTS,
            credentials: this.credentialsProvider,
        };

        switch (this.clientType) {
            case "amp":
                return new AssetMaximizationPlannerClient(clientConfig);
            default:
                throw new InvalidClientTypeException(
                    `No valid API client found from type: ${this.clientType}`
                );
        }
    };

    /**
     * Executes the passed in command with the configured Api client.
     * @param {object} command - Smithy defined Api COMMAND object to execute.
     * @param {number} maxAttempts - The maximum number of retries on any failure while sending the api command.
     * @returns {object|Error} - The response object received on success, or a thrown Error instance on unhandled failure.
     */
    send = async (command, maxAttempts = MAX_ATTEMPTS) => {
        const formattedResult = {httpStatusCode: null, data: null};
        const response = await this.client
            .send(command)
            .then((result) => {
                const httpStatusCode =
                    this.constructor.parseResponseMetaStatusCode(result);
                formattedResult.httpStatusCode = httpStatusCode;
                formattedResult.data = result;

                if (httpStatusCode !== 200) {
                    formattedResult.statusInfo =
                        this.constructor.surfaceStatusInfo(httpStatusCode);
                }

                return formattedResult;
            })
            .catch(async (error) => {
                if (this.constructor.checkTokenExpired(error) && maxAttempts > 0) {
                    // Destroy the underlying resources, and recreate the client with refreshed Auth
                    this.client.destroy();
                    this.client = this.createClient();
                    // Invokes a second Api request attempt after renewing the user token and auth credentials.
                    const retryResponse = await this.send(command, maxAttempts - 1);
                    return retryResponse;
                }

                // Format an appropriate error response
                const httpStatusCode =
                    this.constructor.parseResponseMetaStatusCode(error);
                // Formats a standard Amp Send Error response
                formattedResult.date = error.date || new Date();
                formattedResult.fault = error.$fault;
                formattedResult.hasError = true;
                formattedResult.httpStatusCode = httpStatusCode;
                formattedResult.message = `Status: ${httpStatusCode}, Message: ${
                    !error.message || error.message.length === 0
                        ? "Error Is Missing Message"
                        : error.message
                }`;
                formattedResult.metadata = error.$metadata;
                formattedResult.stack = error.stack;
                formattedResult.statusInfo = this.constructor.surfaceStatusInfo(
                    httpStatusCode,
                    `${error.stack.slice(0, 100)}...(see 'stack')`
                );
                formattedResult.sourceErrorName = error.name;

                // Handle any unhandled Midway Auth missing or expired exceptions that reach here as a
                // 'client' type error response, as the client needs new credentials.
                if (
                    includes(
                        ["MidwayAuthMissingException", "MidwayAuthExpiredException"],
                        error.name
                    )
                ) {
                    formattedResult.fault = "client";
                }

                // Surface the original error formatted based on expected $fault of 'client' or 'server'
                if (formattedResult.fault === "client") {
                    throw new AmpClientException(formattedResult);
                } else if (formattedResult.fault === "server") {
                    throw new AmpServerException(formattedResult);
                }

                // Should rarely* reach here, as exceptions carry either 'server' or 'client' fault codes, and would be
                // resolved as an appropriate exception above.
                // However, this is a fallback to cover any edge cases.
                throw new AmpException(formattedResult);
            });
        return response;
    };
}

// Base constructed API clients
export const AmpLinkConfig = new AmpLink("amp");
