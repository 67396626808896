import React, {createContext, useContext, useState} from "react";
import {NODE_LIST_KEY} from "../../constants";
import {IId} from "../../common/api/api";

interface INodeListStateProps {
    nodeList: string[];

    setNodeList(nodeList: IId[]): any;
}

export const initialState: INodeListStateProps = {
    nodeList: [],
    setNodeList: () => {
    },
};

const NodeListContext = createContext(initialState);

export const NodeListProvider = ({children}) => {
    const localStorageNodeList = localStorage.getItem(NODE_LIST_KEY);
    const [nodeList, setNodeList] = useState(localStorageNodeList ? JSON.parse(localStorageNodeList) : initialState.nodeList);

    return (<NodeListContext.Provider value={{nodeList, setNodeList}}>{children}</NodeListContext.Provider>);
};
const useNodeList = () => {
    const context = useContext(NodeListContext);
    if (context === undefined) {
        throw new Error("useNodeList must be used within a NodeListProvider");
    }
    return context;
};
export default useNodeList;
