import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import {nanoid} from "nanoid";
import {IProcessPathRates} from "../../common/api/api";
import CreatableSelect from "react-select/creatable";

function PPRatesAddForm({
                            addFormHandler,
                            cancelHandler,
                            uniqueSizes,
                            uniqueRoles,
                            uniqueProcessPaths
                        }) {
    const [process_path, setProcess_path] = useState("");
    const [role, setRole] = useState("");
    const [size, setSize] = useState("");
    const [rate, setRate] = useState("");
    // Checking for blank fields
    const [errorMessage, setErrorMessage] = useState("");

    function submitHandler(event) {
        event.preventDefault();
        if (!process_path || !size || !role || !rate) {
            setErrorMessage("Fill all the fields before submitting!");
        } else {
            const addData: IProcessPathRates = {
                id: nanoid(),
                process_path: process_path['label'],
                role: role['label'],
                size: size['label'],
                rate: parseInt(rate),
            };
            addFormHandler(addData);
        }
    }

    //Convert processPath to hold value and label
    uniqueProcessPaths = uniqueProcessPaths.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert size to hold value and label
    uniqueSizes = uniqueSizes.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert size to hold value and label
    uniqueRoles = uniqueRoles.map((str) => ({
        value: str,
        label: str,
    }));
    //Custom styles for the creatable select
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Forest" : "Forest",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };

    return (
        <>
            <Column>
                <form onSubmit={submitHandler}>
                    <Column>
                        <Heading level={4}>Add New Process Path Rate</Heading>
                        <Column>
                            <div style={{width: "300px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setProcess_path}
                                    options={uniqueProcessPaths}
                                    value={process_path}
                                    styles={customStyles}
                                    placeholder="Process Path"
                                />
                            </div>

                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setSize}
                                    options={uniqueSizes}
                                    value={size}
                                    styles={customStyles}
                                    placeholder="Size"
                                />
                            </div>

                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setRole}
                                    options={uniqueRoles}
                                    value={role}
                                    styles={customStyles}
                                    placeholder="Role"
                                />
                            </div>

                            <Input
                                name="rate"
                                value={rate}
                                label="Rate"
                                type="number"
                                onChange={setRate}
                                width={100}
                                size="small"
                            />
                        </Column>
                        <Box>
                            <Row>
                                <Button submit={true}>Add</Button>
                                <Button onClick={cancelHandler}>Cancel</Button>
                                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                            </Row>
                        </Box>
                        <Row></Row>
                    </Column>
                </form>
            </Column>
        </>
    );
}

export default PPRatesAddForm;
