import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";

function ReadOnlyLocSizeAdmissibilityRow({
  locSizeAdmissibility,
  selectedNode,
  handleEditClick,
  handleDeleteClick,
}) {
  return (
    <Table spacing="small">
      <TableRow>
        <TableCell width={100}>
          <Input value={selectedNode} />
        </TableCell>
        <TableCell width={150}>
          <Input value={locSizeAdmissibility.location} />
        </TableCell>
        <TableCell width={150}>
          <Input value={locSizeAdmissibility.size} />
        </TableCell>
        <TableCell width={90}>
          <Input value={locSizeAdmissibility.is_admissible} />
        </TableCell>
        <TableCell>
          <Row>
            <Button minWidth={70} onClick={(event) => handleDeleteClick(locSizeAdmissibility)}>
              Delete
            </Button>
            <Button minWidth={70} onClick={(event) => handleEditClick(locSizeAdmissibility)}>
              Edit
            </Button>
          </Row>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default ReadOnlyLocSizeAdmissibilityRow;
