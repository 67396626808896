import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import {nanoid} from "nanoid";
import CreatableSelect from "react-select/creatable";
import {IInboundContainerEligibility} from "../../common/api/api";

function InboundConEligAddForm({uniqueContainers, uniqueInboundProcesses, addFormHandler, cancelHandler, uniqueSizes}) {
    const [container, setContainer] = useState("");
    const [size, setSize] = useState("");
    const [value, setValue] = useState("");
    const [inbound_process, setInbound_process] = useState("");

    //Error checking
    const [errorMessage, setErrorMessage] = useState("");
    //Convert size to hold value and label
    uniqueSizes = uniqueSizes.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert inbound-processes to hold value and label
    uniqueInboundProcesses = uniqueInboundProcesses.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert container to hold value and label
    uniqueContainers = uniqueContainers.map((str) => ({
        value: str,
        label: str,
    }));
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Black" : "Navy",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };

    //Submit Handler
    function submitHandler(event) {
        event.preventDefault();
        if (!container || !size || !value || !inbound_process) {
            setErrorMessage("Fill all the fields before submitting!");
        } else {
            const addData: IInboundContainerEligibility = {
                id: nanoid(),
                container: container['label'],
                size: size['label'],
                value: parseInt(value),
                inbound_process: inbound_process['label'],
            };
            addFormHandler(addData);
        }
    }

    return (
        <>
            <Column>
                <form onSubmit={submitHandler}>
                    <Column>
                        <Heading level={4}>Add New Process Path Capacity</Heading>
                        <Row height={50}>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setContainer}
                                    options={uniqueContainers}
                                    value={container}
                                    styles={customStyles}
                                    placeholder="Container"
                                />
                            </div>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setSize}
                                    options={uniqueSizes}
                                    value={size}
                                    styles={customStyles}
                                    placeholder="Size"
                                />
                            </div>
                        </Row>
                        <Row>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setInbound_process}
                                    options={uniqueInboundProcesses}
                                    value={inbound_process}
                                    styles={customStyles}
                                    placeholder="Inbound Process"
                                />
                            </div>
                            <Input
                                name="value"
                                value={value}
                                label="value"
                                type="number"
                                onChange={setValue}
                                width={200}
                                size="small"
                            />
                        </Row>
                        <Box>
                            <Row>
                                <Button submit={true}>Add</Button>
                                <Button onClick={cancelHandler}>Cancel</Button>
                                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                            </Row>
                        </Box>
                        <Row></Row>
                    </Column>
                </form>
            </Column>
        </>
    );
}

export default InboundConEligAddForm;
