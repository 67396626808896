import useSelectedNode from "../../components/hooks/nodeContext";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import Row from "@amzn/meridian/row";
import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Upload from "./Upload";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Button from "@amzn/meridian/button";
import useNodeList from "../../components/hooks/NodeListContext";
import Heading from "@amzn/meridian/heading";
import AmpApi from "../../common/api/AmpApi";
import {IId} from "../../common/api/api";
import {NODE_LIST_KEY} from "../../constants";

function Home() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const {nodeId} = useParams<{ nodeId: string }>();
    const {nodeList,setNodeList} = useNodeList();
    const [nodeListEmpty, setNodeListEmpty] = useState(false);
    //For the config new site modal
    const [open, setOpen] = useState(false)
    const onClickButton = useCallback(() => setOpen(true), [])
    const onClickFooterButton = useCallback(() => setOpen(false), [])
    const onClose = useCallback(() => setOpen(false), [])

    //useEffect to update the nodeId
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
    }, []);
    useEffect(() => {
        (async () => {
            try {
                const getAllNodeIds = await AmpApi.getAllNodeIds();
                if (getAllNodeIds) {
                    const ids: IId[] = getAllNodeIds.data.ids;
                    const finalList: IId[] = [...ids];
                    //Add nodeList to the localStorage
                    localStorage.setItem(NODE_LIST_KEY, JSON.stringify(finalList));
                    setNodeList(finalList);
                    if(finalList.length==0){
                        setNodeListEmpty(true);
                    }
                    else{
                        setNodeListEmpty(false);
                    }
                }
            } catch (ex: any) {
                console.error(`Error fetching node list`, ex);
            }
        })();
    }, []);

    return (
        <>
            <Modal
                title="Configure New Site"
                open={open}
                onClose={onClose}
                scrollContainer="viewport"
                closeLabel="Close"
                aria-describedby="modal-description"
            >
                <Text id="modal-description">Enter Sort Center Id and Initial Configuration File</Text>
                <Upload/>
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="secondary" size="small" onClick={onClickFooterButton}>
                            Cancel
                        </Button>
                        <Button type="primary" size="small" onClick={onClickFooterButton}>
                            Confirm
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
            <div className="welcomeDiv">
                <Column spacingInset="400 500" spacing="300">
                    <Heading level={3} type="h500" color={"primary"}>
                        Asset Maximization Planner Service
                    </Heading>
                </Column>
                <Divider/>
                <Column spacingInset="400 500" width={700}>
                    <Text>Welcome to Asset Maximization Planner (AMP)</Text>
                    <Text>
                        AMP provides insights on how the Sort centers in the network are
                        performing wrt to their maximum achievable capacity in terms of
                        Asset (labor hours and TPH). AMP is a mathematical solver for volume planning and capacity.
                    </Text>
                    <Row></Row>
                    {!nodeListEmpty && <> {selectedNode == null ? (
                        <Text>Please choose a sort center from the list to proceed</Text>
                    ) : (<>
                            <Row> <Text>The sort center configurations you are currently accessing: </Text><Text>{selectedNode}</Text></Row>
                        </>
                    )}</>
                    }

                    {nodeListEmpty && <> <Text>Click the button below to configure a new Site</Text>
                        <div aria-valuemax={300}><Button size={"small"} onClick={onClickButton}>Configure
                            New
                            Site</Button></div>
                    </>}
                </Column>
            </div>
        </>
    );
}

export default Home;
