import PPRatesTable from "./PPRatesTable";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import useSelectedNode from "../../components/hooks/nodeContext";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AmpApi from "../../common/api/AmpApi";
import {IProcessPathRates} from "../../common/api/api";
import Loader from "@amzn/meridian/loader";
import Box from "@amzn/meridian/box";
import { Redirect } from "react-router";

function PPRates() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [isLoading, setIsLoading] = useState(false);
    const [ppRatesList, setPPRatesList] = useState([]);
    //Get nodeId from the url
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }
    //Load Process Path Rate entries when the page first loads
    useEffect(() => {
        setIsLoading(true);
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getResponse = await AmpApi.getProcessPathRates(nodeKey);
                if (getResponse) {
                    const processPathRates: IProcessPathRates[] = getResponse.data
                        .prRatesInfos as IProcessPathRates[];
                    const finalList = [...processPathRates];
                    setPPRatesList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching ${selectedNode}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <>
                <Card width="fill" spacingInset="none">
                    <Column spacingInset="400 500" alignmentHorizontal={"center"}>
                        {isLoading ? (
                            <Loader/>
                        ) : (
                            <Box type="outline" spacingInset="400">
                                <PPRatesTable selectedNode={selectedNode} ppRatesList={ppRatesList}/>
                            </Box>
                        )}
                    </Column>
                </Card>
            </>
        )
    );
}

export default PPRates;
