import useSelectedNode from "../../components/hooks/nodeContext";
import PPRelationshipTable from "./ppRelationshipTable/PPRelationshipTable";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import {useEffect, useState} from "react";
import AmpApi from "../../common/api/AmpApi";
import {IProcessPathRelationship} from "../../common/api/api";
import {useParams} from "react-router-dom";
import Box from "@amzn/meridian/box";
import { Redirect } from "react-router";

function PPRelationship() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [isLoading, setIsLoading] = useState(false);
    const [ppRelList, setPPRelList] = useState([]);
    //Get nodeId from the url
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }

    useEffect(() => {
        setIsLoading(true);
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getResponse = await AmpApi.getProcessPathRelationships(nodeKey);
                if (getResponse) {
                    const processPathRels: IProcessPathRelationship[] = getResponse.data
                        .prRelationships as IProcessPathRelationship[];
                    const finalList = [...processPathRels];
                    setPPRelList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching ${selectedNode}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);
    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <>
                <Card width="fill" spacingInset="none">
                    <Column spacingInset="400 500" alignmentHorizontal={"center"}>
                        {isLoading ? (
                            <Loader/>
                        ) : (
                            <Box type="outline" spacingInset="400">
                                <PPRelationshipTable selectedNode={nodeKey} ppRelList={ppRelList}/>
                            </Box>
                        )}
                    </Column>
                </Card>
            </>
        )
    );
}

export default PPRelationship;
