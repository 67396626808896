import Column from "@amzn/meridian/column";
import useSelectedNode from "../../components/hooks/nodeContext";
import Card from "@amzn/meridian/card";
import Box from "@amzn/meridian/box";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Submitmodal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import Button from "@amzn/meridian/button";
import { DOWNLOAD_FAIL_STATUS } from "../../constants";
import AmpApi from "../../common/api/AmpApi";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import { Redirect } from "react-router";

function Download() {
  const { selectedNode, changeSelectedNode } = useSelectedNode();
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  const { nodeId } = useParams<{ nodeId: string }>();

  //useEffect to update the nodeId
  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
  }, []);

  function downloadHandler() {
    setLoading(true);
    (async () => {
      try {
        const response = await AmpApi.downloadModelConfig(selectedNode);
        window.open(response.data.downloadUrl, "_self");
      } catch (exception: any) {
        console.error(`Error downloading configurations`, exception);
        setOpenModal(true);
        setResponseMessage(DOWNLOAD_FAIL_STATUS);
      } finally {
        setLoading(false);
      }
    })();
  }

  return (
      nodeId != selectedNode ? (
          <Redirect to="/"/>
      ) : (
          <div>
            <Submitmodal
              open={openModal}
              responseMessage={responseMessage}
              onClose={onConfirmClose}
            />
            {loading ? (
                <Loader />
            ) : (
                <Column spacingInset="500">
                    <Row>
                      <Text color={"primary"}>Please click below to download a copy of the configuration in
                        excel
                        form:</Text>
                    </Row>
                    <Row></Row>
                    <Row>
                      <Button onClick={downloadHandler}>Download Configuration</Button>
                    </Row>
                </Column>
            )}
          </div>
      )
  );
}

export default Download;
