import { useCallback, useState } from "react";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DEFAULT_SORT_DIRECTION,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_PP_HEADCOUNT,
  ITEMS_PER_PAGE,
  MAX_HC_LABEL,
  MIN_HC_LABEL,
  PLEASE_WAIT_STATUS,
  PPHC_HEADERS,
  PROCESS_PATH_LABEL,
  ROLE_LABEL,
  ROLE_TYPE_LABEL,
  SHIFT_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
} from "../../constants";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import PPHeadCountAddForm from "./PPHeadCountAddForm";
import Button from "@amzn/meridian/button";
import EditablePPHeadcountRow from "./EditablePPHeadcountRow";
import ReadOnlyPPHeadcountRow from "./ReadOnlyPPHeadcountRow";
import Input from "@amzn/meridian/input";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import { IId, IProcessPathHeadcount } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function ppHeadcountTable({ ppHeadCountList, selectedNode }) {
  //useState for the process path headcount list
  const [ppHCList, setPpHCList] = useState(ppHeadCountList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(ppHCList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(PPHC_HEADERS.PROCESS_PATH)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IProcessPathHeadcount = {
    id: "",
    process_path: "",
    role: "",
    role_type: "",
    shift: "",
    min_hc: 0,
    max_hc: 0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new Process Path Headcount record
   * @param addData The new Process Path Headcount to be added.
   */
  function addFormHandler(addData) {
    let result = ppHCList.filter(function (ppHeadCountItem) {
      return (
        ppHeadCountItem.process_path == addData.process_path &&
        ppHeadCountItem.role == addData.role &&
        ppHeadCountItem.shift == addData.shift
      );
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_PP_HEADCOUNT);
      onOpenToast();
    } else {
      const addList: IProcessPathHeadcount[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updateProcessPathHeadcount(selectedNode, finalList);
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setPpHCList([addData, ...ppHCList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editPpHCId, setEditPpHCId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * This function Updates the process path Headcount info
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    let result = ppHCList.filter(function (ppHeadCountItem) {
      return (
        ppHeadCountItem.process_path == newFormData.process_path &&
        ppHeadCountItem.role == newFormData.role &&
        ppHeadCountItem.shift == newFormData.shift
      );
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_PP_HEADCOUNT);
      onOpenToast();
    } else {
      const updateList: IProcessPathHeadcount[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updateProcessPathHeadcount(
            selectedNode,
            finalList
          );
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          const newEditPpHCList = [...ppHCList];
          setEditFormData(newFormData);
          const index = newEditPpHCList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          newEditPpHCList[index] = newFormData;
          setPpHCList(newEditPpHCList);
          setEditPpHCId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (pphc) => {
    setEditPpHCId(pphc.id);
    const formValues = {
      id: pphc.id,
      process_path: pphc.process_path,
      role: pphc.role,
      role_type: pphc.role_type,
      shift: pphc.shift,
      min_hc: pphc.min_hc,
      max_hc: pphc.max_hc,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditPpHCId(null);
  };
  /**
   * To handle the delete button
   * @param pphc The Process Path Headcount object to be deleted
   */
  const handleDeleteClick = (pphc) => {
    const idsList: IId[] = [];
    const finalIds: IId[] = [pphc, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deleteProcessPathHeadcount(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const newPpHCList = [...ppHCList];
        const index = newPpHCList.findIndex((obj) => {
          return obj.id === pphc.id;
        });
        newPpHCList.splice(index, 1);
        setPpHCList(newPpHCList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${pphc}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  // Get unique role, role_types, processPaths
  let roles = ppHeadCountList.map((a) => a.role);
  let uniqueRoles = [...new Set(roles)];
  let roleTypes = ppHeadCountList.map((a) => a.role_type);
  let uniqueRoleTypes = [...new Set(roleTypes)];
  let processPathsOnly = ppHeadCountList.map((a) => a.process_path);
  let uniqueProcessPaths = [...new Set(processPathsOnly)];

  ppHeadCountList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          <PPHeadCountAddForm
            addFormHandler={addFormHandler}
            cancelHandler={cancelHandler}
            uniqueRoles={uniqueRoles}
            uniqueRoleTypes={uniqueRoleTypes}
            uniqueProcessPaths={uniqueProcessPaths}
          />
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>

            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Filter by Process Path"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.PROCESS_PATH} width={200}>{PROCESS_PATH_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.ROLE} width={120}>{ROLE_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.ROLE_TYPE} width={120}>{ROLE_TYPE_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.SHIFT} width={100}>{SHIFT_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.MIN_HC} width={70}>{MIN_HC_LABEL}</TableCell>
            <TableCell sortColumn={PPHC_HEADERS.MAX_HC} width={70}>{MAX_HC_LABEL}</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </Table>

        {ppHCList
          .filter((pphc) => {
            if (searchTerm == "") {
              return pphc;
            } else if (
              pphc.process_path.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return pphc;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((pphc) => (
            <div key={pphc.id}>
              {editPpHCId === pphc.id ? (
                <EditablePPHeadcountRow
                  pphc={pphc}
                  selectedNode={selectedNode}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                  uniqueRoles={uniqueRoles}
                  uniqueRoleTypes={uniqueRoleTypes}
                  uniqueProcessPaths={uniqueProcessPaths}
                />
              ) : (
                <ReadOnlyPPHeadcountRow
                  pphc={pphc}
                  selectedNode={selectedNode}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}

        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default ppHeadcountTable;
