import {
  DeleteIBContainerSizesCommand,
  DeleteIBContainerSizesRequest,
  DeleteLocationSizeAdmissibilityCommand,
  DeleteLocationSizeAdmissibilityRequest,
  DeleteMheConfigCommand,
  DeleteMheConfigRequest,
  DeletePkgSplitsContainerTypesCommand,
  DeletePkgSplitsContainerTypesRequest,
  DeletePPCapacitiesCommand,
  DeletePPCapacitiesRequest,
  DeletePPRatesInfosRequest,
  DeletePPRelationshipsRequest,
  DeletePRRatesInfosCommand,
  DeletePRRelationshipsCommand,
  DeleteShiftInfosCommand,
  DeleteShiftInfosRequest,
  DeleteVolSplitPerLocationCommand,
  DeleteVolSplitPerLocationRequest,
  DownloadAmpModelOutputCommand,
  DownloadAmpModelOutputRequest,
  DownloadModelConfigCommand,
  DownloadModelConfigRequest,
  GetAllNodeIdsCommand,
  GetAllNodeIdsRequest,
  GetAmpModelRunInfosCommand,
  GetAmpModelRunInfosRequest,
  GetIBContainerSizesCommand,
  GetIBContainerSizesRequest,
  GetLocationSizeAdmissibilityCommand,
  GetLocationSizeAdmissibilityRequest,
  GetMheConfigCommand,
  GetMheConfigRequest,
  GetPkgSplitsContainerTypesCommand,
  GetPkgSplitsContainerTypesRequest,
  GetPPCapacitiesCommand,
  GetPPCapacitiesRequest,
  GetPPHCInfosCommand,
  GetPPHCInfosRequest,
  GetPPRatesInfosRequest,
  GetPPRelationshipsRequest,
  GetPRRatesInfosCommand,
  GetPRRelationshipsCommand,
  GetShiftInfosCommand,
  GetShiftInfosRequest,
  GetVolSplitPerLocationCommand,
  GetVolSplitPerLocationRequest,
  UpdateIBContainerSizesCommand,
  UpdateIBContainerSizesRequest,
  UpdateLocationSizeAdmissibilityCommand,
  UpdateLocationSizeAdmissibilityRequest,
  UpdateMheConfigCommand,
  UpdateMheConfigRequest,
  UpdatePkgSplitsContainerTypesCommand,
  UpdatePkgSplitsContainerTypesRequest,
  UpdatePPCapacitiesCommand,
  UpdatePPCapacitiesRequest,
  UpdatePPHCInfosCommand,
  UpdatePPHCInfosRequest,
  UpdatePPRatesInfosRequest,
  UpdatePPRelationshipsRequest,
  UpdatePRRatesInfosCommand,
  UpdatePRRelationshipsCommand,
  UpdateShiftInfosCommand,
  UpdateShiftInfosRequest,
  UpdateVolSplitPerLocationCommand,
  UpdateVolSplitPerLocationRequest,
  UploadInitialModelConfigCommand,
  UploadInitialModelConfigRequest,
  RunAmpModelRequest,
  RunAmpModelCommand
} from "@amzn/asset-maximization-planner-service-client";
import {AmpLinkConfig} from "../AmpLink.js";
import AmpException from "../errors/AmpException";
import {USER_ACCESS_KEY, USERNAME_KEY} from "../../constants";

export default class AmpApi {

    private static userLogin = JSON.parse(localStorage.getItem(USERNAME_KEY)) ;

    /**
     * Api call to get all MHE_Config
     * @param nodeId The sort center id.
     */
    static async getMheConfig(nodeId: string) {
        const getMheConfigRequest: GetMheConfigRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };

        return await AmpLinkConfig.send(
            new GetMheConfigCommand(getMheConfigRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert mhe configuration
     * @param nodeId
     * @param MheConfigs
     * @returns
     */
    static async updateMheConfig(nodeId, MheConfigs) {
        const updateMheConfigRequest: UpdateMheConfigRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            mheConfig: MheConfigs,
        };

        return await AmpLinkConfig.send(
            new UpdateMheConfigCommand(updateMheConfigRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete an MHE_Config
     * @param nodeId The sort center
     * @param idsList The list of MHE_Configurations to be updated
     */
    static async deleteMheConfig(nodeId, idsList) {
        const deleteMheConfigRequest: DeleteMheConfigRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: idsList,
        };
        return await AmpLinkConfig.send(
            new DeleteMheConfigCommand(deleteMheConfigRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to upload initial configuration
     * @param nodeId The sort center
     * @param fileBuffer The file to be uploaded
     */
    static async configNewSite(nodeId: string, fileBuffer: any) {
        const uploadInitialModelConfigRequest: UploadInitialModelConfigRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            csvData: new Uint8Array(fileBuffer),
        };

        return await AmpLinkConfig.send(
            new UploadInitialModelConfigCommand(uploadInitialModelConfigRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all the initial sort center names
     */
    static async getAllNodeIds() {
        const getAllNodeIdsRequest: GetAllNodeIdsRequest = {
            login: this.userLogin
        };

        console.log("request is", getAllNodeIdsRequest);
        return await AmpLinkConfig.send(
            new GetAllNodeIdsCommand(getAllNodeIdsRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * Api call to get all Process Path Relationships
     * @param nodeId The sort center id
     */
    static async getProcessPathRelationships(nodeId) {
        const getRequest: GetPPRelationshipsRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };

        return await AmpLinkConfig.send(new GetPRRelationshipsCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert the process path relationships
     * @param nodeId The sort center id
     * @param pprs list of process path relationships
     * @returns
     */
    static async updatePPRelationship(nodeId, pprs) {
        const request: UpdatePPRelationshipsRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            prRelationships: pprs,
        };

        return await AmpLinkConfig.send(new UpdatePRRelationshipsCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Process Path Relationships
     * @param nodeId The sort center id.
     * @param proPathRels The list of process path relationships to delete
     */
    static async deletePPRelationship(nodeId, proPathRels) {
        const request: DeletePPRelationshipsRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: proPathRels,
        };

        return await AmpLinkConfig.send(new DeletePRRelationshipsCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Process Path Relationships
     * @param nodeId The sort center id
     */
    static async getProcessPathRates(nodeId) {
        const getRequest: GetPPRatesInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetPRRatesInfosCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert process path rates info.
     * @param nodeId The sort center id
     * @param pprs list of process path rates records
     * @returns
     */
    static async updatePPRates(nodeId, pprs) {
        const request: UpdatePPRatesInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            prRatesInfos: pprs,
        };

        return await AmpLinkConfig.send(new UpdatePRRatesInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Process Path Relationships
     * @param nodeId The sort center id.
     * @param proPathRates The list of process path relationships to delete
     */
    static async deletePPRates(nodeId, proPathRates) {
        const request: DeletePPRatesInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: proPathRates,
        };
        return await AmpLinkConfig.send(new DeletePRRatesInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Process Path Capacities
     * @param nodeId The sort center id
     */
    static async getProcessPathCapacity(nodeId) {
        const getRequest: GetPPCapacitiesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetPPCapacitiesCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert process path capacity info.
     * @param nodeId The sort center id
     * @param ppcs list of process path rates records
     * @returns
     */
    static async updatePPCapacity(nodeId, ppcs) {
        const request: UpdatePPCapacitiesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ppCapacities: ppcs,
        };
        return await AmpLinkConfig.send(new UpdatePPCapacitiesCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Process Path Capacity
     * @param nodeId The sort center id.
     * @param ppCapacity The list of process path relationships to delete
     */
    static async deletePPCapacity(nodeId, ppCapacity) {
        const request: DeletePPCapacitiesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: ppCapacity,
        };
        return await AmpLinkConfig.send(new DeletePPCapacitiesCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Shifts_Config
     * @param nodeId The sort center id
     */
    static async getShiftsConfig(nodeId) {
        const getRequest: GetShiftInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetShiftInfosCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert process path capacity info.
     * @param nodeId The sort center id
     * @param scs list of process path rates records
     * @returns
     */
    static async updateShiftsConfig(nodeId, scs) {
        const request: UpdateShiftInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            shiftInfos: scs,
        };
        return await AmpLinkConfig.send(new UpdateShiftInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Process Path Capacity
     * @param nodeId The sort center id.
     * @param shiftsConfigs The list of process path relationships to delete
     */
    static async deleteShiftsConfig(nodeId, shiftsConfigs) {
        const request: DeleteShiftInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: shiftsConfigs,
        };
        return await AmpLinkConfig.send(new DeleteShiftInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Process path Headcount
     * @param nodeId The sort center id
     */
    static async getProcessPathHeadcount(nodeId) {
        const getRequest: GetPPHCInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetPPHCInfosCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert process path Headcount info.
     * @param nodeId The sort center id
     * @param hcList list of process path rates records
     * @returns
     */
    static async updateProcessPathHeadcount(nodeId, hcList) {
        const request: UpdatePPHCInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ppHCInfos: hcList,
        };
        return await AmpLinkConfig.send(new UpdatePPHCInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete process path Headcount
     * @param nodeId The sort center id.
     * @param headCountList The list of process path relationships to delete
     */
    static async deleteProcessPathHeadcount(nodeId, headCountList) {
        const request: DeleteShiftInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: headCountList,
        };
        return await AmpLinkConfig.send(new DeleteShiftInfosCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Inbound Container Eligibility
     * @param nodeId The sort center id
     */
    static async getInboundContainerEligibility(nodeId) {
        const getRequest: GetIBContainerSizesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetIBContainerSizesCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert Inbound Container Eligibility.
     * @param nodeId The sort center id
     * @param ibcList list of Inbound Container Eligibility info
     * @returns
     */
    static async updateInboundContainerEligibility(nodeId, ibcList) {
        const request: UpdateIBContainerSizesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ibContainerSizes: ibcList,
        };
        return await AmpLinkConfig.send(new UpdateIBContainerSizesCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Inbound Container Eligibility.
     * @param nodeId The sort center id.
     * @param headCountList The list of Inbound Container Eligibility data to delete
     */
    static async deleteInboundContainerEligibility(nodeId, ibcList) {
        const request: DeleteIBContainerSizesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: ibcList,
        };
        return await AmpLinkConfig.send(
            new DeleteIBContainerSizesCommand(request)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Location Size Admissibility
     * @param nodeId The sort center id
     */
    static async getLocationSizeAdmissibility(nodeId) {
        const getRequest: GetLocationSizeAdmissibilityRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetLocationSizeAdmissibilityCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert Location Size Admissibility.
     * @param nodeId The sort center id
     * @param locationSizeAdmissibilityList list of Location Size Admissibility info
     * @returns
     */
    static async updateLocationSizeAdmissibility(nodeId, locationSizeAdmissibilityList) {
        const request: UpdateLocationSizeAdmissibilityRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            locationSizeAdmissibilityList: locationSizeAdmissibilityList,
        };
        return await AmpLinkConfig.send(new UpdateLocationSizeAdmissibilityCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Location Size Admissibility.
     * @param nodeId The sort center id.
     * @param locationSizeAdmissibilityList The list of Location Size Admissibility data to delete
     */
    static async deleteLocationSizeAdmissibility(nodeId, locationSizeAdmissibilityList) {
        const request: DeleteLocationSizeAdmissibilityRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: locationSizeAdmissibilityList,
        };
        return await AmpLinkConfig.send(
            new DeleteLocationSizeAdmissibilityCommand(request)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Vol Split Per Location info
     * @param nodeId The sort center id
     */
    static async getVolSplitPerLocation(nodeId) {
        const getRequest: GetVolSplitPerLocationRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetVolSplitPerLocationCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert Vol Split Per location.
     * @param nodeId The sort center id
     * @param volSplitPerLocationList list of Vol Split Per Location info
     * @returns
     */
    static async updateVolSplitPerLocation(nodeId, volSplitPerLocationList) {
        const request: UpdateVolSplitPerLocationRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            volSplitPerLocationList: volSplitPerLocationList,
        };
        return await AmpLinkConfig.send(new UpdateVolSplitPerLocationCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Vol Split Per Location.
     * @param nodeId The sort center id.
     * @param volSplitPerLocationList The list of Vol Split Per Location data to delete
     */
    static async deleteVolSplitPerLocation(nodeId, volSplitPerLocationList) {
        const request: DeleteVolSplitPerLocationRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: volSplitPerLocationList,
        };
        return await AmpLinkConfig.send(
            new DeleteVolSplitPerLocationCommand(request)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to get all Pkg Splits Container Type info
     * @param nodeId The sort center id
     */
    static async getPkgSplitsContainerType(nodeId) {
        const getRequest: GetPkgSplitsContainerTypesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };
        return await AmpLinkConfig.send(new GetPkgSplitsContainerTypesCommand(getRequest))
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to upsert Pkg Splits Container Type.
     * @param nodeId The sort center id
     * @param pkgSplitsContainerTypeList list of Pkg Splits Container Type info
     * @returns
     */
    static async updatePkgSplitsContainerType(nodeId, pkgSplitsContainerTypeList) {
        const request: UpdatePkgSplitsContainerTypesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            pkgSplitsContainerTypes: pkgSplitsContainerTypeList,
        };
        return await AmpLinkConfig.send(new UpdatePkgSplitsContainerTypesCommand(request))
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to delete Pkg Splits Container Type.
     * @param nodeId The sort center id.
     * @param pkgSplitsContainerTypeList The list of Pkg Splits Container Type data to delete
     */
    static async deletePkgSplitsContainerType(nodeId, pkgSplitsContainerTypeList) {
        const request: DeletePkgSplitsContainerTypesRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ids: pkgSplitsContainerTypeList,
        };
        return await AmpLinkConfig.send(
            new DeletePkgSplitsContainerTypesCommand(request)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                if(error.httpStatusCode == 403){
                    localStorage.setItem(USER_ACCESS_KEY, "Denied");
                }
                throw error;
            });
    }

    /**
     * Api call to download model configuration
     * @param nodeId The sort center
     */
    static async downloadModelConfig(nodeId: string) {
        const downloadModelConfigRequest: DownloadModelConfigRequest = {
            login: this.userLogin,
            nodeId: nodeId
        };
        return await AmpLinkConfig.send(
            new DownloadModelConfigCommand(downloadModelConfigRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * Api call to get all model runs
     * @param nodeId The sort center
     */
    static async getAmpModelRunInfos(nodeId: string) {
        const getAmpModelRunInfosRequest: GetAmpModelRunInfosRequest = {
            login: this.userLogin,
            nodeId: nodeId,
        };

        const getAmpModelRunInfosResponse = await AmpLinkConfig.send(
            new GetAmpModelRunInfosCommand(getAmpModelRunInfosRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
        return getAmpModelRunInfosResponse;
    }

    /**
     * Api call to download AMP model output
     * @param runId runId for the amp model
     * @param fileName filename which is to be downloaded
     */
    static async downloadAmpModelOutput(runId: string, fileName: string) {
        const downloadAmpModelOutputRequest: DownloadAmpModelOutputRequest = {
            login: this.userLogin,
            runId: runId,
            fileName: fileName
        };

        return await AmpLinkConfig.send(
            new DownloadAmpModelOutputCommand(downloadAmpModelOutputRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }

    /**
     * api call to run Amp Model
     * @param nodeId
     * @param ampModelArguments
     * @returns
     */
    static async runAmpModel(nodeId, ampModelArguments) {
        const runAmpModelRequest: RunAmpModelRequest = {
            login: this.userLogin,
            nodeId: nodeId,
            ampModelArguments: ampModelArguments
        };

        return await AmpLinkConfig.send(
            new RunAmpModelCommand(runAmpModelRequest)
        )
            .then((response) => response)
            .catch((error: AmpException) => {
                throw error;
            });
    }
}
