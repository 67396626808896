import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";

function ReadOnlyPPRelationshipRow({
  ppr,
  selectedNode,
  handleEditClick,
  handleDeleteClick,
}) {
  return (
    <Table spacing="small">
      <TableRow>
        <TableCell width={100}>
          <Input value={selectedNode} />
        </TableCell>
        <TableCell width={200}>
          <Input value={ppr.from} />
        </TableCell>
        <TableCell width={200}>
          <Input value={ppr.to} />
        </TableCell>

        <TableCell>
          <Row>
            <Button minWidth={70} onClick={(event) => handleDeleteClick(ppr)}>
              Delete
            </Button>
            <Button minWidth={70} onClick={(event) => handleEditClick(ppr)}>
              Edit
            </Button>
          </Row>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default ReadOnlyPPRelationshipRow;
