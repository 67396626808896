import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";

function ReadOnlyScRow({
  sc,
  selectedNode,
  handleEditClick,
  handleDeleteClick,
}) {
  return (
    <Table spacing="small">
      <TableRow>
        <TableCell width={100}>
          <Input value={selectedNode} />
        </TableCell>
        <TableCell width={100}>
          <Input value={sc.shift} />
        </TableCell>
        <TableCell width={100}>
          <Input value={sc.min_hc} />
        </TableCell>
        <TableCell width={100}>
          <Input value={sc.max_hc} />
        </TableCell>
        <TableCell width={70}>
          <Input value={sc.min_length} />
        </TableCell>
        <TableCell width={70}>
          <Input value={sc.max_length} />
        </TableCell>
        <TableCell>
          <Row>
            <Button minWidth={70} onClick={(event) => handleDeleteClick(sc)}>
              Delete
            </Button>
            <Button minWidth={70} onClick={(event) => handleEditClick(sc)}>
              Edit
            </Button>
          </Row>
        </TableCell>
      </TableRow>
    </Table>
  );
}

export default ReadOnlyScRow;
