import LocationSizeAdmissibilityTable from "./LocationSizeAdmissibilityTable";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import useSelectedNode from "../../components/hooks/nodeContext";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AmpApi from "../../common/api/AmpApi";
import {ILocationSizeAdmissibility} from "../../common/api/api";
import Loader from "@amzn/meridian/loader";
import Box from "@amzn/meridian/box";
import { Redirect } from "react-router";

function LocationSizeAdmissibility() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [locationSizeAdmissibilityList, setLocationSizeAdmissibilityList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }
//Load data when the page first loads
    useEffect(() => {
        setIsLoading(true);
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getResponse = await AmpApi.getLocationSizeAdmissibility(nodeKey);
                if (getResponse) {
                    console.log(getResponse.data);
                    const locationSizeAdmissibilityList: ILocationSizeAdmissibility[] = getResponse.data
                        .locationSizeAdmissibilityList as ILocationSizeAdmissibility[];
                    const finalList = [...locationSizeAdmissibilityList];
                    setLocationSizeAdmissibilityList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching ${nodeKey}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <>
                <Card width="fill" spacingInset="none">
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <>
                            <Column spacingInset="400 500" alignmentHorizontal={"center"}>
                                <Box type="outline" spacingInset="400">
                                    <LocationSizeAdmissibilityTable selectedNode={nodeKey}
                                                         LocationSizeAdmissibilityList={locationSizeAdmissibilityList}/>
                                </Box>
                            </Column>
                        </>
                    )}
                </Card>
            </>
        )
    );
}

export default LocationSizeAdmissibility;
