import {Auth} from "@amzn/nasct-common-midway-auth";

interface ApiProperties {
    readonly GATEWAY_EXEC_ROLE: string;
    readonly ENDPOINT: string;
    readonly REGION: string;
}

/**
 * AMP Api mapping of stages to ApiProperties.
 */
// ApiProperties for prod stage
const apiPropProd: ApiProperties = {
    GATEWAY_EXEC_ROLE: "arn:aws:iam::085763724467:role/amp-api-gateway-execution",
    ENDPOINT: "https://qypblhvz7e.execute-api.us-east-1.amazonaws.com/prod",
    REGION: "us-east-1",
};

// ApiProperties for beta stage
const apiPropBeta: ApiProperties = {
    GATEWAY_EXEC_ROLE: "arn:aws:iam::269523807579:role/amp-api-gateway-execution",
    ENDPOINT: "https://a88tjuy5b4.execute-api.us-east-1.amazonaws.com/prod",
    REGION: "us-east-1",
};

// ApiProperties for development stage
const apiPropDev: ApiProperties = {
    GATEWAY_EXEC_ROLE: "arn:aws:iam::200904699435:role/amp-api-gateway-execution",
    ENDPOINT: "https://b0lv1bflc3.execute-api.us-east-1.amazonaws.com/prod",
    REGION: "us-east-1",
};

/**
 * Enum of possible process.NODE_ENV values that might be encountered.
 */
enum NodeEnvs {
    DEV = 'development',
    PROD = 'production',
}

/**
 * Collection of final configuration options for use in Client configuration.
 */
const API_CONFIG_OPTIONS = new Map<string, Map<string, ApiProperties>>();
const AMP_MAP = new Map<string, ApiProperties>();
AMP_MAP.set("development", apiPropDev);
AMP_MAP.set("beta", apiPropBeta);
AMP_MAP.set("production", apiPropProd);
API_CONFIG_OPTIONS.set("amp", AMP_MAP);

/**
 * Interface that defines the ApiConfigurator shape and requirements.
 */
interface IApiConfigurator {
    NODE_ENV: string;

    getAuth(): string;

    getApiClientConfig(clientType: string): any;

    getApiConfigOptions(): any;

    setNodeEnv(nodeEnv: string): any;
}

/**
 * Access the url in order to check if the stage is prod/dev/beta
 */
const getBaseURL = () => {
    return window.location.href;
}
/**
 * url for prod and beta stages.
 */
//URL from prod
const productionURL = "https://na.amp.ats.amazon.dev";
//URL from beta
const betaURL = "https://beta.amp.ats.amazon.dev";


// @ts-ignore
/**
 * Exported Implementation of ApiConfigurator for use in Link class for
 * the Client configuration needed to achieve Api access.
 */
const ApiConfigurator: IApiConfigurator = {
    NODE_ENV: process.env.NODE_ENV as string,
    /**
     * If the url isn't prod or beta
     * then default communication role for anjuknji-dev account is used.
     */
    getAuth() {
        let MAIN_ROLE_ARN;
        //if it's prod stage
        if (getBaseURL().includes(productionURL)) {
            console.log("Using prod communication role");
            MAIN_ROLE_ARN = "arn:aws:iam::085763724467:role/amp-api-communication-role";
            console.log(MAIN_ROLE_ARN);
        }
        //if it's beta stage
        else if (getBaseURL().includes(betaURL)) {
            console.log("Using beta communication role");
            MAIN_ROLE_ARN = "arn:aws:iam::269523807579:role/amp-api-communication-role";
            console.log(MAIN_ROLE_ARN);
        }
        //If it's dev stage
        else {
            // When testing with dev account, replace the amp-api-communication-role arn from your dev account here:
            MAIN_ROLE_ARN = "arn:aws:iam::200904699435:role/amp-api-communication-role";
        }
        return new Auth({role: MAIN_ROLE_ARN});
    },

    getApiClientConfig(clientType: string) {
        if (this.NODE_ENV === NodeEnvs.PROD && getBaseURL().includes(betaURL)) {
            return API_CONFIG_OPTIONS.get(clientType)?.get('beta');
        }
        return API_CONFIG_OPTIONS.get(clientType)?.get(this.NODE_ENV);
    },

    getApiConfigOptions() {
        return API_CONFIG_OPTIONS;
    },

    setNodeEnv(nodeEnv: string) {
        this.NODE_ENV = nodeEnv;
    },
};

export default ApiConfigurator;
