import React, {useCallback} from "react";
import ReactFlow, {addEdge, useEdgesState, useNodesState,} from "react-flow-renderer";

const FlowChart = ({ppRelList}) => {
    /**
     * For creating the nodes of the flow chart
     */
    let res1 = ppRelList.map((a) => a.to);
    let res2 = ppRelList.map((a) => a.from);
    let res = [...res1, ...res2];
    let uniqueRes = [...new Set(res)];
    const initialNodes: NodeProps[] = [];

    interface NodeProps {
        id: string;
        sourcePosition: string;
        targetPosition: string;
        data: { label: string };
        position: { x: number; y: number };
        style: {
            backgroundColor: string;
            width: number;
            height: number;
        };
    }

    var j = 0;
    var k = 20;
    for (let i = 0; i < uniqueRes.length; i++) {
        let value = uniqueRes[i] as string;
        if (i % 2 == 0) {
            var srcPos = "right";
            var tarPos = "left";
        } else {
            srcPos = "top";
            tarPos = "bottom";
        }
        var nodeFlowChart: NodeProps = {
            id: "ppr-" + value,
            sourcePosition: srcPos,
            targetPosition: tarPos,
            data: {label: value},
            position: {
                x: j + 10,
                y: k + 30,
            },
            style: {
                backgroundColor: "#D6ECC9",
                width: 150,
                height: 40,
            },
        };
        initialNodes.push(nodeFlowChart);
        j = j + 30;
        k = k + 30;
    }

    /**
     * For creating the edges of the flow chart
     */
    interface EdgesProps {
        id: string;
        source: string;
        type: string;
        target: string;
        animated: boolean;
    }

    const initialEdges: EdgesProps[] = [];
    for (let i = 0; i < ppRelList.length; i++) {
        let value = ppRelList[i].from + "-" + ppRelList[i].to;
        var edgesChart: EdgesProps = {
            id: value,
            source: "ppr-" + ppRelList[i].from,
            type: "smoothstep",
            target: "ppr-" + ppRelList[i].to,
            animated: true,
        };
        initialEdges.push(edgesChart);
    }
    // @ts-ignore
    const [nodes, _, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const onConnect = useCallback(
        (params) => setEdges((els) => addEdge(params, els)),
        []
    );
    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            fitView
            attributionPosition="bottom-left"
        ></ReactFlow>
    );
};

export default FlowChart;
