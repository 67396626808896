import useSelectedNode from "../../components/hooks/nodeContext";
import MHEConfigTable from "./MHEConfigTable";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Loader from "@amzn/meridian/loader";
import Box from "@amzn/meridian/box"
// Api inclusion
import AmpApi from "../../common/api/AmpApi";
import {IMheConfig} from "../../common/api/api";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import { Redirect } from "react-router"


const MHEConfig = () => {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [mheConfigList, setMheConfigList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const {nodeId} = useParams<{ nodeId: string }>();
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }
    useEffect(() => {
        setIsLoading(true);
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        (async () => {
            try {
                const getMheConfigResponse = await AmpApi.getMheConfig(nodeKey);
                if (getMheConfigResponse) {
                    const mheConfigs: IMheConfig[] = getMheConfigResponse.data
                        .mheConfig as IMheConfig[];
                    const finalList = [...mheConfigs];
                    setMheConfigList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching ${nodeKey}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <div className="mheConfigDiv">
                <Card width="fill" spacingInset="none">
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <>
                            <Column spacingInset="400 500" alignmentHorizontal={"center"}>
                                <Box type="outline" spacingInset="400">
                                    <MHEConfigTable
                                        selectedNode={nodeId}
                                        mheConfigList={mheConfigList}
                                    />
                                </Box>
                            </Column>
                        </>
                    )}
                </Card>
            </div>
        )
    );
};

export default MHEConfig;
