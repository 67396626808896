import { useCallback, useState } from "react";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  CONTAINER_LABEL,
  DEFAULT_SORT_DIRECTION,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_INBOUND_CONTAINER_ELIGIBILITY,
  INBOUND_CONTAINER_HEADERS,
  INBOUND_PROCESS_LABEL,
  ITEMS_PER_PAGE,
  PLEASE_WAIT_STATUS,
  SIZE_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
  VALUE_LABEL,
} from "../../constants";
import Button from "@amzn/meridian/button";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import InboundConEligAddForm from "./InboundConEligAddForm";
import ReadOnlyIbceRow from "./ReadOnlyIbceRow";
import EditableIbceRow from "./EditableIbceRow";
import { IId, IInboundContainerEligibility } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Input from "@amzn/meridian/input";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function InboundConEligTable({
  selectedNode,
  InboundContainerEligibilityList,
}) {
  //useState for the list
  const [ibceList, setIbceList] = useState(InboundContainerEligibilityList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(ibceList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(INBOUND_CONTAINER_HEADERS.CONTAINER)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IInboundContainerEligibility = {
    id: "",
    container: "",
    size: "",
    inbound_process: "",
    value: 0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new Inbound Container Eligibility record
   * @param addData The new Inbound Container Eligibility to be added.
   */
  function addFormHandler(addData) {
    let result = ibceList.filter(function (inboundContainerEligItem) {
      return (
        inboundContainerEligItem.container == addData.container &&
        inboundContainerEligItem.size == addData.size &&
        inboundContainerEligItem.inbound_process == addData.inbound_process
      );
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_INBOUND_CONTAINER_ELIGIBILITY);
      onOpenToast();
    } else {
      const addList: IInboundContainerEligibility[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updateInboundContainerEligibility(
            selectedNode,
            finalList
          );
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setIbceList([addData, ...ibceList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editIbceId, setEditIbceId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * This function Updates the Inbound Container Eligibility
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    let result = ibceList.filter(function (inboundContainerEligItem) {
      return (
        inboundContainerEligItem.container == newFormData.container &&
        inboundContainerEligItem.size == newFormData.size &&
        inboundContainerEligItem.inbound_process == newFormData.inbound_process
      );
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_INBOUND_CONTAINER_ELIGIBILITY);
      onOpenToast();
    } else {
      const updateList: IInboundContainerEligibility[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updateInboundContainerEligibility(
            selectedNode,
            finalList
          );
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          const newEditIbceList = [...ibceList];
          setEditFormData(newFormData);
          const index = newEditIbceList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          newEditIbceList[index] = newFormData;
          setIbceList(newEditIbceList);
          setEditIbceId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (ibce) => {
    setEditIbceId(ibce.id);
    const formValues: IInboundContainerEligibility = {
      id: ibce.id,
      container: ibce.container,
      size: ibce.size,
      inbound_process: ibce.inbound_process,
      value: ibce.value,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditIbceId(null);
  };
  /**
   * To handle the delete button
   * @param ibce The Inbound Container Eligibility object to be deleted
   */
  const handleDeleteClick = (ibce) => {
    const idsList: IId[] = [];
    const finalIds: IId[] = [ibce, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deleteInboundContainerEligibility(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const newIbceList = [...ibceList];
        const index = newIbceList.findIndex((obj) => {
          return obj.id === ibce.id;
        });
        newIbceList.splice(index, 1);
        setIbceList(newIbceList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${ibce}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  //Get list of unique sizes, inbound-processes, containers
  let sizes = InboundContainerEligibilityList.map((a) => a.size);
  let uniqueSizes = [...new Set(sizes)];
  let inboundProcesses = InboundContainerEligibilityList.map(
    (inboundCEItem) => inboundCEItem.inbound_process
  );
  let uniqueInboundProcesses = [...new Set(inboundProcesses)];
  let containers = InboundContainerEligibilityList.map(
    (inboundCEItem) => inboundCEItem.container
  );
  let uniqueContainers = [...new Set(containers)];

  InboundContainerEligibilityList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <InboundConEligAddForm
              uniqueInboundProcesses={uniqueInboundProcesses}
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              uniqueSizes={uniqueSizes}
              uniqueContainers={uniqueContainers}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Search by Container"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={INBOUND_CONTAINER_HEADERS.CONTAINER} width={150}>{CONTAINER_LABEL}</TableCell>
            <TableCell sortColumn={INBOUND_CONTAINER_HEADERS.SIZE} width={150}>{SIZE_LABEL}</TableCell>
            <TableCell sortColumn={INBOUND_CONTAINER_HEADERS.INBOUND_PROCESS} width={200}>{INBOUND_PROCESS_LABEL}</TableCell>
            <TableCell sortColumn={INBOUND_CONTAINER_HEADERS.VALUE} width={90}>{VALUE_LABEL}</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </Table>
        {ibceList
          .filter((ibce) => {
            if (searchTerm == "") {
              return ibce;
            } else if (
              ibce.container.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return ibce;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((ibce) => (
            <div key={ibce.id}>
              {editIbceId === ibce.id ? (
                <EditableIbceRow
                  ibce={ibce}
                  selectedNode={selectedNode}
                  handleEditFormChange={handleEditFormChange}
                  handleCancelClick={handleCancelClick}
                  uniqueSizes={uniqueSizes}
                  uniqueInboundProcesses={uniqueInboundProcesses}
                  uniqueContainers={uniqueContainers}
                />
              ) : (
                <ReadOnlyIbceRow
                  ibce={ibce}
                  selectedNode={selectedNode}
                  handleEditClick={handleEditClick}
                  handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default InboundConEligTable;
