import React, {useState} from "react";
import Table, {TableCell, TableRow} from "@amzn/meridian/table";
import Row from "@amzn/meridian/row";
import Input from "@amzn/meridian/input";
import Button from "@amzn/meridian/button";
import Select, {SelectOption} from "@amzn/meridian/select";
import {IProcessPathRates} from "../../common/api/api";

function EditablePPRatesRow({
                                ppr,
                                selectedNode,
                                handleEditFormChange,
                                handleCancelClick,
                                uniqueSizes,
                                uniqueRoles,
                                uniqueProcessPaths
                            }) {
    const [process_path, setProcess_path] = useState(ppr.process_path);
    const [role, setRole] = useState(ppr.role);
    const [size, setSize] = useState(ppr.size);
    const [rate, setRate] = useState(ppr.rate);
    /**
     * To generate a unique id for the list
     * @param id
     * @param text1
     * @param text2
     */
    const getUniqueKey = (id, text1, text2) => {
        return `${id}-${text1}-${text2}`;
    };
    /**
     * Function to handle submit button
     */
    const saveHandler = () => {
        const editData: IProcessPathRates = {
            id: ppr.id,
            process_path: process_path,
            size: size,
            role: role,
            rate: parseInt(rate),
        };
        handleEditFormChange(editData);
    };
    return (
        <Table spacing="small">
            <TableRow key={ppr.id}>
                <TableCell
                    key={getUniqueKey(ppr.id, selectedNode, "selectedNode")}
                    width={100}
                >
                    <Input id="selectedNode" value={selectedNode}/>
                </TableCell>

                <TableCell width={200}>
                    <Select value={process_path} onChange={setProcess_path}>
                        {uniqueProcessPaths.map((process_path) => (
                            <SelectOption
                                key={getUniqueKey(ppr.id, process_path, "process_path")}
                                value={process_path}
                                label={process_path}
                            />
                        ))}
                    </Select>
                </TableCell>

                <TableCell width={150}>
                    <Select value={size} onChange={setSize}>
                        {uniqueSizes.map((size) => (
                            <SelectOption
                                key={getUniqueKey(ppr.id, size, "size")}
                                value={size}
                                label={size}
                            />
                        ))}
                    </Select>
                </TableCell>

                <TableCell width={150}>
                    <Select value={role} onChange={setRole}>
                        {uniqueRoles.map((role) => (
                            <SelectOption
                                key={getUniqueKey(ppr.id, role, "role")}
                                value={role}
                                label={role}
                            />
                        ))}
                    </Select>
                </TableCell>

                <TableCell key={getUniqueKey(ppr.id, rate, "rate")} width={100}>
                    <Input id="rate" type="number" value={rate} onChange={setRate}/>
                </TableCell>

                <TableCell>
                    <Row>
                        <Button minWidth={70} onClick={saveHandler}>
                            Save
                        </Button>
                        <Button minWidth={70} onClick={handleCancelClick}>
                            Cancel
                        </Button>
                    </Row>
                </TableCell>
            </TableRow>
        </Table>
    );
}

export default EditablePPRatesRow;
