import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import {IPkgSplitsContainerType} from "../../common/api/api";
import CreatableSelect from "react-select/creatable";
import {nanoid} from "nanoid";

function PkgSplitsContainerTypesAddForm({
                               addFormHandler,
                               uniqueContainerTypes,
                               cancelHandler,
                               uniqueSizes,
                               selectedNode
                           }) {
    const [containerType, setContainerType] = useState("");
    const [size, setSize] = useState("");
    const [value, setValue] = useState("");

    function submitHandler(event) {
        event.preventDefault();
        if (!containerType || !size || !value) {
            setErrorMessage("Fill all the fields before submitting!");
        } else {
            const addData: IPkgSplitsContainerType = {
                id: nanoid(),
                container_type: containerType['label'],
                size: size['label'],
                value: parseFloat(value),
            };
            addFormHandler(addData);
        }
    }

    //Convert processPath to hold value and label
    uniqueContainerTypes = uniqueContainerTypes.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert size to hold value and label
    uniqueSizes = uniqueSizes.map((str) => ({
        value: str,
        label: str,
    }));
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Forest" : "Forest",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };
    //Error checking
    const [errorMessage, setErrorMessage] = useState("");
    return (
        <>
            <form onSubmit={submitHandler}>
                <Column>
                    <Heading level={4}>Add New Pkg Split Container Type</Heading>
                    <div style={{width: "300px", height: "40px"}}>
                        <CreatableSelect
                            isClearable
                            onChange={setContainerType}
                            options={uniqueContainerTypes}
                            value={containerType}
                            styles={customStyles}
                            placeholder="Container Type"
                        />
                    </div>
                    <div style={{width: "200px", height: "40px"}}>
                        <CreatableSelect
                            isClearable
                            onChange={setSize}
                            options={uniqueSizes}
                            value={size}
                            styles={customStyles}
                            placeholder="Size"
                        />
                    </div>
                    <Input
                        name="value"
                        value={value}
                        label="Value"
                        type="number"
                        onChange={setValue}
                        width={100}
                        size="small"
                    />
                    <Box>
                        <Row>
                            <Button submit={true}>Add</Button>
                            <Button onClick={cancelHandler}>Cancel</Button>
                            {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                        </Row>
                    </Box>
                    <Row></Row>
                </Column>

            </form>
        </>
    );
}

export default PkgSplitsContainerTypesAddForm;
