import {createContext, useContext, useState} from "react";

/**
 * useContext hook to access sort center id everywhere
 */
interface nodeStateProps {
    selectedNode: string;

    changeSelectedNode(nodeId: string): any;
}

export const initialState: nodeStateProps = {
    selectedNode: "",
    changeSelectedNode: () => {
    },
};

const NodeContext = createContext(initialState);

export const NodeProvider = ({children}) => {
    const [selectedNode, changeSelectedNode] = useState(
        initialState.selectedNode
    );

    return (
        <NodeContext.Provider value={{selectedNode, changeSelectedNode}}>
            {children}
        </NodeContext.Provider>
    );
};
const useSelectedNode = () => {
    const context = useContext(NodeContext);

    if (context === undefined) {
        throw new Error("useSelectedNode must be used within a ThemeProvider");
    }
    return context;
};

export default useSelectedNode;
