import FlowChart from "./FlowChart";
import useSelectedNode from "../../../components/hooks/nodeContext";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AmpApi from "../../../common/api/AmpApi";
import { IProcessPathRelationship } from "../../../common/api/api";
import Loader from "@amzn/meridian/loader";
import { Redirect } from "react-router";

const Flow = () => {
  const { selectedNode, changeSelectedNode } = useSelectedNode();
  const [isLoading, setIsLoading] = useState(false);
  const [ppRelList, setPPRelList] = useState([]);
  const { nodeId } = useParams<{ nodeId: string }>();

  let nodeKey;

  if (nodeId) {
    nodeKey = nodeId;
  } else {
    nodeKey = { selectedNode };
  }

  useEffect(() => {
    setIsLoading(true);
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
    (async () => {
      try {
        const getResponse = await AmpApi.getProcessPathRelationships(nodeKey);
        if (getResponse) {
          const processPathRels: IProcessPathRelationship[] = getResponse.data
            .prRelationships as IProcessPathRelationship[];
          const finalList = [...processPathRels];
          setPPRelList(finalList);
        }
      } catch (ex: any) {
        console.error(`Error fetching ${selectedNode}`, ex);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);
  return (
      nodeId != selectedNode ? (
      <Redirect to="/"/>
      ) : (
          <>{isLoading ? <Loader /> : <FlowChart ppRelList={ppRelList} />}</>
      )
  );
};

export default Flow;
