import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import useSelectedNode from "../../components/hooks/nodeContext";
import {useEffect, useState} from "react";
import AmpApi from "../../common/api/AmpApi";
import {IAmpModelRunInfo} from "../../common/api/api";
import Loader from "@amzn/meridian/loader";
import DownloadAMPOutputTable from "./DownloadAMPOutputTable";
import {useParams} from "react-router-dom";
import Box from "@amzn/meridian/box";
import { Redirect } from "react-router";

function DownloadAMPOutput() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const [ampModelRunList, setAmpModelRunList] = useState({});
    //For the loading sign for api calls
    const [isLoading, setIsLoading] = useState(true);
    const {nodeId} = useParams<{ nodeId: string }>();
    console.log(nodeId);
    let nodeKey;
    if (nodeId) {
        nodeKey = nodeId;
    } else {
        nodeKey = {selectedNode};
    }

    function creationTimeEpochComparator(a, b) {
        return parseInt(b.creationTimeEpoch, 10) - parseInt(a.creationTimeEpoch, 10);
    }

    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
        setIsLoading(true);
        (async () => {
            try {
                const getAmpModelRunInfosResponse = await AmpApi.getAmpModelRunInfos(nodeKey);
                console.log(getAmpModelRunInfosResponse.data);
                if (getAmpModelRunInfosResponse) {
                    const ampModelRuns: IAmpModelRunInfo[] = getAmpModelRunInfosResponse.data
                        .ampModelRunInfo as IAmpModelRunInfo[];
                    const finalList = [...ampModelRuns];
                    finalList.sort(creationTimeEpochComparator)
                    setAmpModelRunList(finalList);
                }
            } catch (ex: any) {
                console.error(`Error fetching AMP model run info for node ${nodeKey}`, ex);
            } finally {
                setIsLoading(false);
            }
        })();
    }, []);

    return (
        nodeId != selectedNode ? (
            <Redirect to="/"/>
        ) : (
            <div>
                <Card width="fill" spacingInset="none">
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <>
                            <Column spacingInset="400 500">
                                <Box type="outline" spacingInset="400">
                                    <DownloadAMPOutputTable
                                        ampModelRunList={ampModelRunList}
                                        selectedNode={nodeKey}
                                    />
                                </Box>
                            </Column>
                        </>
                    )}
                </Card>
            </div>
        )
    );
}

export default DownloadAMPOutput;
