import ConfigNewSiteForm from "./ConfigNewSiteForm";
import Card from "@amzn/meridian/card";
import Column from "@amzn/meridian/column";
import Divider from "@amzn/meridian/divider";
import useSelectedNode from "../../components/hooks/nodeContext";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useNodeList from "../../components/hooks/NodeListContext";

function ConfigNewSite() {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const {nodeList} = useNodeList();
    const {nodeId} = useParams<{ nodeId: string }>();

    //useEffect to update the nodeId
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
    }, []);
    return (
        <>
            <Card width="fill" spacingInset="none">
                <Divider/>
                <Column spacingInset="400 500">
                    <ConfigNewSiteForm uniqueNodeList={nodeList}/>
                </Column>
            </Card>
        </>
    );
}

export default ConfigNewSite;
