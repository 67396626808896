import React, {useCallback, useEffect, useState} from "react";
import FileInput, { FileDetails } from "@amzn/meridian/file-input";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import AmpApi from "../../common/api/AmpApi";
import Heading from "@amzn/meridian/heading";
import Submitmodal from "../../components/modal/SubmitModal";
import {
  ACCESS_DENIED_MESSAGE, PLEASE_WAIT_STATUS,
  UPLOAD_FAIL_STATUS,
  UPLOAD_SUCCESS_STATUS, USER_ACCESS_KEY
} from "../../constants";
import CreatableSelect from "react-select/creatable";
import Link from "@amzn/meridian/link";
import useSelectedNode from "../../components/hooks/nodeContext";
import {useParams} from "react-router-dom";
import {IId} from "../../common/api/api";
import useNodeList from "../../components/hooks/NodeListContext";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import Loader from "@amzn/meridian/loader";

export default function ConfigNewSiteForm({uniqueNodeList}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [newNodeId, setNewNodeId] = useState("");
  const [ready, setReady] = useState(false);
  const [files, setFiles] = useState([]);
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  const { selectedNode, changeSelectedNode } = useSelectedNode();
  const {nodeId} = useParams<{ nodeId: string }>();
  const {nodeList,setNodeList} = useNodeList();
//useEffect to update the nodeId
  //Access Denied Toast
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
      (id) => setToasts(toasts.filter((t) => t.id !== id)),
      [toasts]
  );
  const onOpenToast = useCallback(
      () => setToasts(toasts.concat({ id: `${++dummyId}` })),
      [toasts]
  );

  useEffect(() => {
    if (nodeId !== selectedNode) {
      changeSelectedNode(nodeId);
    }
  }, []);
  function submitHandler(event) {
    event.preventDefault();
    setLoading(true);
    (async () => {
      try {
        await AmpApi.configNewSite(newNodeId['value'], selectedFile);
        setLoading(false);
        setOpenModal(true);
        setResponseMessage(UPLOAD_SUCCESS_STATUS);
        let newId = JSON.parse(JSON.stringify({
          id: newNodeId['value']
        }));
        const checkId = obj => obj.id === newNodeId['value'];
        if (nodeList.some(checkId)) {
          console.log("Updating existing sort center");
        } else {
          console.log("Entry does not exist");
          let finalList: IId[] = [newId, ...nodeList];
          setNodeList(finalList);
        }
    } catch (error) {
        setLoading(false);
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        }
        else {
          console.error(`Error uploading file`, error);
          setOpenModal(true);
          setResponseMessage(UPLOAD_FAIL_STATUS);
        }
      }
    })();
  }
//Convert processPath to hold value and label
  uniqueNodeList = uniqueNodeList.map((str) => ({
    value: str.id,
    label: str.id,
  }));
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted green",
      padding: 10,
      "&:hover": {borderColor: "green"}, // border style on hover
      boxShadow: "none", // no box-shadow
    }),
  };

  const sampleFileDownloadHandler = () => {
      (async () => {
        try {
          let folder = "sample_input";
          let fileName = "Sample_input.xlsx";
          const downloadSampleFileResponse =
              await AmpApi.downloadAmpModelOutput(folder, fileName);
          console.log(downloadSampleFileResponse);
          if (downloadSampleFileResponse) {
            window.open(downloadSampleFileResponse.data.downloadUrl, "_self")
          }
        } catch (ex: any) {
          console.error(`Error Downloading Sample File`, ex);
        }
      })();
    };

  return (
    <React.Fragment>
      <Submitmodal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {errorMessage && (
          <Toaster toasts={toasts} onCloseToast={onCloseToast}>
            {(toast) => (
                <Alert toast={true} onClose={toast.onClose} type="error">
                  {errorMessage}
                </Alert>
            )}
          </Toaster>
      )}
      {loading ? (
          <Loader />
      ) : (
          <form onSubmit={submitHandler}>
            <Column alignmentHorizontal={"center"}>
              <Heading level={5} type="h300">
                Please enter new Sort Center Configuration
              </Heading>
              <label id="disclaimer" className="nodeIdColor">* Only North American Sort Centers are allowed to be entered by design. This tool is not approved to support other regions such as EU due to data privacy.</label>
              <Row></Row>
              <Row><Column>
              <Row><Link onClick={sampleFileDownloadHandler}>Click here to download sample excel sheet</Link></Row>
              <Row></Row>
              <Row>
              <div style={{width: "200px", height: "40px"}}>
                <CreatableSelect
                    isClearable
                    onChange={setNewNodeId}
                    options={uniqueNodeList}
                    value={newNodeId}
                    styles={customStyles}
                    placeholder="Sort Center"
                />
              </div>
              </Row>
              <Row></Row>
              <FileInput
                type={"single"}
                onFileAttached={(acceptedFiles) => {
                  acceptedFiles.forEach((file) => {
                    console.log("File", file);
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
                    fileReader.onload = function () {
                      const arrayBuffer: any = fileReader.result;
                      setSelectedFile(arrayBuffer);
                      setReady(true);
                    };
                    file.percentage = 100;
                  });
                  setFiles([...files, ...acceptedFiles]);
                }}
                uploadButtonSize="large"
                uploadButtonLabel={"Browse"}
                showDropZone={true}
                dropZoneLabel="Drag and drop, or click to browse"
                dropZoneDirection="column"
                uploadButtonType={"link"}
              >
                {files.map((file) => {
                  return (
                    <FileDetails
                      error={file.error}
                      errorMessage={file.errorMessage}
                      file={file}
                      connectionSpeed="5MB/s"
                      uploadPercentage={file.percentage}
                      key={file.name}
                      cancelUploadButtonLabel={`Cancel, ${file.name}`}
                      onClickCancel={() => {
                        file.cancel();
                        const updatedFileArr = files.filter(
                          (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                      }}
                      removeFileButtonLabel={`Delete, ${file.name}`}
                      onClickRemoveFile={() => {
                        const updatedFileArr = files.filter(
                          (fl) => fl.name !== file.name
                        );
                        setFiles(updatedFileArr);
                      }}
                      uploadComplete={file.percentage === 100}
                    />
                  );
                })}
              </FileInput>
              <Row></Row>
              <Row alignmentHorizontal={"center"}>
                <Button disabled={!files[0]} submit={ready}>
                  Submit
                </Button>
              </Row>
              </Column></Row>
            </Column>
          </form>
      )}
    </React.Fragment>
  );
}
