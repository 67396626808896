import AmpException, { AmpExceptionStruct } from "./AmpException";

/**
 * Named AmpException Error to indicate a $FAULT of 'client' in a smithy Error response
 * from an Api Gateway request.
 * See: https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/
 *      client-api-gateway/classes/apigatewayserviceexception.html
 * @extends AmpException
 */
class AmpClientException extends AmpException {
  constructor(formattedError: AmpExceptionStruct) {
    super(formattedError);

    Object.setPrototypeOf(this, AmpClientException.prototype);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AmpClientException);
    }

    this.fault = formattedError.fault || "client";
    this.name = "AmpClientException";
  }
}

export default AmpClientException;
