//ATSQUAD wiki link
export const ATSQUAD_WIKI_LINK = "https://w.amazon.com/bin/view/NASC/ATSquad";
//Marquee Welcome Message
export const WELCOME_MESSAGE = "Select a sort center to view it's configurations";
export const SORT_CENTER_MESSAGE = "You are currently updating configurations for sort center: ";
//Placeholder for the node dropdown
export const NODE_ID_DROPDOWN_PLACEHOLDER = "-Sort Center-";

//LocalStorage keys
export const SIDE_MENU_HASH_KEY = "sideMenuHash";
export const SIDE_MENU_OPEN_HASH_KEY = "sideMenuOpenHash";
export const NODE_LIST_KEY = "nodeList";
export const USERNAME_KEY = "username";
export const USER_ACCESS_KEY = "userAccess";
//Modal Messages
export const UPDATE_SUCCESS_STATUS = "Update Successful";
export const UPDATE_FAIL_STATUS = "Update failed";
export const ADD_SUCCESS_STATUS = "Record added successfully";
export const ADD_FAIL_STATUS = "Add record failed";
export const DELETE_SUCCESS_STATUS = "Record deleted successfully";
export const DELETE_FAIL_STATUS = "Delete record failed";
export const UPLOAD_SUCCESS_STATUS = "File uploaded successfully";
export const UPLOAD_FAIL_STATUS = "File upload failed. Check input file against sample file for correctness " +
    "and try again. If issue persist please contact NASC Tech.";
export const DOWNLOAD_FAIL_STATUS = "File Download failed";
export const PLEASE_WAIT_STATUS = "Processing...";
export const RUN_AMP_MODEL_SUCCESS_STATUS = "Model Run Started Successfully";
export const RUN_AMP_MODEL_FAIL_STATUS = "Model Run Failed";

//Duplicate entries error messages
export const DUPLICATE_MHE_CONFIG = "Process Path entry already exists.";
export const DUPLICATE_PP_RELATIONSHIP = "The process path relationship already exists.";
export const DUPLICATE_PP_CAPACITY = "The process path with that size already exists.";
export const DUPLICATE_SHIFTS_CONFIG = "Entry for that shift type already exists";
export const DUPLICATE_PP_RATES = "Entry for the process path with that size and role already exists";
export const DUPLICATE_PP_HEADCOUNT = "Entry for the process path with the role and shift already exists";
export const DUPLICATE_INBOUND_CONTAINER_ELIGIBILITY = "The inbound process with the container type and size already exists.";
export const DUPLICATE_LOCATION_SIZE_ADMISSIBILITY = "The location with this size and admissibility already exists.";
export const DUPLICATE_VOL_SPLIT_PER_LOCATION = "The location with this value already exists.";
export const DUPLICATE_PKG_SPLITS_CONTAINER_TYPES = "The Container type with this size and value already exists.";

//Authorization error messages
export const ACCESS_DENIED_MESSAGE = "You are not Authorized to perform the operation. Please refer bindle: https://bindles.amazon.com/resource/Bindle/Lock/AMPAdmin"
//Blank fields error message
export const BLANK_FIELDS_ERROR = "Fill all the fields before submitting!";

//Side Menu Link Labels
export const HOME_LINK_LABEL = "Home";
export const MHE_CONFIG_LINK_LABEL = "MHE_Config";
export const PP_REL_LINK_LABEL = "Process Path Relationship";
export const PP_RATES_LINK_LABEL = "Process Path Rates";
export const PP_CAPACITY_LINK_LABEL = "Process Path Capacity";
export const SHIFTS_CONFIG_LINK_LABEL = "Shifts_Config";
export const PP_HEADCOUNT_LINK_LABEL = "Process Path Headcount";
export const ICE_LINK_LABEL = "Inbound Container Eligibility";
export const PKG_SPLITS_CONTAINER_TYPES_LINK_LABEL = "Pkg Splits Container Types";
export const LOCATION_SIZE_ADMISSIBILITY_LINK_LABEL = "Location Size Admissibility";
export const VOL_SPLIT_PER_LOCATION_LINK_LABEL = "Vol Split Per Location";
export const DOWNLOAD_LINK_LABEL = "Download Configuration";
export const RUN_AMP_LINK_LABEL = "Run AMP Model";
export const DOWNLOAD_AMP_OUTPUT_LINK_LABEL = "View AMP Output";
export const CONFIG_LINK_LABEL = "Upload Configuration";
export const FLOW_CHART_LABEL = "Flow Chart";
export const PP_REL_TABLE_LABEL = "Table";
export const MANAGE_INPUTS_LABEL = "Manage Inputs"
// All Table Headers
export const ITEMS_PER_PAGE = 25;
export const SORT_CENTER_LABEL = "Sort Center";
export const PROCESS_PATH_LABEL = "Process Path";
export const QUANTITY_LABEL = "Quantity";
export const IS_INBOUND_LABEL = "Is Inbound?";
export const ROLE_LABEL = "Role";
export const SIZE_LABEL = "Size";
export const RATE_LABEL = "Rate";
export const CAPACITY_LABEL = "Capacity";
export const SHIFT_LABEL = "Shift";
export const MIN_HC_LABEL = "Min HC";
export const MAX_HC_LABEL = "Max HC";
export const MIN_LENGTH_LABEL = "Min Length";
export const MAX_LENGTH_LABEL = "Max Length";
export const ROLE_TYPE_LABEL = "Role Type";
export const INBOUND_PROCESS_LABEL = "Inbound Process";
export const VALUE_LABEL = "Value";
export const CONTAINER_LABEL = "Container";
export const TO_LABEL = "To";
export const FROM_LABEL = "From";
export const RUN_ID_LABEL = "Run Id";
export const CREATION_DATE_TIME_LABEL = "Creation Time";
export const STATUS_LABEL = "Status";
export const DOWNLOAD_OUTPUT_OR_ERROR_FILE_LABEL = "Output/Error File";
export const CREATED_BY_LABEL = "Created By";
export const RUN_ARGUMENTS_LABEL = "Run Arguments";
export const LOCATION_LABEL = "Location"
export const IS_ADMISSIBLE_LABEL = "Is Admissible"
export const CONTAINER_TYPE_LABEL = "Container Type"
//Sort for DownloadAMPOutput
export const DEFAULT_SORT_COLUMN = "creationTimeEpoch";
export const DEFAULT_SORT_DIRECTION = "descending";

//Tab Headers
export const RUN_SUMMARY = "Run Summary";
export const VOLUME_MIX = "Volume Mix";
export const AVG_HC_ALLOCATION = "HC Allocation";
export const VOLUME_BETWEEN_PROCESS_PATHS = "Volume Per Process Paths";
export const WIP = "WIP";
// Amp Output File
export const AMP_OUTPUT_ZIP_FILE_NAME = "AMP_output.zip";
export const AMP_OUTPUT_ERROR_FILE_NAME = "error.json";
// Amp Output Status
export const STATUS = {
    STARTED: 'STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
    TIMED_OUT: "TIMED_OUT"
};
// MHE config Headers
export const MHECONFIG_HEADERS = {
    PROCESS_PATH: "processPath",
    QUANTITY: "quantity",
    IS_INBOUND: "isInbound"
};
// Process Path Relationship Headers
export const PPRELATIONSHIP_HEADERS = {
    FROM: "from",
    TO: "to"
};
// Process Path Rates Headers
export const PPRATES_HEADERS = {
    PROCESS_PATH: "process_path",
    SIZE: "size",
    ROLE: "role",
    RATE: "rate"
};
// Process Path Capacity Headers
export const PPCAPACITY_HEADERS = {
    PROCESS_PATH: "process_path",
    SIZE: "size",
    CAPACITY: "capacity"
};
// Shift Info Headers
export const SHIFTSINFO_HEADERS = {
    SHIFT: "shift",
    MIN_HC: "min_hc",
    MAX_HC: "max_hc",
    MIN_LENGTH: "min_length",
    MAX_LENGTH: "max_length"
};
// Shift Info Headers
export const PPHC_HEADERS = {
    PROCESS_PATH: "process_path",
    ROLE: "role",
    ROLE_TYPE: "role_type",
    SHIFT: "shift",
    MIN_HC: "min_hc",
    MAX_HC: "max_hc"
};
// Inbound Container Headers
export const INBOUND_CONTAINER_HEADERS = {
    CONTAINER: "container",
    SIZE: "size",
    INBOUND_PROCESS: "inbound_process",
    VALUE: "value"
};
// Shift Info Headers
export const LOCATION_SIZE_ADMISSIBILITY_HEADERS = {
    LOCATION: "location",
    SIZE: "size",
    IS_ADMISSIBLE: "is_admissible"
};
// Shift Info Headers
export const VOL_SPLIT_PER_LOCATION_HEADERS = {
    LOCATION: "location",
    VALUE: "value"
};
// PKG Split Container Type Headers
export const PKG_SPLITS_CONTAINER_TYPES_HEADERS = {
    CONTAINER_TYPE: "container_type",
    SIZE: "size",
    VALUE: "value"
};
