import { useCallback, useState } from "react";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DEFAULT_SORT_DIRECTION,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_MHE_CONFIG,
  IS_INBOUND_LABEL,
  ITEMS_PER_PAGE,
  MHECONFIG_HEADERS,
  PLEASE_WAIT_STATUS,
  PROCESS_PATH_LABEL,
  QUANTITY_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
} from "../../constants";
import Button from "@amzn/meridian/button";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import MHEConfigAddForm from "./MHEConfigAddForm";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import AmpApi from "../../common/api/AmpApi";
import { IId, IMheConfig } from "../../common/api/api";
import SubmitModal from "../../components/modal/SubmitModal";
import Input from "@amzn/meridian/input";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Loader from "@amzn/meridian/loader";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import AmpClientException from "../../common/errors/AmpClientException";
import {TableSortDirection} from "@amzn/meridian/table/table";

function MHEConfigTable({ mheConfigList, selectedNode }) {
  //useState for the mhe list
  const [mheList, setMheList] = useState(mheConfigList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState<any>();
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(mheList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(MHECONFIG_HEADERS.PROCESS_PATH)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])

  //initial form data
  const initialForm: IMheConfig = {
    id: "",
    isInbound: 0,
    quantity: 0,
    processPath: "",
  };

  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const [addFormData, setAddFormData] = useState(initialForm);

  /**
   * This function adds the new MHE_Config
   * @param addData
   */
  function addFormHandler(addData) {
    //check if the unique values already exist in the list
    if (mheList.find((e) => e.processPath === addData.processPath)) {
      setErrorMessage(DUPLICATE_MHE_CONFIG);
      onOpenToast();
    } else {
      const addList: IMheConfig[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updateMheConfig(selectedNode, finalList);
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setMheList([addData, ...mheList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editMheId, setEditMheId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialForm);

  /**
   * This function edits the MHE_Config record
   * @param newFormData Object containing the edited data
   */
  function handleEditFormChange(newFormData) {
    //check if the unique values already exist in the list
    let result = mheList.filter(function (v, i) {
      return v.processPath == newFormData.processPath;
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_MHE_CONFIG);
      onOpenToast();
    } else {
      const newEditMHEList = [...mheList];
      setEditFormData(newFormData);
      const index = newEditMHEList.findIndex((obj) => {
        return obj.id === newFormData.id;
      });
      newEditMHEList[index] = newFormData;
      const updateList: IMheConfig[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updateMheConfig(selectedNode, finalList);
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          setMheList(newEditMHEList);
          setEditMheId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (mhe) => {
    setEditMheId(mhe.id);
    const formValues: IMheConfig = {
      id: mhe.id,
      isInbound: mhe.isInbound,
      quantity: mhe.quantity,
      processPath: mhe.processPath,
    };
    setEditFormData(formValues);
  };

  /**
   * Delete handler with the api to delete MHE_Config record
   * @param mheId MHE_Config id
   */
  const handleDeleteClick = (mheId) => {
    const newMHEList = [...mheList];
    const idsList: IId[] = [];
    const finalIds: IId[] = [mheId, ...idsList];
    const index = newMHEList.findIndex((obj) => {
      return obj.id === mheId.id;
    });
    newMHEList.splice(index, 1);
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deleteMheConfig(
          selectedNode,
          finalIds
        );
        setResponseMessage(DELETE_SUCCESS_STATUS);
        setMheList(newMHEList);
      } catch (error) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        }
        else {
          console.error(`Error deleting ${mheId}`, error);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditMheId(null);
  };

  //To get a list of unique ProcessPath names
  let processPathsOnly = mheList.map((a) => a.processPath);
  let uniqueProcessPaths = [...new Set(processPathsOnly)];

  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );

  mheList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <MHEConfigAddForm
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              uniqueProcessPaths={uniqueProcessPaths}
              selectedNode={selectedNode}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Filter by Process Path"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={MHECONFIG_HEADERS.PROCESS_PATH} width={300}>{PROCESS_PATH_LABEL}</TableCell>
            <TableCell sortColumn={MHECONFIG_HEADERS.QUANTITY} width={100}>{QUANTITY_LABEL}</TableCell>
            <TableCell sortColumn={MHECONFIG_HEADERS.IS_INBOUND} width={100}>{IS_INBOUND_LABEL}</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </Table>
        <form>
          {mheList
            .filter((mhe) => {
              if (searchTerm == "") {
                return mhe;
              } else if (
                mhe.processPath.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                return mhe;
              }
            })
            .slice(firstVisibleIndex, lastVisibleIndex)
            .map((mhe) => (
              <div key={mhe.id}>
                {editMheId === mhe.id ? (
                  <EditableRow
                    mhe={mhe}
                    selectedNode={selectedNode}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                    uniqueProcessPaths={uniqueProcessPaths}
                  />
                ) : (
                  <ReadOnlyRow
                    mhe={mhe}
                    selectedNode={selectedNode}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                  />
                )}
              </div>
            ))}
        </form>
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default MHEConfigTable;
