import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import {nanoid} from "nanoid";
import CreatableSelect from "react-select/creatable";
import {ILocationSizeAdmissibility} from "../../common/api/api";

function LocationSizeAdmissibilityAddForm({uniqueLocations, uniqueSizes, addFormHandler, cancelHandler}) {
    const [location, setLocation] = useState("");
    const [size, setSize] = useState("");
    const [is_admissible, setIs_admissible] = useState("");

    //Error checking
    const [errorMessage, setErrorMessage] = useState("");
    //Convert container to hold value and label
    uniqueLocations = uniqueLocations.map((str) => ({
        value: str,
        label: str,
    }));
    //Convert size to hold value and label
    uniqueSizes = uniqueSizes.map((str) => ({
        value: str,
        label: str,
    }));
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: "1px dotted green",
            color: state.isFocused ? "Black" : "Navy",
            padding: 10,
            "&:hover": {borderColor: "green"}, // border style on hover
            boxShadow: "none", // no box-shadow
        }),
    };

    //Submit Handler
    function submitHandler(event) {
        event.preventDefault();
        if (!location || !size || !is_admissible) {
            setErrorMessage("Fill all the fields before submitting!");
        } else if (parseInt(is_admissible) < 0 || parseInt(is_admissible) > 1) {
            setErrorMessage("Is admissible value can only be 0 or 1!");
        } else {
            const addData: ILocationSizeAdmissibility = {
                id: nanoid(),
                location: location['label'],
                size: size['label'],
                is_admissible: parseInt(is_admissible),
            };
            addFormHandler(addData);
        }
    }

    return (
        <>
            <Column>
                <form onSubmit={submitHandler}>
                    <Column>
                        <Heading level={4}>Add New Location Size Admissibility</Heading>
                        <Row height={50}>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setLocation}
                                    options={uniqueLocations}
                                    value={location}
                                    styles={customStyles}
                                    placeholder="Location"
                                />
                            </div>
                            <div style={{width: "200px", height: "40px"}}>
                                <CreatableSelect
                                    isClearable
                                    onChange={setSize}
                                    options={uniqueSizes}
                                    value={size}
                                    styles={customStyles}
                                    placeholder="Size"
                                />
                            </div>
                            <Input
                                name="is_admissible"
                                value={is_admissible}
                                placeholder={"Is Admissible"}
                                type="number"
                                onChange={setIs_admissible}
                            />
                        </Row>
                        <Box>
                            <Row>
                                <Button submit={true}>Add</Button>
                                <Button onClick={cancelHandler}>Cancel</Button>
                                {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                            </Row>
                        </Box>
                        <Row></Row>
                    </Column>
                </form>
            </Column>
        </>
    );
}

export default LocationSizeAdmissibilityAddForm;
