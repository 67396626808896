import { useCallback, useState } from "react";
import Table, {
  TableActionBar,
  TableCell,
  TableRow,
} from "@amzn/meridian/table";
import {
  ACCESS_DENIED_MESSAGE,
  ADD_FAIL_STATUS,
  ADD_SUCCESS_STATUS,
  DELETE_FAIL_STATUS,
  DELETE_SUCCESS_STATUS,
  DUPLICATE_VOL_SPLIT_PER_LOCATION,
  ITEMS_PER_PAGE,
  PLEASE_WAIT_STATUS,
  LOCATION_LABEL,
  VALUE_LABEL,
  SORT_CENTER_LABEL,
  UPDATE_FAIL_STATUS,
  UPDATE_SUCCESS_STATUS,
  USER_ACCESS_KEY,
  DEFAULT_SORT_DIRECTION,
  VOL_SPLIT_PER_LOCATION_HEADERS,
} from "../../constants";
import Button from "@amzn/meridian/button";
import Pagination from "@amzn/meridian/pagination";
import Column from "@amzn/meridian/column";
import VolSplitPerLocationAddForm from "./VolSplitPerLocationAddForm";
import ReadOnlyVolSplitPerLocationRow from "./ReadOnlyVolSplitPerLocationRow";
import EditableVolSplitPerLocationRow from "./EditableVolSplitPerLocationRow";
import { IId, IVolSplitPerLocation } from "../../common/api/api";
import AmpApi from "../../common/api/AmpApi";
import SubmitModal from "../../components/modal/SubmitModal";
import Loader from "@amzn/meridian/loader";
import filterTokenIcon from "@amzn/meridian-tokens/base/icon/filter";
import Input from "@amzn/meridian/input";
import Toaster from "@amzn/meridian/toaster";
import Alert from "@amzn/meridian/alert";
import {TableSortDirection} from "@amzn/meridian/table/table";

function VolSplitPerLocationTable({
  selectedNode,
  VolSplitPerLocationList,
}) {
  //useState for the list
  const [volSplitPerLocationList, setVolSplitPerLocationList] = useState(VolSplitPerLocationList);
  //useState for filtering table
  const [searchTerm, setSearchTerm] = useState("");
  //For the modal to display message
  const [openModal, setOpenModal] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const onConfirmClose = useCallback(() => setOpenModal(false), []);
  //For the loading sign for api calls
  const [loading, setLoading] = useState(false);
  //For pagination
  const itemsPerPage = ITEMS_PER_PAGE;
  const numberOfPages = Math.ceil(volSplitPerLocationList.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const firstVisibleIndex = (currentPage - 1) * itemsPerPage;
  const lastVisibleIndex = firstVisibleIndex + itemsPerPage;
  //Pagination
  //For sorting table columns
  const [sortColumn, setSortColumn] = useState(VOL_SPLIT_PER_LOCATION_HEADERS.LOCATION)
  const [sortDirection, setSortDirection] = useState<TableSortDirection>(DEFAULT_SORT_DIRECTION);
  const onSort = useCallback(({sortColumn, sortDirection}) => {
    setSortDirection(sortDirection)
    setSortColumn(sortColumn)
  }, [])
  //For duplicate records error
  let dummyId = 0;
  const [errorMessage, setErrorMessage] = useState("");
  const [toasts, setToasts] = useState([]);
  const onCloseToast = useCallback(
    (id) => setToasts(toasts.filter((t) => t.id !== id)),
    [toasts]
  );
  const onOpenToast = useCallback(
    () => setToasts(toasts.concat({ id: `${++dummyId}` })),
    [toasts]
  );
  //For Add Form
  const [isShown, setIsShown] = useState(false);
  const displayAddForm = () => {
    setIsShown((current) => !current);
  };
  const initialState: IVolSplitPerLocation = {
    id: "",
    location: "",
    value: 0.0,
  };
  const [addFormData, setAddFormData] = useState(initialState);

  /**
   * This function adds new Inbound Container Eligibility record
   * @param addData The new Inbound Container Eligibility to be added.
   */
  function addFormHandler(addData) {
    let result = volSplitPerLocationList.filter(function (volSplitPerLocationItem) {
      return (
          volSplitPerLocationItem.location == addData.location &&
          volSplitPerLocationItem.value == addData.value
      );
    });
    if (result.length > 0) {
      setErrorMessage(DUPLICATE_VOL_SPLIT_PER_LOCATION);
      onOpenToast();
    } else {
      const addList: IVolSplitPerLocation[] = [];
      const finalList = [addData, ...addList];
      setLoading(true);
      //Making api call for add
      (async () => {
        try {
          await AmpApi.updateVolSplitPerLocation(
            selectedNode,
            finalList
          );
          setLoading(false);
          setOpenModal(true);
          setResponseMessage(ADD_SUCCESS_STATUS);
          const newFormData = { ...addData };
          setAddFormData(newFormData);
          setVolSplitPerLocationList([addData, ...volSplitPerLocationList]);
        } catch (ex: any) {
          setLoading(false);
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          }
          else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(ADD_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for add
    }
  }

  //To edit data
  const [editVolSplitPerLocationId, setEditVolSplitPerLocationId] = useState(null);
  const [editFormData, setEditFormData] = useState(initialState);

  /**
   * This function Updates the Location Size Admissibility
   * @param newFormData Object contains the updated data
   */
  function handleEditFormChange(newFormData) {
    let result = volSplitPerLocationList.filter(function (volSplitPerLocationItem) {
      return (
          volSplitPerLocationItem.location == newFormData.location &&
          volSplitPerLocationItem.value == newFormData.value
      );
    });
    if (result.length > 0 && result[0].id != newFormData.id) {
      setErrorMessage(DUPLICATE_VOL_SPLIT_PER_LOCATION);
      onOpenToast();
    } else {
      const updateList: IVolSplitPerLocation[] = [];
      const finalList = [newFormData, ...updateList];
      setOpenModal(true);
      setResponseMessage(PLEASE_WAIT_STATUS);
      //Making api call for update
      (async () => {
        try {
          await AmpApi.updateVolSplitPerLocation(
            selectedNode,
            finalList
          );
          setResponseMessage(UPDATE_SUCCESS_STATUS);
          const newEditVolSplitPerLocationList = [...volSplitPerLocationList];
          setEditFormData(newFormData);
          const index = newEditVolSplitPerLocationList.findIndex((obj) => {
            return obj.id === newFormData.id;
          });
          setVolSplitPerLocationList(newEditVolSplitPerLocationList);
          setEditVolSplitPerLocationId(null);
        } catch (ex: any) {
          if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
            setOpenModal(false);
            setErrorMessage(ACCESS_DENIED_MESSAGE);
            onOpenToast();
          } else {
            console.error(`Error updating ${selectedNode}`, ex);
            setOpenModal(true);
            setResponseMessage(UPDATE_FAIL_STATUS);
          }
        }
      })();
      //End of the api call for update
    }
  }

  const handleEditClick = (volSplitPerLocation) => {
    setEditVolSplitPerLocationId(volSplitPerLocation.id);
    const formValues: IVolSplitPerLocation = {
      id: volSplitPerLocation.id,
      location: volSplitPerLocation.location,
      value: volSplitPerLocation.value,
    };
    setEditFormData(formValues);
  };

  /**
   * To handle the cancel button
   */
  const cancelHandler = () => {
    setIsShown((current) => !current);
  };
  const handleCancelClick = () => {
    setEditVolSplitPerLocationId(null);
  };
  /**
   * To handle the delete button
   * @param volSplitPerLocation The Vol Split Per Location object to be deleted
   */
  const handleDeleteClick = (volSplitPerLocation) => {
    const idsList: IId[] = [];
    const finalIds: IId[] = [volSplitPerLocation, ...idsList];
    setOpenModal(true);
    setResponseMessage(PLEASE_WAIT_STATUS);
    //Delete api call starts
    (async () => {
      try {
        await AmpApi.deleteVolSplitPerLocation(selectedNode, finalIds);
        setResponseMessage(DELETE_SUCCESS_STATUS);
        const newVolSplitPerLocationList = [...volSplitPerLocationList];
        const index = newVolSplitPerLocationList.findIndex((obj) => {
          return obj.id === volSplitPerLocation.id;
        });
        newVolSplitPerLocationList.splice(index, 1);
        setVolSplitPerLocationList(newVolSplitPerLocationList);
      } catch (ex: any) {
        if(localStorage.getItem(USER_ACCESS_KEY)=="Denied"){
          setOpenModal(false);
          setErrorMessage(ACCESS_DENIED_MESSAGE);
          onOpenToast();
        } else {
          console.error(`Error deleting ${volSplitPerLocation}`, ex);
          setOpenModal(true);
          setResponseMessage(DELETE_FAIL_STATUS);
        }
      }
    })();
    //Delete api call ends
  };

  //Get list of unique locations
  let locations = VolSplitPerLocationList.map(
    (volSplitPerLocationItem) => volSplitPerLocationItem.location
  );
  let uniqueLocations = [...new Set(locations)];

  VolSplitPerLocationList.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) {
      return sortDirection === "ascending" ? -1 : 1
    }
    if (a[sortColumn] > b[sortColumn]) {
      return sortDirection === "ascending" ? 1 : -1
    }
    return 0
  })

  return (
    <>
      {errorMessage && (
        <Toaster toasts={toasts} onCloseToast={onCloseToast}>
          {(toast) => (
            <Alert toast={true} onClose={toast.onClose} type="error">
              {errorMessage}
            </Alert>
          )}
        </Toaster>
      )}
      <SubmitModal
        open={openModal}
        responseMessage={responseMessage}
        onClose={onConfirmClose}
      />
      {isShown && (
        <Column>
          {loading ? (
            <Loader />
          ) : (
            <VolSplitPerLocationAddForm
              addFormHandler={addFormHandler}
              cancelHandler={cancelHandler}
              uniqueLocations={uniqueLocations}
            />
          )}
        </Column>
      )}
      <Column>
        <Table
            headerRows={1}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onSort={onSort}
            spacing="small"
        >
          <TableActionBar>
            <Button onClick={displayAddForm}>
              {isShown ? "Close" : "Add New"}
            </Button>
            <Input
              key="searchInput"
              type="text"
              value={searchTerm}
              placeholder="Search by Location"
              prefixIconTokens={filterTokenIcon}
              onChange={setSearchTerm}
            />
          </TableActionBar>
          <TableRow>
            <TableCell width={100}>{SORT_CENTER_LABEL}</TableCell>
            <TableCell sortColumn={VOL_SPLIT_PER_LOCATION_HEADERS.LOCATION} width={150}>{LOCATION_LABEL}</TableCell>
            <TableCell sortColumn={VOL_SPLIT_PER_LOCATION_HEADERS.VALUE} width={150}>{VALUE_LABEL}</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </Table>
        {volSplitPerLocationList
          .filter((volSplitPerLocation) => {
            if (searchTerm == "") {
              return volSplitPerLocation;
            } else if (
                volSplitPerLocation.location.toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              return volSplitPerLocation;
            }
          })
          .slice(firstVisibleIndex, lastVisibleIndex)
          .map((volSplitPerLocation) => (
            <div key={volSplitPerLocation.id}>
              {editVolSplitPerLocationId === volSplitPerLocation.id ? (
                <EditableVolSplitPerLocationRow
                    volSplitPerLocation={volSplitPerLocation}
                    selectedNode={selectedNode}
                    handleEditFormChange={handleEditFormChange}
                    handleCancelClick={handleCancelClick}
                    uniqueLocations={uniqueLocations}
                />
              ) : (
                <ReadOnlyVolSplitPerLocationRow
                    volSplitPerLocation={volSplitPerLocation}
                    selectedNode={selectedNode}
                    handleEditClick={handleEditClick}
                    handleDeleteClick={handleDeleteClick}
                />
              )}
            </div>
          ))}
        <Pagination
          showSkipArrows={true}
          numberOfPages={numberOfPages}
          onChange={setCurrentPage}
          currentPage={currentPage}
        />
      </Column>
    </>
  );
}

export default VolSplitPerLocationTable;
