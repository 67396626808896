import React, {useState} from "react";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Heading from "@amzn/meridian/heading";
import Input from "@amzn/meridian/input";
import Card from "@amzn/meridian/card";
import Select, {SelectOption} from "@amzn/meridian/select";
import {BLANK_FIELDS_ERROR} from "../../constants";
import {nanoid} from "nanoid";
import {IShiftsConfig} from "../../common/api/api";

function ShiftsConfigAddForm({addFormHandler, cancelHandler, selectedNode}) {
    const [shift, setShift] = useState("");
    const [min_hc, setMin_hc] = useState("");
    const [max_hc, setMax_hc] = useState("");
    const [min_length, setMin_length] = useState("");
    const [max_length, setMax_length] = useState("");
//Error checking
    const [errorMessage, setErrorMessage] = useState("");

    function submitHandler(event) {
        event.preventDefault();
        if (!shift || !min_hc || !max_hc || !min_length || !max_length) {
            setErrorMessage(BLANK_FIELDS_ERROR);
        } else {
            const addData: IShiftsConfig = {
                id: nanoid(),
                shift: shift,
                min_hc: parseInt(min_hc),
                max_hc: parseInt(max_hc),
                min_length: parseInt(min_length),
                max_length: parseInt(max_length),
            };
            addFormHandler(addData);
        }
    }

    /**
     * Function to generate unique id
     * @param id
     * @param text
     */
    const getUniqueKey = (id, text) => {
        return `${id}-${text}`;
    };
    return (
        <div>
            <Column>
                <Card>
                    <form onSubmit={submitHandler}>
                        <Column>
                            <Heading level={4}>Add New Shifts Configuration</Heading>
                            <Column>
                                <Row>
                                    <Select value={shift} onChange={setShift} width={200}>
                                        <SelectOption
                                            key={getUniqueKey("1-SUN", "shift")}
                                            value="1-SUN"
                                            label="1-SUN"
                                        />
                                        <SelectOption
                                            key={getUniqueKey("2-MOR", "shift")}
                                            value="2-MOR"
                                            label="2-MOR"
                                        />
                                        <SelectOption
                                            key={getUniqueKey("3-DAY", "shift")}
                                            value="3-DAY"
                                            label="3-DAY"
                                        />
                                        <SelectOption
                                            key={getUniqueKey("4-TWI", "shift")}
                                            value="4-TWI"
                                            label="4-TWI"
                                        />
                                        <SelectOption
                                            key={getUniqueKey("5-NIT", "shift")}
                                            value="5-NIT"
                                            label="5-NIT"
                                        />
                                    </Select>
                                </Row>
                                <Row>
                                    <Input
                                        name="min_hc"
                                        value={min_hc}
                                        label="min_hc"
                                        type="number"
                                        onChange={setMin_hc}
                                        size="small"
                                    />

                                    <Input
                                        name="max_hc"
                                        value={max_hc}
                                        label="max_hc"
                                        type="number"
                                        onChange={setMax_hc}
                                        size="small"
                                    />
                                </Row>
                                <Row>
                                    <Input
                                        name="min_length"
                                        value={min_length}
                                        label="min_length"
                                        type="number"
                                        onChange={setMin_length}
                                        size="small"
                                    />

                                    <Input
                                        name="max_length"
                                        value={max_length}
                                        label="max_length"
                                        type="number"
                                        onChange={setMax_length}
                                        size="small"
                                    />
                                </Row>
                            </Column>
                            <Box>
                                <Row>
                                    <Button submit={true}>Add</Button>
                                    <Button onClick={cancelHandler}>Cancel</Button>
                                    {errorMessage && <p className="errorMsg"> {errorMessage} </p>}
                                </Row>
                            </Box>
                        </Column>
                    </form>
                </Card>
            </Column>
        </div>
    );
}

export default ShiftsConfigAddForm;
