import {useHistory, useParams} from "react-router-dom";
import Select, {SelectOption} from "@amzn/meridian/select";
import useSelectedNode from "../hooks/nodeContext";
import {NODE_ID_DROPDOWN_PLACEHOLDER, NODE_LIST_KEY} from "../../constants";
import {useEffect, useState} from "react";
import useNodeList from "../hooks/NodeListContext";
import AmpApi from "../../common/api/AmpApi";
import {IId} from "../../common/api/api";

/**
 * The dropdown component to display sort center ids
 * @param width
 * @param label
 * @constructor
 */
export default function NodeIdSelect({width = 200, label = ""}) {
    const {selectedNode, changeSelectedNode} = useSelectedNode();
    const history = useHistory();
    const {nodeList,setNodeList} = useNodeList();
    const {nodeId} = useParams<{ nodeId: string }>();
    const [nodeIds, setNodeIds] = useState([]);
    useEffect(() => {
        if (nodeId !== selectedNode) {
            changeSelectedNode(nodeId);
        }
    }, []);

    function onChangeNodeIdHandler(nodeValue) {
        changeSelectedNode(nodeValue);
        history.push(`/${nodeValue}`);
    }
    useEffect(() => {
        (async () => {
        try {
            const getAllNodeIds = await AmpApi.getAllNodeIds();
            if (getAllNodeIds) {
                const ids: IId[] = getAllNodeIds.data.ids;
                const finalList: IId[] = [...ids];
                //Add nodeList to the localStorage
                localStorage.setItem(NODE_LIST_KEY, JSON.stringify(finalList));
                setNodeList(finalList);
                setNodeIds(finalList);
            }
        } catch (ex: any) {
            console.error(`Error fetching node list`, ex);
        }
    })();
    }, []);

    return (
        <>
            <label className="nodeIdColor">
                {selectedNode ? `${selectedNode}` : "Select Sort Center to Proceed"}
            </label>

            <Select
                value={selectedNode}
                onChange={onChangeNodeIdHandler}
                placeholder={NODE_ID_DROPDOWN_PLACEHOLDER}
                width={width}
                label={label}
            >

                {nodeList.map((node) => (
                    <SelectOption key={node['id']} value={node['id']} label={node['id']}/>))}

            </Select>
        </>
    );
}
