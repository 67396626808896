// Promise polyfill
import "core-js/features/promise";

import { render } from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./components/App";
import "@amzn/meridian-tokens/base/font/amazon-ember.css";

render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.querySelector("#app")
);
