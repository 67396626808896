import React from "react";
import Button from "@amzn/meridian/button";
import Modal, {ModalFooter} from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import {
    ADD_FAIL_STATUS,
    ADD_SUCCESS_STATUS,
    DELETE_FAIL_STATUS,
    DELETE_SUCCESS_STATUS,
    PLEASE_WAIT_STATUS,
    UPDATE_FAIL_STATUS,
    UPDATE_SUCCESS_STATUS,
    UPLOAD_SUCCESS_STATUS,
    UPLOAD_FAIL_STATUS,
    RUN_AMP_MODEL_SUCCESS_STATUS,
    RUN_AMP_MODEL_FAIL_STATUS
} from "../../constants";

/**
 * Modal that displays the result of CRUD operations in all the tables.
 * @param responseMessage
 * @param open
 * @param onClose
 * @constructor
 */
export default function SubmitModal({responseMessage, open, onClose}) {
    let shortText = "";

    switch (responseMessage) {
        case UPDATE_SUCCESS_STATUS:
            shortText = UPDATE_SUCCESS_STATUS;
            break;
        case UPDATE_FAIL_STATUS:
            shortText = UPDATE_FAIL_STATUS;
            break;
        case ADD_SUCCESS_STATUS:
            shortText = ADD_SUCCESS_STATUS;
            break;
        case ADD_FAIL_STATUS:
            shortText = ADD_FAIL_STATUS;
            break;
        case DELETE_SUCCESS_STATUS:
            shortText = DELETE_SUCCESS_STATUS;
            break;
        case DELETE_FAIL_STATUS:
            shortText = DELETE_FAIL_STATUS;
            break;
        case UPLOAD_SUCCESS_STATUS:
            shortText = UPLOAD_SUCCESS_STATUS;
            break;
        case UPLOAD_FAIL_STATUS:
            shortText = UPLOAD_FAIL_STATUS;
            break;
        case PLEASE_WAIT_STATUS:
            shortText = PLEASE_WAIT_STATUS;
            break;
        case RUN_AMP_MODEL_SUCCESS_STATUS:
            shortText = RUN_AMP_MODEL_SUCCESS_STATUS;
            break;
        case RUN_AMP_MODEL_FAIL_STATUS:
            shortText = RUN_AMP_MODEL_FAIL_STATUS;
            break;
    }

    return (
        <React.Fragment>
            <Modal
                open={open}
                title=""
                onClose={onClose}
                scrollContainer="viewport"
                width={300}
                closeLabel="Close"
            >
                <Text id="modal-description">{shortText}</Text>
                <ModalFooter>
                    <Row alignmentHorizontal="right" widths="fit">
                        <Button type="primary" size="small" onClick={onClose}>
                            confirm
                        </Button>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
